import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ManufacturerService } from 'src/app/services/http/manufacturer/manufacturer.service';

@Component({
  selector: 'app-delete-manufacturer',
  templateUrl: './delete-manufacturer.component.html',
  styleUrls: ['./delete-manufacturer.component.scss']
})
export class DeleteManufacturerComponent implements OnInit {

  @Output() manufacturerDeleted = new EventEmitter();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private manufacturerService: ManufacturerService) { }

  ngOnInit() {
  }

  async deleteManufacturers(manufacturerId: number) {
    this.spinner.show("fullScreenSpinner");
    await this.manufacturerService.deleteManufacturer(manufacturerId);
    this.spinner.hide("fullScreenSpinner");

    this.ngxSmartModalService.getModal('deleteManufacturer').close()
    this.manufacturerDeleted.emit();
  }
}
