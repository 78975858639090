import { Component, OnInit } from '@angular/core';
import { FilterModel } from 'src/app/models/FilterModel';
import { FilterOption } from 'src/app/models/FilterOption';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { VesselService } from 'src/app/services/http/vessel/vessel.service';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';

@Component({
  selector: 'app-view-vessels',
  templateUrl: './view-vessels.component.html',
  styleUrls: ['./view-vessels.component.scss']
})
export class ViewVesselsComponent implements OnInit {

  //Modal Identifier Constants
  addVesselModal: string = "addVessel";
  editVesselModal: string = "editVessel";
  disableVesselModal: string = "disableVessel";

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];

  //Local Variables
  vessels: any;

  constructor(private vesselService: VesselService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService: ToastrService) {
    this.filterOptions = [
      new FilterOption("Id", "Vessel Id"),
      new FilterOption("Name", "Name"),
      new FilterOption("Carrier", "Carrier"),
      new FilterOption("RampCapacity", "Ramp Capacity"),
      new FilterOption("HatchHeight", "Hatch Height"),
      new FilterOption("Created", "Modified On"),
      new FilterOption("CreatedByName", "Modified By")
    ];

    this.tableControl = new TableControl(10);
  }

  async ngOnInit() {
    this.spinner.show("fullScreenSpinner");
    await this.loadItemCount();
    this.spinner.hide("fullScreenSpinner");

    await this.loadVessels();
  }

  private async loadVessels() {
    this.spinner.show("tableSpinner");
    this.vessels = await this.getVessels();
    this.tableControl.CurrentItemCount = this.vessels.length;
    this.spinner.hide("tableSpinner");
  }

  private async loadItemCount() {
    this.tableControl.TotalItemCount = await this.vesselService.getVesselsCount(this.tableControl.Filter);
  }

  private getVessels() {
    return this.vesselService.getVessels(this.tableControl);
  }

  addVessel() {
    this.ngxSmartModalService.open(this.addVesselModal);
  }

  editVessel(vessel) {
    this.ngxSmartModalService.resetModalData(this.editVesselModal);
    this.ngxSmartModalService.setModalData(vessel, this.editVesselModal);
    this.ngxSmartModalService.open(this.editVesselModal);
  }

  disableVessel(vessel) {
    this.ngxSmartModalService.resetModalData(this.disableVesselModal);
    this.ngxSmartModalService.setModalData(vessel, this.disableVesselModal);
    this.ngxSmartModalService.open(this.disableVesselModal);
  }

  //EMMITED EVENT ACTIONS
  onVesselAdded() {
    this.tableControl.Page = 1;
    this.loadItemCount();
    this.loadVessels();
    this.toasterService.success("Vessel successfully Added.", "Add Vessel");
  }

  onVesselEdited() {
    this.loadVessels();
    this.toasterService.success("Vessel successfully Edited.", "Edit Vessel");
  }

  onVesselDisabled() {
    this.loadItemCount();
    this.loadVessels();
    this.toasterService.success("Vessel successfully Deleted.", "Delete Vessel");
  }

  onPageChanged({ page, maxPageSize }) {
    this.tableControl.Page = page;
    this.tableControl.MaxPageSize = maxPageSize;
    
    this.loadVessels();
  }

  onFiltered(filter: FilterModel) {
    this.tableControl.Page = 1;
    this.tableControl.Filter = filter;
    this.loadVessels();
    this.loadItemCount();
  }

  onSorted(sort: string) {
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1;
    this.loadVessels();
  }

}
