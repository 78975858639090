import { Injectable } from '@angular/core';
import { TableControl } from 'src/app/models/TableControl';
import { FilterModel } from 'src/app/models/FilterModel';
import { ConstantsService } from '../constants/constants.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  private currentUser: any;

  constructor(private constants: ConstantsService) { 
    this.currentUser = JSON.parse(this.getStorage(this.constants.CURRENT_USER));
  }

  applyTableControls(url: string, tableControl: TableControl){
    url += url.includes("?") ? "&" : "?";
    url += "page=" + tableControl.Page + "&itemCount=" + tableControl.MaxPageSize + "&sort=" + tableControl.Sorting 
        + "&filterType=" + tableControl.Filter.Type + "&filterText=" + tableControl.Filter.Text;

    return url;
  }

  applyTableFilters(url: string, filter: FilterModel){
    url += url.includes("?") ? "&" : "?";
    url += "filterType=" + filter.Type + "&filterText=" + filter.Text;

    return url;
  }

  getFormHeaders()
  {
    return {
      headers: {
        "Authorization": "Bearer " + this.currentUser.access_token,
        "Content-Type": "application/x-www-form-urlencoded",
      }
    };
  }

  getFormHeadersJson() {
    return {
      headers: {
        "Authorization": "Bearer " + this.currentUser.access_token,
        "Content-Type": "application/json; charset=utf-8",
      }
    };
  }

  getAuthorizationTokenHeader() {
    return new HttpHeaders({
      Authorization: "Bearer " + this.currentUser.access_token
    });
  }

  getAuthorizationHeader(){
    return {
      headers: {
        "Authorization": "Bearer " + this.currentUser.access_token
      }
    };
  }

  getEncodedBody(body: any){
    var xb = "";
    for (var key in body) {
      if (xb.length) {
        xb += "&";
      }
      xb += key + "=";
      xb += encodeURIComponent(body[key]);
    }

    return xb;
  }

  setStorage(item: string, value: string) {
    window.localStorage.setItem(item, value);
  }

  getStorage(item: string) {
    return window.localStorage.getItem(item);
  }

}
