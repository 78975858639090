import { Component, OnInit, Output, EventEmitter, Input, ViewChild, DebugNode } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { NgForm, FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { FreightRate, Charge, Denomination, CarOceanFreight } from 'src/app/models/FreightRate';
import { FreightRateService } from 'src/app/services/http/freight-rate/freight-rate.service';
import { PortService } from 'src/app/services/http/port/port.service';
import { CurrencyService } from 'src/app/services/http/currency/currency.service';
import { ConstantsService } from 'src/app/services/common/constants/constants.service';
import { PaymentType } from 'src/app/models/PaymentType';
import { PaymentTypeService } from 'src/app/services/http/payment-type/payment-type.service';
// import { CarrierService } from 'src/app/services/http/carrier/carrier.service';
import { FilterModel } from 'src/app/models/FilterModel';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
	selector: 'app-edit-freight-rate',
	templateUrl: './edit-freight-rate.component.html',
	styleUrls: ['./edit-freight-rate.component.scss']
})
export class EditFreightRateComponent implements OnInit {

	@Output() freightRateAdded = new EventEmitter();
	@Output() freightRateEdited = new EventEmitter();
	@Input() countries: any;
	@Input() currencies: any;
	@Input() rateTypes: any;
	@Input() incotermsFrom: any;
	@Input() incotermsTo: any;
	@ViewChild('editFreightRate', {static: false}) editFreightRate: NgxSmartModalComponent;
	@ViewChild('model', {static: false}) modelSelectComponent: NgSelectComponent;

	//carrierBuffer = {};
	//carrierBufferArr: {Id: number, Name:string}[] = [];
	//carrierLength = 0;
	//carrierPageIndex = 0;

	//carriersLoading = false;
	//carriersInputLength = 0;
	//carriersInput = '';
	//carrierSearchTimer: any;

	bufferSize = 50;
	numberOfItemsFromEndBeforeFetchingMore = 10;

	paymentTypes: PaymentType[];

	form: FormGroup;
	freightForm: FormGroup;
	carFreightsForm: FormArray;
	exportChargesForm: FormArray;
	importChargesForm: FormArray;
	isSubmitted: boolean;

	originRegions: any;
	destinationRegions: any;
	categories: any[];

	defaultExportCurrencyId: number;
	defaultImportCurrencyId: number;

	freightRate: FreightRate = new FreightRate();
	fullscreenSpinner = 'fullScreenSpinners';
	isNewImportChargeSubmitted: boolean;
	isNewExportChargeSubmitted: boolean;

	constructor(
		private spinnerService: NgxSpinnerService,
		private freightRateService: FreightRateService,
		private portService: PortService,
		private currencyService: CurrencyService,
		private paymentTypeService: PaymentTypeService,
		private constantService: ConstantsService,
		//private carrierService: CarrierService
	) { 
		this.categories = [
			{ text: 'For All Categories', value: true },
			{ text: 'For Cars', value: false }
		];

		this.paymentTypes = paymentTypeService.getPaymentTypes();
	}

	async ngOnInit() {
		this.freightForm = new FormGroup({
			currencyId: new FormControl(null, Validators.required),
			amount: new FormControl(null, Validators.required),
		});

		//this.carrierLength = await this.carrierService.getCarrierCount(this.productTypeWordFilter(this.carriersInput));

		this.carFreightsForm = new FormArray([]);
		this.exportChargesForm = new FormArray([]);
		this.importChargesForm = new FormArray([]);

		this.form = new FormGroup({
			id: new FormControl(null),
			//carrierId: new FormControl(null, Validators.required),
			originId: new FormControl(null, Validators.required),
			destinationId: new FormControl(null, Validators.required),
			originCountryId: new FormControl(null, Validators.required),
			destinationCountryId: new FormControl(null, Validators.required),
			rateTypeId: new FormControl(null, Validators.required),
			freight: this.freightForm,
			carFreights: this.carFreightsForm,
			exportCharges: this.exportChargesForm,
			importCharges: this.importChargesForm,
			newExportChargeName: new FormControl(null),
			newImportChargeName: new FormControl(null),
			incotermFromId: new FormControl(null, Validators.required),
			incotermToId: new FormControl(null, Validators.required),
			isAllCategories: new FormControl(null, Validators.required)
		  });
	}

	get id() { return this.form.get('id') };
	//get carrierId() { return this.form.get('carrierId') };
	get originId() { return this.form.get('originId') };
	get destinationId() { return this.form.get('destinationId') };
	get originCountryId() { return this.form.get('originCountryId') };
	get destinationCountryId() { return this.form.get('destinationCountryId') };
	get rateTypeId() { return this.form.get('rateTypeId') };
	get isAllCategories() { return this.form.get('isAllCategories') };
	get freight() { return this.form.get('freight') };
	get carFreights() { return this.form.get('carFreights') as FormArray };
	get freightCurrency() { return this.form.get('freight.currencyId') };
	get freightAmount() { return this.form.get('freight.amount') };
	get exportCharges() { return this.form.get('exportCharges') as FormArray };
	get importCharges() { return this.form.get('importCharges') as FormArray };
	get incotermFromId() { return this.form.get('incotermFromId') };
	get incotermToId() { return this.form.get('incotermToId') };
	get newExportChargeName() { return this.form.get('newExportChargeName') };
	get newImportChargeName() { return this.form.get('newImportChargeName') };
  
	async onDataAdded() {
		this.isSubmitted = false;

		let data = this.editFreightRate.getData() as FreightRate;

		if  (!data) return;

		Object.keys(data.CarOceanFreights).forEach(_ => {
			this.carFreightsForm.push(new FormGroup({
				key: new FormControl(null),
				name: new FormControl(null),
				paymentTypeId: new FormControl(null),
				currencyId: new FormControl(null),
				amount: new FormControl(null),
			}));
		});

		data.ExportCharges.forEach(c => {
			this.exportChargesForm.push(
				new FormGroup({
					id: new FormControl(null),
					name: new FormControl(null),
					currencyId: new FormControl(null),
					amount: new FormControl(null),
					isDefault: new FormControl(null),
				})
			);
		});

		data.ImportCharges.forEach(c => {
			this.importChargesForm.push(
				new FormGroup({
					id: new FormControl(null),
					name: new FormControl(null),
					currencyId: new FormControl(null),
					amount: new FormControl(null),
					isDefault: new FormControl(null),
				})
			);
		});

		this.form.setValue({
			id: data.Id,
			//carrierId: data.CarrierId,
			originId: data.OriginId,
			destinationId: data.DestinationId,
			originCountryId: data.OriginCountryId,
			destinationCountryId: data.DestinationCountryId,
			rateTypeId: data.RateTypeId,
			isAllCategories: data.IsAllCategories,
			freight: { currencyId: data.OceanFreight.CurrencyId, amount: data.OceanFreight.Amount },
			carFreights: Object.keys(data.CarOceanFreights).map((key, index) => {
				return {
					key: key,
					name: data.CarOceanFreights[key].Name,
					paymentTypeId: data.CarOceanFreights[key].PaymentTypeId,
					currencyId: data.CarOceanFreights[key].Denomination.CurrencyId,
					amount: data.CarOceanFreights[key].Denomination.Amount
				}
			}),
			exportCharges: data.ExportCharges.map(c => {
				return {
					id: c.Id,
					name: c.Name,
					currencyId: c.Denomination.CurrencyId,
					amount: c.Denomination.Amount,
					isDefault: c.IsDefault
				};
			}),
			importCharges: data.ImportCharges.map(c => {
				return {
					id: c.Id,
					name: c.Name,
					currencyId: c.Denomination.CurrencyId,
					amount: c.Denomination.Amount,
					isDefault: c.IsDefault
				};
			}),
			incotermFromId: data.IncotermFromId,
			incotermToId: data.IncotermToId,
			newExportChargeName: '',
			newImportChargeName: ''
		});

		this.toggleFreightFields(this.isAllCategories.value);
	}

	async onClose(){
		this.form.reset();
		this.freightForm.reset();
		this.carFreightsForm.clear();
		this.exportChargesForm.clear();
		this.importChargesForm.clear();
	}

	async onSubmit() {
		this.isSubmitted = true;

		this.newExportChargeName.reset();
		this.newImportChargeName.reset();

		if (this.form.invalid) return;
		
		this.spinnerService.show(this.fullscreenSpinner);
	
		let isNew = !this.id.value;
		let isSuccessful = await this.save();
	
		this.spinnerService.hide(this.fullscreenSpinner);

		if (!isSuccessful) return;

		this.isSubmitted = false;
		this.form.reset();
		this.editFreightRate.close();
	
		if (isNew)
		  this.freightRateAdded.emit();
		else
		  this.freightRateEdited.emit();

	}

	async save() {
		const freightRate = new FreightRate();
		
		let oceanFreight = new Denomination(this.freight.value.currencyId, this.freight.value.amount);
		
		const carFreights = {};
		this.carFreights.value.forEach(c => {
			carFreights[c.key] = new CarOceanFreight(c.name, c.paymentTypeId, new Denomination(c.currencyId, c.amount));
		});

		console.log('[carFreights]', carFreights);

		let exportCharges = this.exportCharges.value.map(c =>
			new Charge(
				c.name,
				new Denomination(c.currencyId, c.amount),
				c.isDefault,
				c.id
			)
		);

		let importCharges = this.importCharges.value.map(c => 
			new Charge(
				c.name,
				new Denomination(c.currencyId, c.amount),
				c.isDefault,
				c.id
			)
		);

		freightRate.Construct({
			Id: this.id.value,
			//CarrierId: this.carrierId.value,
			OriginId: this.originId.value,
			DestinationId: this.destinationId.value,
			OriginCountryId: this.originCountryId.value,
			DestinationCountryId: this.destinationCountryId.value,
			RateTypeId: this.rateTypeId.value,
			OceanFreight: oceanFreight,
			ExportCharges: exportCharges,
			ImportCharges: importCharges,
			IncotermFromId: this.incotermFromId.value,
			IncotermToId: this.incotermToId.value,
			IsAllCategories: this.isAllCategories.value,
			CarOceanFreights: carFreights
		});

		let isSuccessful: boolean;

		await this.freightRateService.editFreightRate(freightRate)
			.then(_ => isSuccessful = true);

		return isSuccessful;
	}

	// onCarrierClear()
	// {
	// 	this.carriersInput = '';

	// 	this.carriersLoading = true;

	// 	this.carrierService.getCarrierCount(this.productTypeWordFilter(this.carriersInput))
	// 	.then(data => {
	// 	this.carrierLength = data;

	// 	if (!this.carrierLength) {
	// 		this.carriersInputLength = 0;
	// 		this.carriersLoading = false;

	// 		return;
	// 	}
		
	// 	this.carrierBufferArr = [];
	// 	this.carrierPageIndex = 0;
		
	// 	this.fetchMoreCarriers();
	// 	});
	// }

	// onCarrierSearch(obj: {term:string, items:any[]})
	// {
	// 	if (this.carrierSearchTimer)
	// 	clearTimeout(this.carrierSearchTimer);
		
	// 	this.carrierSearchTimer = setTimeout(() => {
	// 	let term = obj.term;
	// 	let items = obj.items;

	// 	this.carriersInput = term.trim();

	// 	this.carriersLoading = true;

	// 	this.carrierService.getCarrierCount(this.productTypeWordFilter(this.carriersInput))
	// 	.then(data => {
	// 		this.carrierLength = data;
			
	// 		if (!this.carrierLength || this.carrierLength == items.length) {
	// 		this.carriersInputLength = items.length;
	// 		this.carriersLoading = false;

	// 		return;
	// 		}
			
	// 		this.carrierBufferArr = [];
	// 		this.carrierPageIndex = 0;
		
	// 		this.fetchMoreCarriers();
	// 	});
	// 	}, 1000);
	// }

	// async onCarrierScroll({ end }) {
	// 	if (this.carriersLoading || this.carrierLength <= this.carriersInputLength)
	// 		return;

	// 	if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.carriersInputLength)
	// 		await this.fetchMoreCarriers();
	// }

	// async onCarrierScrollToEnd() {
	// 	if (this.carriersLoading || this.carrierLength <= this.carriersInputLength)
	// 		return;
		
	// 	await this.fetchMoreCarriers();
	// }

	// private async fetchMoreCarriers() {
	// 	this.carriersLoading = true;
	
	// 	let newCarriers = await this.carrierService.getMoreCarriers(this.carriersInput, this.bufferSize, ++this.carrierPageIndex);

	// 	this.carrierBuffer = {...this.carrierBuffer, ...newCarriers};

	// 	this.carrierBufferArr = Object.keys(this.carrierBuffer).map(key => {
	// 		return { Id: Number(key), Name: this.carrierBuffer[key] };
	// 	}).sort((a,b) => 
	// 		(a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));

	// 	let bufferLength = Object.keys(this.carrierBuffer).length;
		
	// 	this.carriersInputLength = bufferLength > this.carrierLength ? this.carrierLength : bufferLength;

	// 	this.carriersLoading = false;
	// }

	private productTypeWordFilter(term: string)
	{
		return new FilterModel("Word", term);
	}

	async onOriginCountryChanged() {
		const countryId = this.originCountryId.value;

		if (countryId) {
			this.spinnerService.show('modalSpinner');

			const currencyId = this.getCurrencyIdByCountryId(countryId);

			this.selectCurrencyForCharges(currencyId, this.defaultExportCurrencyId, this.exportCharges.controls);
			this.defaultExportCurrencyId = currencyId;

			this.originRegions = await this.portService.getPortsByCountry(countryId);
			this.originId.setValue(null);

			this.selectCurrencyForCarOceanFreights();

			this.spinnerService.hide('modalSpinner');
		}
	}

	async onDestinationCountryChanged() {
		const countryId = this.destinationCountryId.value;
		if (countryId) {
			this.spinnerService.show('modalSpinner');

			const currencyId = this.getCurrencyIdByCountryId(countryId);
			this.selectCurrencyForCharges(currencyId, this.defaultImportCurrencyId, this.importCharges.controls);
			this.defaultImportCurrencyId = currencyId;

			this.destinationRegions = await this.portService.getPortsByCountry(countryId);
			this.destinationId.setValue(null);

			this.selectCurrencyForCarOceanFreights();

			this.spinnerService.hide('modalSpinner');
		}
	}

	selectCurrencyForCarOceanFreights() {
		const country = this.countries.find(c => c.Id == this.originCountryId.value);

		if (!country) return;

		const currency = this.currencyService.getCurrencyById(country.CurrencyId, this.currencies);

		let currencyId: number = this.currencyService.getCurrencyIdByISO(this.constantService.CURRENCY_ISO_USD, this.currencies);
			
		if (currency) {
			const isShippedFromNZ = country && country.Code == this.constantService.COUNTRY_CODE_NZ;
			const isShippedFromAU = country && country.Code == this.constantService.COUNTRY_CODE_AU;

			if (isShippedFromNZ || isShippedFromAU)
				currencyId = currency.Id;
		}

		this.carFreights.controls.forEach(c => {
			c.get('currencyId').setValue(currencyId);
		});
	}
	
	selectCurrencyForCharges(currencyId: number, lastCurrencyId: number, charges: AbstractControl[]) {
		let isChangeable = charges.every(charge => {
			return charge.get('currencyId').value
				|| charge.get('currencyId').value == lastCurrencyId;
			}
		);

		if (!isChangeable) return;

		charges.forEach(charge => charge.get('currencyId').setValue(currencyId));
	}

	getCurrencyIdByCountryId(id: number): number {
		let country = this.countries.find(c => c.Id == id);
		if (country) return country.CurrencyId;

		return;
	}

    async loadOriginRegions(originCountryId: number) {
		if (originCountryId) {
			this.spinnerService.show('modalSpinner');
			this.originRegions = await this.portService.getPortsByCountry(originCountryId);
			this.spinnerService.hide('modalSpinner');
		}
	}

	async loadDestinationRegions(destinationCountryId: number) {
		if (destinationCountryId) {
			this.spinnerService.show('modalSpinner');
			this.destinationRegions = await this.portService.getPortsByCountry(destinationCountryId);
			this.spinnerService.hide('modalSpinner');
		}
	}
	
	addNewExportCharge() {
		this.isNewExportChargeSubmitted = true;
		const name = (this.newExportChargeName.value || '').trim();

		if (!name) {
			this.newExportChargeName.setErrors({ required: true });
			return;
		}

		if (this.exportCharges.controls.some(c => c.get('name').value.toLowerCase() == name.toLowerCase())) {
			this.newExportChargeName.setErrors({ duplicate: true });
			return;
		}
		
		const currencyId = this.getLastChargeCurrencyId(this.exportCharges.controls);
		this.exportCharges.push(this.newChargeControl(name, currencyId));

		this.isNewExportChargeSubmitted = false;
		this.newExportChargeName.reset();
	}

	removeNewExportCharge(index: number) {
		this.exportCharges.controls.splice(index, 1);
	}
	
	addNewImportCharge() {
		this.isNewImportChargeSubmitted = true;
		const name = (this.newImportChargeName.value || '').trim();

		if (!name) {
			this.newImportChargeName.setErrors({ required: true });
			return;
		}

		if (this.importCharges.controls.some(c => c.get('name').value.toLowerCase() == name.toLowerCase())) {
			this.newImportChargeName.setErrors({ duplicate: true });
			return;
		}

		const currencyId = this.getLastChargeCurrencyId(this.importChargesForm.controls);
		this.importCharges.push(this.newChargeControl(name, currencyId));

		this.isNewImportChargeSubmitted = false;
		this.newExportChargeName.reset();
	}

	removeNewImportCharge(index: number) {
		this.importCharges.controls.splice(index, 1);
	}

	newChargeControl(name: string, currencyId: number): FormGroup {
		const group = new FormGroup({
			id: new FormControl(null),
			name: new FormControl(null),
			currencyId: new FormControl(null),
			amount: new FormControl(null),
			isDefault: new FormControl(null),
		});

		group.setValue({
			id: null,
			name: name,
			currencyId: currencyId,
			amount: null,
			isDefault: false
		});

		return group;
	}

	getLastChargeCurrencyId(formGroup: AbstractControl[]) {
		if (formGroup && formGroup.length)
			return formGroup[formGroup.length-1].get('currencyId').value;
	}

	onCategoryChange(isAllCategories: boolean) {
		this.toggleFreightFields(isAllCategories);
	}

	toggleFreightFields(isAllCategories: boolean) {
		if (isAllCategories) {
			this.freight.enable();
			this.carFreights.disable();
		} else {
			this.freight.disable();
			this.carFreights.enable();
		}
	}
}
