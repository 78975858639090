export class MakeModel {
    public Id: number;
    public Name: string;
	public YearModel: number;
	public ProductTypeId: number;
	public ProductTypeName: string;
	public ManufacturerId: number;
	public ManufacturerName: string;

	public Clear() {
		this.Name = "";
		this.YearModel = null;
		this.ProductTypeId = null;
		this.ManufacturerId = null;
		this.ProductTypeName = "";
		this.ManufacturerName = "";
	}

	public Construct(object: any) {
		this.Id = object.Id;
		this.Name = object.Name;
		this.YearModel = object.YearModel;
		this.ProductTypeId = object.ProductTypeId;
		this.ManufacturerId = object.ManufacturerId;
		this.ProductTypeName = object.ProductTypeName;
		this.ManufacturerName = object.ManufacturerName;
	}
}