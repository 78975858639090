import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { ViewProductTypesComponent } from './components/pages/product-types/view-product-types/view-product-types.component';
import { ViewManufacturersComponent } from './components/pages/manufacturers/view-manufacturers/view-manufacturers.component';
import { ViewMakeModelsComponent } from './components/pages/make-models/view-make-models/view-make-models.component';
import { ViewConfigurationsComponent } from './components/pages/configurations/view-configurations/view-configurations.component';
import { ViewProductConfigurationsComponent } from './components/pages/product-configurations/view-product-configurations/view-product-configurations.component';
import { ViewVesselsComponent } from './components/pages/vessels/view-vessels/view-vessels.component';
import { ViewVoyageComponent } from './components/pages/voyages/view-voyage/view-voyage.component';
import { ViewSailingSchedulesComponent } from './components/pages/sailing-schedules/view-sailing-schedules/view-sailing-schedules.component';
import { ViewFreightRatesComponent } from './components/pages/freight-rates/view-freight-rates/view-freight-rates.component';
import { ViewCarriersComponent } from './components/pages/carriers/view-carriers/view-carriers.component';
import { ViewInsuranceQuoteReportsComponent } from './components/pages/reports/insurance-quotes/view-insurance-quote-reports/view-insurance-quote-reports.component';
import { ViewInsuranceCertificateReportsComponent } from './components/pages/reports/insurance-certificates/view-insurance-certificate-reports/view-insurance-certificate-reports.component';
import { ViewBordereauComponent } from './components/pages/marsh/bordereau/view-bordereau/view-bordereau.component';
import { ViewPremiumDistributionComponent } from './components/pages/marsh/premium-distribution/view-premium-distribution/view-premium-distribution.component';
import { ViewCommissionPayableComponent } from './components/pages/marsh/commission-payable/view-commission-payable/view-commission-payable.component';
import { EditAccountDistributorComponent } from './components/pages/administration/distributors/edit-account-distributor/edit-account-distributor.component';
import { ViewDistributorsComponent } from './components/pages/administration/distributors/view-distributors/view-distributors.component';
import { DeleteDistributorComponent } from './components/pages/administration/distributors/delete-distributor/delete-distributor.component';
import { ChangeUserPasswordComponent } from './components/pages/administration/users/change-user-password/change-user-password.component';
import { ViewDistributorFeesComponent } from './components/pages/administration/distributor-fees/view-distributor-fees/view-distributor-fees.component';
//import { MarginComponent } from './components/pages/administration/margin/margin/margin.component';
import { ViewUserProfileComponent } from './components/pages/administration/users/view-user-profile/view-user-profile.component';
import { EditUserProfileComponent } from './components/pages/administration/users/edit-user-profile/edit-user-profile.component';
import { DeleteUserProfileComponent } from './components/pages/administration/users/delete-user-profile/delete-user-profile.component';

const routes: Routes = [
	{ path: '', component: DashboardComponent, pathMatch: 'full' },
	// { path: 'dashboard', component: DashboardComponent },
	{ path: 'view-product-types', component: ViewProductTypesComponent },
	{ path: 'view-manufacturers', component: ViewManufacturersComponent },
	{ path: 'view-make-models', component: ViewMakeModelsComponent },
	{ path: 'view-configurations', component: ViewConfigurationsComponent },
	{ path: 'view-product-configurations', component: ViewProductConfigurationsComponent },
	{ path: 'view-carriers', component: ViewCarriersComponent },
	{ path: 'view-vessels', component: ViewVesselsComponent },
	{ path: 'view-voyages', component: ViewVoyageComponent },
	{ path: 'view-sailing-schedules', component: ViewSailingSchedulesComponent },
	{ path: 'view-freight-rates', component: ViewFreightRatesComponent },
	{ path: 'view-insurance-quote-reports', component: ViewInsuranceQuoteReportsComponent },
	{ path: 'view-insurance-certificate-reports', component: ViewInsuranceCertificateReportsComponent },
	{ path: 'view-bordereau', component: ViewBordereauComponent },
	{ path: 'view-premium-distribution', component: ViewPremiumDistributionComponent },
	{ path: 'view-commission-payable', component: ViewCommissionPayableComponent },
	{ path: 'edit-account-distributor', component: EditAccountDistributorComponent },
	{ path: 'view-distributors', component: ViewDistributorsComponent },
	{ path: 'delete-distributor', component: DeleteDistributorComponent },
	{ path: 'change-user-password', component: ChangeUserPasswordComponent },
	{ path: 'view-distributor-fees', component: ViewDistributorFeesComponent },
	{ path: 'view-user-profile', component: ViewUserProfileComponent },
	{ path: 'edit-user-profile', component: EditUserProfileComponent },
	{ path: 'delete-user-profile', component: DeleteUserProfileComponent },
	//{ path: 'margin', component: MarginComponent }
	// { path: '**', redirectTo: '/dashboard' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
