import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { MakeModelService } from 'src/app/services/http/make-model/make-model.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MakeModel } from 'src/app/models/MakeModel';
import { FilterModel } from 'src/app/models/FilterModel';
import { ProductTypeService } from 'src/app/services/http/product-type/product-type.service';
import { ManufacturerService } from 'src/app/services/http/manufacturer/manufacturer.service';

@Component({
  selector: 'app-edit-make-model',
  templateUrl: './edit-make-model.component.html',
  styleUrls: ['./edit-make-model.component.scss']
})
export class EditMakeModelComponent implements OnInit {
  @Output() makeModelAdded = new EventEmitter();
  @Output() makeModelEdited = new EventEmitter();
  @ViewChild('EditMakeModel', {static:false}) editMakeModel: NgxSmartModalComponent;

  makeModel: MakeModel = new MakeModel();

  bufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;

  productTypeBuffer = {};
  productTypeBufferArr: {Id: number, Name:string}[] = [];
  productTypeLength = 0;
  productTypePageIndex = 0;

  productTypesLoading = false;
  productTypesInputLength = 0;
  productTypesInput = '';
  productTypeSearchTimer: any;

  manufacturerBuffer = {};
  manufacturerBufferArr: {Id: number, Name:string}[] = [];
  manufacturerLength = 0;
  manufacturerPageIndex = 0;

  manufacturersLoading = false;
  manufacturersInputLength = 0;
  manufacturersInput = '';
  manufacturerSearchTimer: any;

  fullScreenSpinner = 'fullScreenSpinner';
  isSubmitted: boolean;
  form: FormGroup;
  modalTitle: string;
  submitDisabled: boolean;

  constructor(private spinnerService: NgxSpinnerService,
    private productTypeService: ProductTypeService,
    private manufacturerService: ManufacturerService,
    private makeModelService: MakeModelService) { }

  get id() { return this.form.controls['id']; }
  get name() { return this.form.controls['name']; }
  get yearModel() { return this.form.controls['yearModel']; }
  get productTypeId() { return this.form.controls['productTypeId']; }
  get manufacturerId() { return this.form.controls['manufacturerId']; }

  async ngOnInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      yearModel: new FormControl(null, Validators.required),
      productTypeId: new FormControl(null, Validators.required),
      manufacturerId: new FormControl(null, Validators.required)
    });
  }

  async onDataAdded() {
    this.isSubmitted = false;

    let data = this.editMakeModel.getData() as MakeModel;

    if (!data) return;

    if (data.Id == null) {
      this.form.reset();
      this.refreshTitle(data.Name);
      return;
    }

    this.form.setValue({
      id: data.Id,
      name: data.Name,
      yearModel: data.YearModel,
      productTypeId: data.ProductTypeId,
      manufacturerId: data.ManufacturerId
    });

    this.refreshTitle(data.Name);

    this.submitDisabled = true;

    this.selectProductTypeDropdownItem(data.ProductTypeName);
    this.selectManufacturerDropdownItem(data.ManufacturerName);
  }

  refreshTitle(name: string = '') {
    this.modalTitle = name
      ? 'Editing: ' + name
      : 'Adding Model';
  }
  
  async selectProductTypeDropdownItem(name: string) {
    this.productTypesLoading = true;
    this.productTypesInput = name || '';
    
    let filter = this.productTypeWordFilter(this.productTypesInput);
    this.productTypeLength = await this.productTypeService.getProductTypeCount(filter);
    
    if (this.productTypeLength){
      this.productTypeBufferArr = [];
      this.productTypePageIndex = 0;

      await this.fetchMoreProductTypes();

      this.productTypesInputLength = this.productTypeBufferArr.length;
      this.productTypeLength = this.productTypeBufferArr.length;
    }

    this.productTypesLoading = false;

    if (!this.manufacturersLoading) this.submitDisabled = false;
  }

  async selectManufacturerDropdownItem(name: string) {
    this.manufacturersLoading = true;
    this.manufacturersInput = name || '';
    
    let filter = this.manufacturerWordFilter(this.manufacturersInput);
    this.manufacturerLength = await this.manufacturerService.getManufacturerCount(filter);

    if (this.manufacturerLength) {
      this.manufacturerBufferArr = [];
      this.manufacturerPageIndex = 0;

      await this.fetchMoreManufacturers();

      this.manufacturersInputLength = this.manufacturerBufferArr.length;
      this.manufacturerLength = this.manufacturerBufferArr.length;
    }

    this.manufacturersLoading = false;

    if (!this.productTypesLoading) this.submitDisabled = false;
  }

  async onSubmit() {
    this.isSubmitted = true;

    if (!this.form.valid) return;
    
    this.spinnerService.show(this.fullScreenSpinner);

    let isNew = !this.id.value;
    let isSuccessful = await this.save();

    this.spinnerService.hide(this.fullScreenSpinner);

    if (!isSuccessful) return;

    this.isSubmitted = false;
    this.form.reset();
    this.editMakeModel.close();

    if (isNew)
      this.makeModelAdded.emit();
    else
      this.makeModelEdited.emit();
  }

  async save() {
    let model = new MakeModel();

    model.Construct({
      Id: this.id.value, 
      Name: this.name.value,
      YearModel:  this.yearModel.value,
      ProductTypeId: this.productTypeId.value,
      ManufacturerId: this.manufacturerId.value,
    });

    let isSuccessful: boolean;

    await this.makeModelService.editMakeModel(model)
      .then(_ => isSuccessful = true)
      .catch(err => {
        if (err.status === 409) {
          this.name.setErrors({ duplicate: true });
        }
      });

    return isSuccessful;
  }

  async onProductTypeScrollToEnd() {
    if (this.productTypesLoading || this.productTypeLength <= this.productTypesInputLength)
        return;
      
    await this.fetchMoreProductTypes();
  }

  async onManufacturerScrollToEnd() {
    if (this.manufacturersLoading || this.manufacturerLength <= this.manufacturersInputLength)
        return;
      
    await this.fetchMoreManufacturers();
  }

  onProductTypeClear()
  {
    this.productTypesInput = '';

    this.productTypesLoading = true;

    this.productTypeService.getProductTypeCount(this.productTypeWordFilter(this.productTypesInput))
    .then(data => {
      this.productTypeLength = data;

      if (!this.productTypeLength) {
        this.productTypesInputLength = 0;
        this.productTypesLoading = false;

        return;
      }
      
      this.productTypeBufferArr = [];
      this.productTypePageIndex = 0;
    
      this.fetchMoreProductTypes();
    });
  }

  onManufacturerClear()
  {
    this.manufacturersInput = '';

    this.manufacturersLoading = true;

    this.manufacturerService.getManufacturerCount(this.productTypeWordFilter(this.manufacturersInput))
    .then(data => {
      this.manufacturerLength = data;

      if (!this.manufacturerLength) {
        this.manufacturersInputLength = 0;
        this.manufacturersLoading = false;

        return;
      }
      
      this.manufacturerBufferArr = [];
      this.manufacturerPageIndex = 0;
    
      this.fetchMoreManufacturers();
    });
  }

  onProductTypeSearch(obj: {term:string, items:any[]})
  {
    if (this.productTypeSearchTimer)
      clearTimeout(this.productTypeSearchTimer);
    
    this.productTypeSearchTimer = setTimeout(() => {
      let term = obj.term;
      let items = obj.items;

      this.productTypesInput = term.trim();

      this.productTypesLoading = true;

      this.productTypeService.getProductTypeCount(this.productTypeWordFilter(this.productTypesInput))
      .then(data => {
        this.productTypeLength = data;
          
        if (!this.productTypeLength || this.productTypeLength == items.length) {
          this.productTypesInputLength = items.length;
          this.productTypesLoading = false;

          return;
        }
        
        this.productTypeBufferArr = [];
        this.productTypePageIndex = 0;
      
        this.fetchMoreProductTypes();
      });
    }, 1000);
  }

  onManufacturerSearch(obj: {term:string, items:any[]})
  {
    if (this.manufacturerSearchTimer)
      clearTimeout(this.manufacturerSearchTimer);
    
    this.manufacturerSearchTimer = setTimeout(() => {
      let term = obj.term;
      let items = obj.items;

      this.manufacturersInput = term.trim();

      this.manufacturersLoading = true;

      this.manufacturerService.getManufacturerCount(this.productTypeWordFilter(this.manufacturersInput))
      .then(data => {
        this.manufacturerLength = data;
          
        if (!this.manufacturerLength || this.manufacturerLength == items.length) {
          this.manufacturersInputLength = items.length;
          this.manufacturersLoading = false;

          return;
        }
        
        this.manufacturerBufferArr = [];
        this.manufacturerPageIndex = 0;
      
        this.fetchMoreManufacturers();
      });
    }, 1000);
  }

  async onProductTypeScroll({ end }) {
    if (this.productTypesLoading || this.productTypeLength <= this.productTypesInputLength)
        return;

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.productTypesInputLength)
        await this.fetchMoreProductTypes();
  }

  async onManufacturerScroll({ end }) {
    if (this.manufacturersLoading || this.manufacturerLength <= this.manufacturersInputLength)
        return;

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.manufacturersInputLength)
        await this.fetchMoreManufacturers();
  }

  async fetchMoreProductTypes() {
    this.productTypesLoading = true;
  
    let newProductTypes = await this.productTypeService.getMoreProductTypes(this.productTypesInput, this.bufferSize, ++this.productTypePageIndex);

    this.productTypeBuffer = {...this.productTypeBuffer, ...newProductTypes};

    this.productTypeBufferArr = Object.keys(this.productTypeBuffer).map(key => {
        return { Id: Number(key), Name: this.productTypeBuffer[key] };
      }).sort((a,b) => 
        (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));

    let bufferLength = Object.keys(this.productTypeBuffer).length;
    
    this.productTypesInputLength = bufferLength > this.productTypeLength ? this.productTypeLength : bufferLength;

    this.productTypesLoading = false;
  }
  
  async fetchMoreManufacturers() {
    this.manufacturersLoading = true;
  
    let newManufacturers = await this.manufacturerService.getMoreManufacturers(this.manufacturersInput, this.bufferSize, ++this.manufacturerPageIndex);

    this.manufacturerBuffer = {...this.manufacturerBuffer, ...newManufacturers};

    this.manufacturerBufferArr = Object.keys(this.manufacturerBuffer).map(key => {
        return { Id: Number(key), Name: this.manufacturerBuffer[key] };
      }).sort((a,b) => 
        (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));

    let bufferLength = Object.keys(this.manufacturerBuffer).length;
    
    this.manufacturersInputLength = bufferLength > this.manufacturerLength ? this.manufacturerLength : bufferLength;

    this.manufacturersLoading = false;
  }

  private productTypeWordFilter(term: string)
  {
    return new FilterModel("Word", term);
  }
  
  private manufacturerWordFilter(term: string)
  {
    return new FilterModel("Word", term);
  }
}
