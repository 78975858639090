import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { SailingSchedule } from 'src/app/models/SailingScedule';
import { SailingScheduleService } from 'src/app/services/http/sailing-schedule/sailing-schedule.service';
import { VoyageService } from 'src/app/services/http/voyage/voyage.service';
import { PortService } from 'src/app/services/http/port/port.service';

@Component({
	selector: 'app-add-sailing-schedule',
	templateUrl: './add-sailing-schedule.component.html',
	styleUrls: ['./add-sailing-schedule.component.scss']
})
export class AddSailingScheduleComponent implements OnInit {

	@Output() sailingScheduleAdded = new EventEmitter();
	@Input() vessels: any;
	@Input() countries: any;

	public voyages: any;
	public originPorts: any;
	public destinationPorts: any;

	sailingSchedule: SailingSchedule = new SailingSchedule();

	constructor(
		private spinner: NgxSpinnerService,
		private ngxSmartModalService: NgxSmartModalService,
		private sailingScheduleService: SailingScheduleService,
		private voyageService: VoyageService,
		private portService: PortService
	) {
		this.sailingSchedule.VesselId = null;
		this.sailingSchedule.VoyageId = null;
		this.sailingSchedule.OriginPortId = null;
		this.sailingSchedule.DestinationPortId = null;
		this.sailingSchedule.OriginCountryId = null;
		this.sailingSchedule.DestinationCountryId = null;
		this.sailingSchedule.DistributorId = this.sailingScheduleService.distributorId;
	}

	ngOnInit() {

	}

	async addSailingSchedules(sailingSchedule: NgForm) {
		if (sailingSchedule.valid) {
			this.spinner.show('fullScreenSpinner');
			await this.sailingScheduleService.saveSailingSchedule(this.sailingSchedule);
			this.spinner.hide('fullScreenSpinner');
			this.sailingSchedule.Clear();
			sailingSchedule.resetForm();

			this.ngxSmartModalService.getModal('addSailingSchedule').close()
			this.sailingScheduleAdded.emit();
		}
	}

	async loadVoyages() {
		if (this.sailingSchedule.VesselId) {
			this.spinner.show('modalSpinner');
			this.voyages = await this.voyageService.getVoyagesByVessel(this.sailingSchedule.VesselId);
			this.spinner.hide('modalSpinner');
		}
	}

	async loadOriginPorts() {
		if (this.sailingSchedule.OriginCountryId) {
			this.spinner.show('modalSpinner');
			this.originPorts = await this.portService.getPortsByCountry(this.sailingSchedule.OriginCountryId);
			this.spinner.hide('modalSpinner');
		}
	}

	async loadDestinationPorts() {
		if (this.sailingSchedule.DestinationCountryId) {
			this.spinner.show('modalSpinner');
			this.destinationPorts = await this.portService.getPortsByCountry(this.sailingSchedule.DestinationCountryId);
			this.spinner.hide('modalSpinner');
		}
	}
}
