import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../common/constants/constants.service';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { ChildUser } from 'src/app/models/ChildUser';
import { FilterModel } from 'src/app/models/FilterModel';
import { TableControl } from 'src/app/models/TableControl';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public distributorId: number;
  
  constructor(private http: HttpClient,
    private utilities: UtilitiesService,
    private constants: ConstantsService) {
      this.distributorId = this.constants.DISTRIBUTOR_ID; 
    }

    async changePassword(userId: number, currentPassword: string, newPassword:string) {
      return new Promise((resolve, reject) => {
        var url = this.constants.SERVICE_URL + "api/LookUpValues/ChangePassword?id=" + userId + "&currentPassword=" + currentPassword +"&newPassword=" + newPassword;

        var headers = this.utilities.getAuthorizationHeader();
  
        this.http.get(url, headers).subscribe(data => {
          resolve(data);
        }, err => {
          resolve(err);
        });
      });
    }

    async saveChildUser(childUser: ChildUser): Promise<any> {
      return new Promise((resolve, reject) => {
        let url = this.constants.IDENTITY_URL + "core/createchilduser";
        let body = {
          parentUserId: childUser.ParentUserId,
          email: childUser.ChildUserEmail,
          password: childUser.Password,
          initialClaim: childUser.InitialClaim
        }
  
        let xb = this.utilities.getEncodedBody(body);
        let headers = this.utilities.getFormHeaders();
  
        this.http.post(url, xb, headers).subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    // async deleteChildUser(childUserId: number): Promise<any> {
    //   return new Promise((resolve, reject) => {
    //     let url = this.constants.IDENTITY_URL + `core/deletechilduser`;
        
    //     let body = '=' + childUserId;
    //     let headers = this.utilities.getFormHeaders();
  
    //     this.http.post(url, body, headers).subscribe(data => {
    //       resolve(data);
    //     }, err => {
    //       reject(err);
    //     });
    //   });
    // }

    async getChildUserCount(filter: FilterModel): Promise<any> {
      return new Promise((resolve, reject) => {
        let url = this.constants.IDENTITY_URL + `core/getcountchilduser?distributorId=${this.distributorId}`;
        url = this.utilities.applyTableFilters(url, filter);

        var headers = this.utilities.getAuthorizationHeader();

        this.http.get(url, headers).subscribe(data => {
          resolve(data);
        }, err => {
          resolve(err);
        });
      });
    }

    async getChildUser(tableControl: TableControl) {
      return new Promise((resolve, reject) => {
        let url = this.constants.IDENTITY_URL + `core/getpaginatedchilduser?distributorId=${this.distributorId}`;
        url = this.utilities.applyTableControls(url, tableControl);

        //var headers = this.utilities.getAuthorizationHeader();

        this.http.get(url).subscribe(data => {
          resolve(data);
        }, err => {
          resolve(err);
        });
      });
    }
    
}
