import { Component, OnInit, OnDestroy } from '@angular/core';
import { MakeModelService } from 'src/app/services/http/make-model/make-model.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterOption } from 'src/app/models/FilterOption';
import { FilterModel } from 'src/app/models/FilterModel';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';
import { MakeModel } from 'src/app/models/MakeModel';

@Component({
  selector: 'app-view-make-models',
  templateUrl: './view-make-models.component.html',
  styleUrls: ['./view-make-models.component.scss']
})
export class ViewMakeModelsComponent implements OnInit, OnDestroy {
  //Modal Identifier Constants
  addMakeModelModal: string = 'addMakeModel';
  editMakeModelModal: string = 'editMakeModel';
  deleteMakeModelModal: string = 'deleteMakeModel';

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];
  
  //Local Variables
  makeModels: any;
	productTypes: any;
  manufacturers: any;
  
  isSyncing = false;
  syncPoll: any;
  progressRate = 0;
  loadedOngoingSync = false;


  constructor(private makeModelService: MakeModelService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService : ToastrService) { 
      this.filterOptions = [
        new FilterOption('Id', 'Id'),
        new FilterOption('MakeOrModelName', 'Model'),
        new FilterOption('ProductTypeName', 'Product Type'),
        new FilterOption('ManufacturerName', 'Manufacturer'),
        new FilterOption('YearModel', 'Year Model'),
        new FilterOption('CreatedDT', 'Modified On'),
        new FilterOption('CreatedByName', 'Modified By')
      ];

      this.tableControl = new TableControl(10);
  }
    
  async ngOnInit() {
    this.spinner.show("fullScreenSpinner");
    await this.getItemCount();
    this.spinner.hide("fullScreenSpinner");

    await this.loadMakeModels();
    
    if (await this.getSyncStatus())
      await this.initializeSyncPolling();
  }

  ngOnDestroy(): void {
    clearInterval(this.syncPoll);
  }

  private async getItemCount(){
    this.tableControl.TotalItemCount = await this.makeModelService.getMakeModelCount(this.tableControl.Filter);
  }
  
  async syncMakeModels() {
    let result = await this.makeModelService.syncAll();

    this.isSyncing = result && result.syncId;

    if (this.isSyncing)
      this.initializeSyncPolling();
  }

  private async getSyncStatus(): Promise<any> {
    return await this.makeModelService.getSyncStatus();
  }

  async initializeSyncPolling() {
    this.isSyncing = true;

    clearInterval(this.syncPoll);

    this.syncPoll = setInterval(() => {
      this.getSyncStatus().then(result => {
        this.isSyncing = !!result.syncId;
        
        this.progressRate = parseFloat((result.progress * 100).toFixed(2));

        if (this.isSyncing)
          this.loadedOngoingSync = true;

        if (!this.isSyncing && this.syncPoll) {
          clearInterval(this.syncPoll);
          this.syncPoll = null;

          if (this.loadedOngoingSync) {
            this.loadedOngoingSync = false;
            this.toasterService.success("Syncronisation was completed.", "Sync Models");
          }
        }

      });
    }, 2000);
  }

  private async loadMakeModels(){
    this.spinner.show("tableSpinner");
    this.makeModels = await this.getMakeModels();
    this.tableControl.CurrentItemCount = this.makeModels.length;
    this.spinner.hide("tableSpinner");
  }

  private async getMakeModels(){
    return this.makeModelService.getMakeModels(this.tableControl);
  }

  addMakeModel(){
    this.openMakeModel();
  }

  editMakeModel(makeModel: any){
    this.openMakeModel(makeModel);
  }

  openMakeModel(makeModel: any = null) {
    this.ngxSmartModalService.resetModalData(this.editMakeModelModal);
    
    let makeModelForm = new MakeModel();
    
    if (makeModel) {
      makeModelForm.Construct({
        Id: makeModel.Id,
        Name: makeModel.MakeOrModelName,
        YearModel: makeModel.YearModel,
        ProductTypeId: makeModel.ProductTypeId,
        ProductTypeName: makeModel.ProductTypeName,
        ManufacturerId: makeModel.ManufacturerId,
        ManufacturerName: makeModel.ManufacturerName
      })
    };

    this.ngxSmartModalService.setModalData(makeModelForm, this.editMakeModelModal);

    this.ngxSmartModalService.open(this.editMakeModelModal);
  }

  deleteMakeModel(makeModel: any){
    this.ngxSmartModalService.resetModalData(this.deleteMakeModelModal);
    this.ngxSmartModalService.setModalData(makeModel, this.deleteMakeModelModal);
    this.ngxSmartModalService.open(this.deleteMakeModelModal);
  }

  importMakeModels(){
    this.ngxSmartModalService.open("importMakeModels");
  }

  //EMMITED EVENT ACTIONS
  onMakeModelAdded(){
    this.tableControl.Page = 1; 
    this.getItemCount();
    this.loadMakeModels();
    this.toasterService.success("Model successfully Added.", "Add Model");
  }

  onMakeModelEdited(){
    this.loadMakeModels();
    this.toasterService.success("Model successfully Edited.", "Edit Model");
  }

  onMakeModelDeleted(){
    this.getItemCount();
    this.loadMakeModels();
    this.toasterService.success("Model successfully Deleted.", "Delete Model");
  }

  onMakeModelsImported(){
    this.getItemCount();
    this.loadMakeModels();
    this.toasterService.success("Models successfully Imported.", "Import Models");
  }

  onPageChanged({ page, maxPageSize }){
    this.tableControl.Page = page;
		this.tableControl.MaxPageSize = maxPageSize;

    this.loadMakeModels();
  }

  onFiltered(filter : FilterModel){
    this.tableControl.Page = 1; 
    this.tableControl.Filter = filter;
    this.getItemCount();
    this.loadMakeModels();
  }

  onSorted(sort: string){
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1; 
    this.loadMakeModels();
  }
}
