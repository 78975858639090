import { Component, EventEmitter, Output, Input } from '@angular/core';
import { FilterModel } from 'src/app/models/FilterModel';
import { FilterOption } from 'src/app/models/FilterOption';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent {
  @Output() filtered = new EventEmitter<FilterModel>();
  @Input() filterOptions: any;
  trueFalse: string = null;
  selectedFilterOption: FilterOption;

  constructor() {
    this.selectedFilterOption = new FilterOption("", "");
    this.filterModel = new FilterModel("", "");
  }

  get filterOptionsWithValues() {
    if (!this.filterOptions) return;
    return (this.filterOptions as FilterOption[])
            .filter(f => f.Value);
  }

  timeout: any = null;
  filterModel: FilterModel;

  filter(event) {
    if(event.keyCode === 13){
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
  
      this.timeout = window.setTimeout(() => {
        this.selectedFilterOption = this.getFilterOptionByValue(this.filterModel.Type);
  
        if (this.selectedFilterOption.Type == "truefalse")
          this.filtered.emit(new FilterModel(this.filterModel.Type, this.trueFalse == null ? '' : this.trueFalse));
        else
          this.filtered.emit(this.filterModel);
      }, 800);
    }
    
  }

  filterTrueFalse(e: any) {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }

    this.timeout = window.setTimeout(() => {
      const filterModelText = e == null ? '' : e.toString();
  
      this.filtered.emit(new FilterModel(this.filterModel.Type, filterModelText));
    }, 800);
  }

  getFilterOptionByValue(value: string) {
    const filterOptions = this.filterOptionsWithValues.filter(f => f.Value == value);

    if (filterOptions && filterOptions.length)
      return filterOptions[0];

    return new FilterOption("", "");
  }
}
