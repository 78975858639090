import { Incoterm } from './Incoterm';

export class Charge {
	public Id: number;
	public Name: string;
	public Denomination: Denomination;
	public IsDefault: boolean;

	constructor(name: string, denomination?: Denomination, isDefault?: boolean, id?: number) {
		this.Id = id || null;
		this.Name = name;
		this.Denomination = denomination || new Denomination();
		this.IsDefault = isDefault || false;
	}
	
	static Default(): Charge[] {
		return [
			new Charge("Fixed Fee", null, true),
			new Charge("Cost Per Revenue Tonne", null, true),
			new Charge("Fee Per 1000kg", null, true),
		];
	}

}

export class Denomination {
	public CurrencyId: number;
	public Amount: number;

	constructor(currencyId?: number, amount?: number) {
		this.CurrencyId = currencyId || null;
		this.Amount = amount || null;
	}
}

export class CarOceanFreight {
	Name: string;
	PaymentTypeId: number;
	Denomination: Denomination;

	static readonly LESS_THAN_14_RT = '<=14 Revenue Tonnes';
	static readonly MORE_THAN_14_RT = '>=14.001 and <=18 Revenue Tonnes';
	static readonly MORE_THAN_18_RT = '>=18.001 and <=20  Revenue Tonnes';
	static readonly MORE_THAN_20_RT = '>20.001  Revenue Tonnes';

	constructor(name: string, paymentType?: number, denomination?: Denomination) {
		this.Name = name;
		this.PaymentTypeId = paymentType || null;
		this.Denomination = denomination || new Denomination();
	}

	static Map(obj?: any): any {
		if (!obj) return this.Default();

		return {
			lessThan14: new CarOceanFreight(this.LESS_THAN_14_RT, obj.SmallSizePaymentTypeId, new Denomination(obj.SmallSizeCurrencyId, obj.SmallSizeRate)),
			moreThan14: new CarOceanFreight(this.MORE_THAN_14_RT, obj.MediumSizePaymentTypeId, new Denomination(obj.MediumSizeCurrencyId, obj.MediumSizeRate)),
			moreThan18: new CarOceanFreight(this.MORE_THAN_18_RT, obj.LargeSizePaymentTypeId, new Denomination(obj.LargeSizeCurrencyId, obj.LargeSizeRate)),
			moreThan20: new CarOceanFreight(this.MORE_THAN_20_RT, obj.ExtraLargeSizePaymentTypeId, new Denomination(obj.ExtraLargeSizeCurrencyId, obj.ExtraLargeSizeRate))
		};
	}

	static Default(): any {
		return {
			lessThan14: new CarOceanFreight(this.LESS_THAN_14_RT),
			moreThan14: new CarOceanFreight(this.MORE_THAN_14_RT),
			moreThan18: new CarOceanFreight(this.MORE_THAN_18_RT),
			moreThan20: new CarOceanFreight(this.MORE_THAN_20_RT)
		};
	}

	static ToJson(obj: any): any {
		return {
			SmallSizePaymentTypeId: obj.lessThan14.PaymentTypeId,
			SmallSizeCurrencyId: obj.lessThan14.Denomination.CurrencyId,
			SmallSizeRate: obj.lessThan14.Denomination.Amount,
			MediumSizePaymentTypeId: obj.moreThan14.PaymentTypeId,
			MediumSizeCurrencyId: obj.moreThan14.Denomination.CurrencyId,
			MediumSizeRate: obj.moreThan14.Denomination.Amount,
			LargeSizePaymentTypeId: obj.moreThan18.PaymentTypeId,
			LargeSizeCurrencyId: obj.moreThan18.Denomination.CurrencyId,
			LargeSizeRate: obj.moreThan18.Denomination.Amount,
			ExtraLargeSizePaymentTypeId: obj.moreThan20.PaymentTypeId,
			ExtraLargeSizeCurrencyId: obj.moreThan20.Denomination.CurrencyId,
			ExtraLargeSizeRate: obj.moreThan20.Denomination.Amount,
		}
	}
}

export class FreightRate {
	public Id: number;
	//public CarrierId: number;
	public OriginId: number;
	public DestinationId: number;
	public OriginCountryId: number;
	public DestinationCountryId: number;
	public Rates: any;
	public RateTypeId: number;
	public OceanFreight: Denomination;
	public ExportCharges: Charge[];
	public ImportCharges: Charge[];
	public IncotermFromId: number;
	public IncotermToId: number;
	public IsAllCategories: boolean;
	public CarOceanFreights: any;

	public Clear() {
		this.Id = null;
		//this.CarrierId = null;
		this.OriginCountryId = null;
		this.OriginId = null;
		this.DestinationCountryId = null;
		this.DestinationId = null;
		this.RateTypeId = null;
		this.OceanFreight = new Denomination();
		this.ExportCharges = Charge.Default();
		this.ImportCharges = Charge.Default();
		this.IncotermFromId = null;
		this.IncotermToId = null;
		this.IsAllCategories = true;
		this.CarOceanFreights = CarOceanFreight.Default();
	}

	public Construct(object?: any) {
		this.Id = object.Id;
		//this.CarrierId = object.CarrierId;
		this.OriginCountryId = object.OriginCountryId;
		this.OriginId = object.OriginId;
		this.DestinationCountryId = object.DestinationCountryId;
		this.DestinationId = object.DestinationId;
		this.RateTypeId = object.RateTypeId;
		this.OceanFreight = object.OceanFreight;
		this.ExportCharges = object.ExportCharges;
		this.ImportCharges = object.ImportCharges;
		this.IncotermFromId = object.IncotermFromId;
		this.IncotermToId = object.IncotermToId;
		this.IsAllCategories = object.IsAllCategories || object.IsAllCategories == null;
		this.CarOceanFreights = object.CarOceanFreights;
	}

	public SortAddCharges(charges: any) {
		const dc = Charge.Default();
		charges.forEach((c) => {
			const defCharge = dc.find((x) => x.Name.toLowerCase() == c.Name.toLowerCase());
			var charge = new Charge(c.Name, new Denomination(c.CurrencyId, c.Rates), !!defCharge, null);

			if (c.ChargeTypeId == 1) {
				let index = this.ExportCharges.findIndex((x) => x.Name.toLowerCase() == c.Name.toLowerCase());
				if (index >= 0) {
					charge.IsDefault = true;
					this.ExportCharges[index] = charge;
				} else {
					this.ExportCharges.push(charge);
				}
			}
			else {
				let index = this.ImportCharges.findIndex((x) => x.Name.toLowerCase() == c.Name.toLowerCase());
				if (index >= 0) {
					charge.IsDefault = true;
					this.ImportCharges[index] = charge;
				} else {
					this.ImportCharges.push(charge);
				}
			}
		});
	}
}