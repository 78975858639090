import { Component, OnInit, OnDestroy } from '@angular/core';
import { InsuranceCertificateService } from 'src/app/services/http/insurance-certificate/insurance-certificate.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterOption } from 'src/app/models/FilterOption';
import { FilterModel } from 'src/app/models/FilterModel';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';

@Component({
  selector: 'app-view-bordereau',
  templateUrl: './view-bordereau.component.html',
  styleUrls: ['./view-bordereau.component.scss']
})
export class ViewBordereauComponent implements OnInit, OnDestroy {
  //Modal Identifier Constants
  detailedBordereauModal: string = "detailedBordereau";

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];
  
  //Local Variables
  bordereaus: any;
  isSyncing = false;
  syncPoll: any;
  loadedOngoingSync = false;
  progressRate = 0;

  constructor(private insuranceCertificatesService: InsuranceCertificateService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService : ToastrService) { 
      this.filterOptions = [
        new FilterOption("CertificatesId", "Policy No"),
        new FilterOption("AssuredBy", "Name of Insured"),
        new FilterOption("CreatedDTString", "Certificate Date"),
        new FilterOption("ETAString", "Arrival Date"),
        new FilterOption("TransactionType", "Transaction Type"),
        new FilterOption("Currency", "Currency"),
        new FilterOption("InsuredValue", "Insured Value"),
        new FilterOption("Premium", "Premium"),
        new FilterOption("CreatedBy", "Created By")
      ];

      this.tableControl = new TableControl(10);
    }

  async ngOnInit() {
    this.spinner.show("fullScreenSpinner");
    await this.getItemCount();
    this.spinner.hide("fullScreenSpinner");

    await this.loadInsuranceCertificates();
  }

  async ngOnDestroy() {
    clearInterval(this.syncPoll);
  }

  private async loadInsuranceCertificates(){
    this.spinner.show("tableSpinner");
    this.bordereaus = await this.getInsuranceCertificates();
    this.tableControl.CurrentItemCount = this.bordereaus.length;
    this.spinner.hide("tableSpinner");
  }

  private async getInsuranceCertificates(){
    return this.insuranceCertificatesService.getInsuranceCertificates(this.tableControl);
  }

  private async getItemCount(){
    this.tableControl.TotalItemCount = await this.insuranceCertificatesService.getInsuranceCertificateCount(this.tableControl.Filter);
  }

  viewDetails(insuranceCertificate: any){
    this.ngxSmartModalService.resetModalData(this.detailedBordereauModal);
    this.ngxSmartModalService.setModalData(insuranceCertificate, this.detailedBordereauModal);
    this.ngxSmartModalService.open(this.detailedBordereauModal);
  }

  //EMMITED EVENT ACTIONS

  onPageChanged({ page, maxPageSize }){
    this.tableControl.Page = page;
    this.tableControl.MaxPageSize = maxPageSize;

    this.loadInsuranceCertificates();
  }

  onFiltered(filter : FilterModel){
    this.tableControl.Page = 1; 
    this.tableControl.Filter = filter;
    this.getItemCount();
    this.loadInsuranceCertificates();
  }

  onSorted(sort: string){
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1; 
    this.loadInsuranceCertificates();
  }

}
