import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/services/common/utilities/utilities.service';
import { ConstantsService } from 'src/app/services/common/constants/constants.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  username: string;

  constructor(private utilities: UtilitiesService,
    private constants: ConstantsService) { }

  ngOnInit() {
    var currentUser: any = JSON.parse(this.utilities.getStorage(this.constants.CURRENT_USER));
    if (currentUser){
      this.username = currentUser.profile.preferred_username;
    }
  }

}
