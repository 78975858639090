import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManufacturerService } from 'src/app/services/http/manufacturer/manufacturer.service';
import { isArray } from 'util';

@Component({
  selector: 'app-import-manufacturers',
  templateUrl: './import-manufacturers.component.html',
  styleUrls: ['./import-manufacturers.component.scss']
})
export class ImportManufacturersComponent implements OnInit {
  @Output() manufacturersImported = new EventEmitter();
  @ViewChild('importManufacturers', { static: false }) modal: NgxSmartModalComponent;
  @ViewChild('importExcelControl', { static: false }) importExcelControl: FormControl;

  importManufacturersForm: FormGroup;

  importFailed = false;
  errorMessages: string[] = [];
  importExcel = null;
  isSubmitted = false;

  constructor(
    private manufacturerService: ManufacturerService,
    private spinner: NgxSpinnerService) { }

    ngOnInit() {
      this.importManufacturersForm = new FormGroup({
        importExcel: new FormControl(null),
        file: new FormControl(null, [Validators.required, this.fileTypeExcel.bind(this)])
      });
    }
  
    onFileChange(files: File[]) {
      this.importFailed = false;
      this.errorMessages = [];
      
      if (files.length) {
        this.importManufacturersForm.patchValue({ file: files[0] });
        this.importManufacturersForm.get('file').markAsDirty();
        this.importManufacturersForm.get('file').updateValueAndValidity();
      }
    }
  
    fileTypeExcel(control: FormControl): {[s: string]: boolean} {
      if (control.value && !this.isValidExcelFile(control.value))
        return { 'invalidExcelFile': true };
    }
  
    isValidExcelFile(file: File): boolean {
      const allowedFileTypes = [
        "application/vnd.ms-excel", 
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  
      return !this.isFileTypeCsv(file) && !!allowedFileTypes.some(t => t === file.type);
    }
  
    isFileTypeCsv(file: File) {
      return file.name.split('.').pop().toLowerCase() === 'csv';
    }
  
    removeFile() {
      this.importManufacturersForm.reset();
    }
  
    async onSubmit() {
      this.isSubmitted = true;
      this.importFailed = false;
      this.errorMessages = [];

      if (!this.importManufacturersForm.valid)
        return;
      
      this.spinner.show("fullScreenSpinner");
      const file: File = this.importManufacturersForm.get('file').value;
      
      await this.manufacturerService.importManufacturers(file)
      .then(data => {
        this.isSubmitted = false;
        const result = data;
        if (result && isArray(result)) {
          const errors = this.getErrors(result);
    
          if (errors.length) {
            this.importFailed = true;
            this.errorMessages = errors;
            this.importManufacturersForm.reset();
          }
        } else {
          this.close();
          this.manufacturersImported.emit();
        }
      })
      .catch(err => {
        this.isSubmitted = false;
        this.importFailed = true;
        
        if (err.status === 409)
          this.errorMessages = this.getErrors(err.error);
        else if (err.status === 422)
          this.errorMessages = [err.error];
        else
          this.errorMessages = ["An unexpected error occurred. Try uploading again."];
        
        this.importManufacturersForm.reset();
      });
  
      this.spinner.hide("fullScreenSpinner");
    }
  
    getErrors(data: any[]): string[] {
      const errors: string[] = [];
  
      for (let item of data)
        if (item.HasError)
          errors.push(`${item.Row ? `[Row ${item.Row}] ` : ''}${item.Word} : ${item.ErrorMessage}`);
  
      return errors;
    }
      
    close() {
      this.reset();
      this.modal.close();
    }
    
    reset() {
      this.isSubmitted = false;
      this.importFailed = false;
      this.errorMessages = [];
      this.importManufacturersForm.reset();
    }

}
