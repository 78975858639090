import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() totalItemCount : number;
  @Input() currentItemCount : number;
  @Input() maxPageSize : number;
  @Output() pageChange = new EventEmitter<any>();

  readonly defaultMaxPageSize = 10;
  goToPageTimer: any;
  page = 1;

  onPageChanged(event : any){
    this.page = event;

    this.pageChange.emit({ page: this.page, maxPageSize: this.maxPageSize});
  }

  getRange() {
    return ((this.maxPageSize)*(this.page - 1) + this.currentItemCount) || 0;
  }

  onItemsPerPageChanged(value: string) {
    let maxPageSize = parseInt(value);

    if (isNaN(maxPageSize)) return;

    if (!this.maxPageSizeOptions.some(m => m === maxPageSize))
      maxPageSize = this.defaultMaxPageSize;

    let pageLimit = Math.ceil(this.totalItemCount / maxPageSize);
    
    this.page = Math.min(this.page, pageLimit);
    this.maxPageSize = maxPageSize;

    this.pageChange.emit({ page: this.page, maxPageSize: this.maxPageSize});
  }

  goToPage(value: string) {
    if (this.goToPageTimer)
      clearTimeout(this.goToPageTimer);

    this.goToPageTimer = setTimeout(() => {
      var page = parseInt(value);
  
      if (isNaN(page) || page < 1) return;

      if (this.page === page) return;

      this.page = page;

      this.pageChange.emit({ page: this.page, maxPageSize: this.maxPageSize});
    }, 800);
  }

  escapeGoTo(el: any) {
    let page = parseInt(el.value);

    if (isNaN(page) || page < 1) {
      el.value = this.page.toString();
      return;
    }

    el.value = page;
  }

  get maxPageSizeOptions(): number[] {
    return [10, 20, 30, 40, 50];
  }
}
