import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { VoyageService } from 'src/app/services/http/voyage/voyage.service';
import { Voyage } from 'src/app/models/Voyage';
import { Vessel } from 'src/app/models/Vessel';

@Component({
  selector: 'app-edit-voyage',
  templateUrl: './edit-voyage.component.html',
  styleUrls: ['./edit-voyage.component.scss']
})
export class EditVoyageComponent implements OnInit {
  @Output() voyageEdited = new EventEmitter();
  @Input() vessels: any;
  voyage: Voyage = new Voyage();
  vessel: Vessel = new Vessel();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private voyageService: VoyageService) { }

  ngOnInit() {
  }

  async editVoyages(voyage: NgForm){
    if (voyage.valid){
      this.spinner.show("fullScreenSpinner");
      
      this.voyage.Construct(voyage.value.VoyageId, voyage.value.VoyageNumber, voyage.value.Vessel);
      await this.voyageService.editVoyage(this.voyage);
      this.spinner.hide("fullScreenSpinner");

      this.ngxSmartModalService.getModal('editVoyage').close()
      this.voyageEdited.emit();
    }
  }
}
