import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Carrier } from 'src/app/models/Carrier';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgForm } from '@angular/forms';
import { CarrierService } from 'src/app/services/http/carrier/carrier.service';

@Component({
  selector: 'app-edit-carrier',
  templateUrl: './edit-carrier.component.html',
  styleUrls: ['./edit-carrier.component.scss']
})
export class EditCarrierComponent implements OnInit {
  @Output() carrierEdited = new EventEmitter();
  carrier: Carrier = new Carrier();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private carrierService: CarrierService) { }

  ngOnInit() {
  }

  async editCarriers(carrier: NgForm){
    if (!carrier.valid)
      return;

      this.spinner.show("fullScreenSpinner");

      this.carrier.Construct(carrier.value.carrierId, carrier.value.carrierName);

      let isSuccess = false;
      
      await this.carrierService.editCarrier(this.carrier)
      .then(data => {
        if (data === "Carrier record exist!")
          carrier.controls['carrierName'].setErrors({ 'duplicate': true });
        else
          isSuccess = true;
      })
      .catch(err => {
        if (err.status === 409)
          carrier.controls['carrierName'].setErrors({ 'duplicate': true });
      });

      this.spinner.hide("fullScreenSpinner");
      
      if (isSuccess) {
        this.carrier.Clear();
        this.ngxSmartModalService.getModal('editCarrier').close()
        this.carrierEdited.emit();
      }
  }
}
