import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TableControl } from 'src/app/models/TableControl';
import { Manufacturer } from 'src/app/models/Manufacturer';
import { FilterModel } from 'src/app/models/FilterModel';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { ConstantsService } from '../../common/constants/constants.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ManufacturerService {
  
  public distributorId: number;
  
  constructor(private http: HttpClient,
    private utilities: UtilitiesService,
    private constants: ConstantsService) {
      // let currentUser = JSON.parse(localStorage.getItem('_currentUser'));
      // this.distributorId = currentUser.profile.sub;
      this.distributorId = this.constants.DISTRIBUTOR_ID;
    }
    
    async getMoreManufacturers(term: string, bufferSize: number, pageIndex: number): Promise<{}> {
      return new Promise((resolve, reject) => {
        var url = this.constants.SERVICE_URL + `api/Manufacturers/GetPaginatedByDistributorId?distributorId=${this.distributorId}`;

        var tableCntrl = new TableControl(bufferSize);
        tableCntrl.Filter = new FilterModel("Word", term);
        tableCntrl.Sorting = "WordASC";
        tableCntrl.Page = pageIndex;

        url = this.utilities.applyTableControls(url, tableCntrl);

        this.http.get<any[]>(url).pipe(
          map((items: {Id:number, Word:string}[]) => {
            var obj = {};
            items.forEach(item => {
              obj[item.Id] = item.Word;
            });
            return obj;
            })
          )
          .subscribe(data => resolve(data),
                      err => reject(err));
      })
  }

    async getAllManufacturers(): Promise<any> {
      return new Promise((resolve, reject) => {
        var url = this.constants.SERVICE_URL + `api/Manufacturers/GetPaginatedByDistributorId?distributorId=${this.distributorId}`;
        url = this.utilities.applyTableControls(url, new TableControl(999));
  
        this.http.get(url).subscribe(data => {
          resolve(data);
        }, err => {
          resolve(err);
        });
      });
    }
  
  async getManufacturers(tableControl: TableControl): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Manufacturers/GetPaginatedByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableControls(url, tableControl);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getManufacturerCount(filter: FilterModel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Manufacturers/GetCountByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async saveManufacturer(manufacturer: Manufacturer): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Manufacturers/Save";
      var body = {
        Word: manufacturer.Name,
        DistributorId: this.distributorId,
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async editManufacturer(manufacturer: Manufacturer): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + "api/Manufacturers/Save";
      var body = {
        Id: manufacturer.Id,
        Word: manufacturer.Name,
        DistributorId: this.distributorId
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async deleteManufacturer(manufacturerId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + `api/Manufacturers/Delete/${manufacturerId}`;
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, null, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }
  
  async importManufacturers(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.constants.SERVICE_URL}api/Manufacturers/ReadExcelFile`;
      const formData = new FormData();
      formData.append('excel', file, file.name);

      this.http.post(url, formData, {
        headers: this.utilities.getAuthorizationTokenHeader(),
        params: new HttpParams().set("distributorId", this.constants.DISTRIBUTOR_ID.toString())
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  async syncAll(): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Manufacturers/SyncAll/${this.distributorId}`;
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, null, headers).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getSyncStatus() : Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Manufacturers/GetSyncStatus/${this.distributorId}`;

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }
}
