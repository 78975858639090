import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigurationService } from 'src/app/services/http/configuration/configuration.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterOption } from 'src/app/models/FilterOption';
import { FilterModel } from 'src/app/models/FilterModel';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';
import { Configuration } from 'src/app/models/Configuration';
import { config } from 'rxjs';

@Component({
  selector: 'app-view-configurations',
  templateUrl: './view-configurations.component.html',
  styleUrls: ['./view-configurations.component.scss']
})
export class ViewConfigurationsComponent implements OnInit, OnDestroy {
  //Modal Identifier Constants
  addConfigurationModal: string = 'addConfiguration';
  editConfigurationModal: string = 'editConfiguration';
  deleteConfigurationModal: string = 'deleteConfiguration';

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];
  
  //Local Variables
  configurations: any;

  isSyncing = false;
  syncPoll: any;
  progressRate = 0;
  loadedOngoingSync = false;

  constructor(private configurationService: ConfigurationService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService : ToastrService) { 
      this.filterOptions = [
        new FilterOption('Id', 'Id'),
        new FilterOption('Name', 'Name'),
        new FilterOption('CreatedDT', 'Modified On'),
        new FilterOption('CreatedByName', 'Modified By')
      ];

      this.tableControl = new TableControl(10);
    }

    async ngOnInit() {
      this.spinner.show("fullScreenSpinner");
      await this.getItemCount();
      this.spinner.hide("fullScreenSpinner");
  
      await this.loadConfigurations();
        
      if (await this.getSyncStatus())
        await this.initializeSyncPolling();
    }
  
    ngOnDestroy(): void {
      clearInterval(this.syncPoll);
    }
  
    private async loadConfigurations(){
      this.spinner.show("tableSpinner");
      this.configurations = await this.getConfigurations();
      this.tableControl.CurrentItemCount = this.configurations.length;
      this.spinner.hide("tableSpinner");
    }
  
    private async getConfigurations(){
      return this.configurationService.getConfigurations(this.tableControl);
    }
  
    private async getItemCount(){
      this.tableControl.TotalItemCount = await this.configurationService.getConfigurationCount(this.tableControl.Filter);
    }
    
  async syncConfigurations() {
    let result = await this.configurationService.syncAll();

    this.isSyncing = result && result.syncId;

    if (this.isSyncing)
      this.initializeSyncPolling();
  }

  private async getSyncStatus(): Promise<any> {
    return await this.configurationService.getSyncStatus();
  }

  async initializeSyncPolling() {
    this.isSyncing = true;

    clearInterval(this.syncPoll);

    this.syncPoll = setInterval(() => {
      this.getSyncStatus().then(result => {
        this.isSyncing = !!result.syncId;
        
        this.progressRate = parseFloat((result.progress * 100).toFixed(2));

        if (this.isSyncing)
          this.loadedOngoingSync = true;

        if (!this.isSyncing && this.syncPoll) {
          clearInterval(this.syncPoll);
          this.syncPoll = null;

          if (this.loadedOngoingSync) {
            this.loadedOngoingSync = false;
            this.toasterService.success("Syncronisation was completed.", "Sync Configurations");
          }
        }

      });
    }, 2000);
  }

  addConfiguration(){
    this.openEditConfiguration();
  }

  editConfiguration(configuration: any){
    this.openEditConfiguration(configuration);
  }

  openEditConfiguration(configuration: any = null) {
    this.ngxSmartModalService.resetModalData(this.editConfigurationModal);

    let configurationForm = new Configuration();

    if (configuration)
      configurationForm.Construct(configuration.Id, configuration.Name);
  
    this.ngxSmartModalService.setModalData(configurationForm, this.editConfigurationModal);
    this.ngxSmartModalService.open(this.editConfigurationModal);
  }
  
  deleteConfiguration(configuration: any){
    this.ngxSmartModalService.resetModalData(this.deleteConfigurationModal);
    this.ngxSmartModalService.setModalData(configuration, this.deleteConfigurationModal);
    this.ngxSmartModalService.open(this.deleteConfigurationModal);
  }

  importConfigurations(){
    this.ngxSmartModalService.open("importConfigurations");
  }

  //EMMITED EVENT ACTIONS
  onConfigurationAdded(){
    this.tableControl.Page = 1; 
    this.getItemCount();
    this.loadConfigurations();
    this.toasterService.success("Configuration successfully Added.", "Add Configuration");
  }

  onConfigurationEdited(){
    this.loadConfigurations();
    this.toasterService.success("Configuration successfully Edited.", "Edit Configuration");
  }

  onConfigurationDeleted(){
    this.getItemCount();
    this.loadConfigurations();
    this.toasterService.success("Configuration successfully Deleted.", "Delete Configuration");
  }

  onConfigurationsImported(){
    this.getItemCount();
    this.loadConfigurations();
    this.toasterService.success("Configurations successfully Imported.", "Import Configurations");
  }

  onPageChanged({ page, maxPageSize }){
    this.tableControl.Page = page;
    this.tableControl.MaxPageSize = maxPageSize;

    this.loadConfigurations();
  }

  onFiltered(filter : FilterModel){
    this.tableControl.Page = 1; 
    this.tableControl.Filter = filter;
    this.getItemCount();
    this.loadConfigurations();
  }

  onSorted(sort: string){
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1; 
    this.loadConfigurations();
  }
}
