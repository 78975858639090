export class Manufacturer {
    public Id: number;
    public Name: string;
    public DistributorId: number;
    public Company: string;

    public Clear(){
        this.Name = "";
        this.DistributorId = null;
        this.Company = "";
    }

    public Construct(id: number, name: string){
        this.Id = id;
        this.Name = name;
    }
}