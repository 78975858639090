import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DistributorService } from 'src/app/services/http/distributor/distributor.service';

@Component({
  selector: 'app-delete-distributor',
  templateUrl: './delete-distributor.component.html',
  styleUrls: ['./delete-distributor.component.scss']
})
export class DeleteDistributorComponent implements OnInit {

  @Output() distributorDeleted = new EventEmitter();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private distributorService: DistributorService) { }

  ngOnInit() {
  }

  async deleteDistributors(distributorId: number) {
    this.spinner.show("fullScreenSpinner");
    await this.distributorService.deleteDistributor(distributorId);
    this.spinner.hide("fullScreenSpinner");

    this.ngxSmartModalService.getModal('deleteDistributor').close()
    this.distributorDeleted.emit();
  }
}
