import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DistributorFeeService } from 'src/app/services/http/distributorfee/distributorfee.service';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { DistributorFee } from 'src/app/models/DistributorFee';
import { UtilitiesService } from 'src/app/services/common/utilities/utilities.service';
import { ConstantsService } from 'src/app/services/common/constants/constants.service';
import { PayPalCurrencyService } from 'src/app/services/http/paypal-currency/paypal-currency.service';

@Component({
  selector: 'app-edit-distributor-fee',
  templateUrl: './edit-distributor-fee.component.html',
  styleUrls: ['./edit-distributor-fee.component.scss']
})
export class EditDistributorFeeComponent implements OnInit {
  @Output() distributorFeeEdited = new EventEmitter();
  @Output() distributorFeeAdded = new EventEmitter();
  @Input() currencies: any;
	@ViewChild('EditDistributorFee', {static: false}) editDistributorFee: NgxSmartModalComponent;
  currentUser: any;

  form: FormGroup;
  
  fullScreenSpinner = 'fullScreenSpinner';
  isSubmitted: any;
  modalTitle: string;

  constructor(private spinnerService: NgxSpinnerService,
    private distributorFeeService: DistributorFeeService,
    private utilities: UtilitiesService,
    private constants: ConstantsService,
    private payPalCurrencyService: PayPalCurrencyService,) { 
      this.currentUser = JSON.parse(this.utilities.getStorage(this.constants.CURRENT_USER));
    }

  ngOnInit() {
    this.loadCurrencies();

    this.form = new FormGroup({
      id: new FormControl(null),
      feeDescription: new FormControl(null, Validators.required),
      currencyId: new FormControl(null, Validators.required),
      feeRates: new FormControl(null, Validators.required)
    });
  }

  get id() { return this.form.controls['id']; }
  get feeDescription() { return this.form.controls['feeDescription']; }
  get currencyId() { return this.form.controls['currencyId']; }
  get feeRates() { return this.form.controls['feeRates']; }

  async onDataAdded() {
    this.isSubmitted = false;
    let data = this.editDistributorFee.getData() as DistributorFee;

    if (!data) return;

    if (data.Id == null) {
      this.form.reset();
      this.refreshTitle();
      return;
    }

    this.form.setValue({
      id: data.Id,
      feeDescription: data.FeeDescription,
      currencyId: data.CurrencyId,
      feeRates: data.FeeRates
    });
  }

  async onSubmit() {
    this.isSubmitted = true;

    if (!this.form.valid) return;

    this.spinnerService.show(this.fullScreenSpinner);

    let isNew = !this.id.value;
    let isSuccessful = await this.save();

    this.spinnerService.hide(this.fullScreenSpinner);

    if (!isSuccessful) return;

    this.isSubmitted = true;
    this.form.reset();
    this.editDistributorFee.close();

    if (isNew)
      this.distributorFeeAdded.emit();
    else
      this.distributorFeeEdited.emit();
  }

  async save() {
    let distributorFee = new DistributorFee();
    distributorFee.Construct({
      Id: this.id.value, 
      FeeDescription: this.feeDescription.value,
      CurrencyId: this.currencyId.value,
      FeeRates: this.feeRates.value
    });
  
    let isSuccessful: boolean;

    await this.distributorFeeService.save(distributorFee).then(_ => isSuccessful = true);

    return isSuccessful;
  }

  private async loadCurrencies() {
		this.currencies = await this.payPalCurrencyService.getBare();
	}

  refreshTitle(name: string = '') {
    this.modalTitle = name
      ? 'Editing ' + name
      : 'Adding Distributor Fee';
  }
}
