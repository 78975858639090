import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/http/user/user.service';
import { UtilitiesService } from 'src/app/services/common/utilities/utilities.service';
import { ConstantsService } from 'src/app/services/common/constants/constants.service';

@Component({
  selector: 'app-change-user-password',
  templateUrl: './change-user-password.component.html',
  styleUrls: ['./change-user-password.component.scss']
})
export class ChangeUserPasswordComponent implements OnInit {
  currentUser: any;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;

  constructor(private spinner: NgxSpinnerService,
    private utilities: UtilitiesService,
    private constants: ConstantsService,
    private toasterService: ToastrService,
    private userService: UserService) { 
      this.currentUser = JSON.parse(this.utilities.getStorage(this.constants.CURRENT_USER));
    }

  ngOnInit() {
  }

  async changePassword(password: NgForm) {
    if (password.valid) {
      if (this.newPassword == this.confirmPassword) {
        this.spinner.show("fullScreenSpinner");
        var result: any = await this.userService.changePassword(this.currentUser.profile.sub, this.currentPassword, this.newPassword);
        var resultContent = JSON.parse(result).Content;
        this.spinner.hide("fullScreenSpinner");
        
        if (resultContent == "Success!") {
          this.toasterService.success("Password successfully changed. You will be logged in a few moments.", "Change Password");
          this.spinner.show("fullScreenSpinner");

          setTimeout(function() {
            (window as any).signOutUser();
          }, 3000);
        } else if (resultContent == "Incorrect password.") {
          this.toasterService.error("Incorrect password.", "Change Password");
        } else {
          this.toasterService.error("Password change failed.", "Change Password");
        }
      } else{
        this.toasterService.error("New Password does not match!", "Change Password");
      }

    }
  }
}
