import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../common/constants/constants.service';

@Injectable({
  providedIn: 'root'
})
export class PayPalCurrencyService {
  payPalCurrencies: any[];

  constructor(private http: HttpClient,
    private constants: ConstantsService) { 
      this.payPalCurrencies = [];
    }

  async getBare(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/PayPalCurrencies/GetBare";

      this.http.get(url).subscribe((data: any[]) => {
        this.payPalCurrencies = data;
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }
}