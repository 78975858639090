export class Voyage {
    public Id: number;
    public VoyageNumber: string;
    public VesselId: number;

    public Clear(){
        this.VoyageNumber = null;
        this.VesselId = null;
    }

    public Construct(id:number, voyageNumber: string, vesselId: number){
        this.Id = id;
        this.VoyageNumber = voyageNumber;
        this.VesselId = vesselId;
    }
}
