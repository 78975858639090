import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TableControl } from 'src/app/models/TableControl';
import { FilterModel } from 'src/app/models/FilterModel';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { ConstantsService } from '../../common/constants/constants.service';
import { Voyage } from 'src/app/models/Voyage';

@Injectable({
  providedIn: 'root'
})
export class VoyageService {

  constructor(private http: HttpClient,
    private utilities: UtilitiesService,
    private constants: ConstantsService) { }

  async getVoyages(tableControl: TableControl) {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Voyage/GetPaginatedByDistributorId";
      url = this.utilities.applyTableControls(url, tableControl);

      this.http.get(url, {
        params: new HttpParams().set("distributorId", this.constants.DISTRIBUTOR_ID.toString())
      }).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getVoyagesCount(filter: FilterModel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Voyage/GetCountByDistributorId";
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url, {
        params: new HttpParams().set("distributorId", this.constants.DISTRIBUTOR_ID.toString())
      }).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getVoyagesByVessel(vesselId: number) {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Voyage/GetByVessel?vesselId=" + vesselId;

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async saveVoyage(voyage: Voyage): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Voyage/Save";
      var body = {
        VoyageNumber: voyage.VoyageNumber,
        VesselId: voyage.VesselId,
        DistributorId: this.constants.DISTRIBUTOR_ID
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async editVoyage(voyage: Voyage): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Voyage/Save";
      var body = {
        Id: voyage.Id,
        VoyageNumber: voyage.VoyageNumber,
        VesselId: voyage.VesselId,
        DistributorId: this.constants.DISTRIBUTOR_ID
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async disableVoyage(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Voyage/Disable";
      var body = "=" + id;

      var headers = this.utilities.getFormHeaders();

      this.http.post(url, body, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }
}
