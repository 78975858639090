import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TableControl } from 'src/app/models/TableControl';
import { ProductConfiguration } from 'src/app/models/ProductConfiguration';
import { FilterModel } from 'src/app/models/FilterModel';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { ConstantsService } from '../../common/constants/constants.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductConfigurationService {
	public distributorId: number;

  constructor(private http: HttpClient,
    private utilities: UtilitiesService,
    private constants: ConstantsService) {
      // let currentUser = JSON.parse(localStorage.getItem('_currentUser'));
      // this.distributorId = currentUser.profile.sub;
      this.distributorId = this.constants.DISTRIBUTOR_ID;
    }

  async getProductConfigurations(tableControl: TableControl) {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/ProductConfigs/GetProductConfigsPaginatedByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableControls(url, tableControl);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getProductConfigurationCount(filter: FilterModel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/ProductConfigs/GetProductConfigsCountByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async saveProductConfiguration(prodConfig: ProductConfiguration): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/ProductConfigs/Save";
      var body = {
        ProductTypeId: prodConfig.ProductTypeId,
        ManufacturerId: prodConfig.ManufacturerId,
        MakeOrModelNameId: prodConfig.MakeOrModelNameId,
        ConfigId: prodConfig.ConfigurationId,
        CBM: prodConfig.CBM,
        Height: prodConfig.Height,
        Weight: prodConfig.Weight,
        Length: prodConfig.Length,
        Width: prodConfig.Width,
        Title: prodConfig.Title,
        MetaTitle: prodConfig.MetaTitle,
        MetaDescription: prodConfig.MetaDescription,
        MetaKeywords: prodConfig.MetaKeywords,
        MetaURL: prodConfig.MetaURL,
        FeaturedImage: prodConfig.FeaturedImage,
        DistributorId: this.distributorId,
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async editProductConfiguration(prodConfig: ProductConfiguration): Promise<any> {

    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + "api/ProductConfigs/Save";
      var body = {
        Id: prodConfig.Id,
        ProductTypeId: prodConfig.ProductTypeId,
        ProductTypeName: prodConfig.ProductTypeName,
        ManufacturerId: prodConfig.ManufacturerId,
        ManufacturerName: prodConfig.ManufacturerName,
        MakeOrModelNameId: prodConfig.MakeOrModelNameId,
        MakeOrModelName: prodConfig.MakeOrModelName,
        YearModel: prodConfig.YearModel,
        ConfigId: prodConfig.ConfigurationId,
        ConfigName: prodConfig.ConfigurationName,
        CBM: prodConfig.CBM,
        Height: prodConfig.Height,
        Weight: prodConfig.Weight,
        Length: prodConfig.Length,
        Width: prodConfig.Width,
        Title: prodConfig.Title,
        MetaTitle: prodConfig.MetaTitle,
        MetaDescription: prodConfig.MetaDescription,
        MetaKeywords: prodConfig.MetaKeywords,
        MetaURL: prodConfig.MetaURL,
        FeaturedImage: prodConfig.FeaturedImage,
        DistributorId: this.distributorId,
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async editSelectedProductConfiguration(prodConfigs: ProductConfiguration[]): Promise<any> {

    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + "api/ProductConfigs/SaveSelectedProducts";

      const httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      }
      
      prodConfigs.forEach(pc => {
        let prodList: ProductConfiguration[] = [];
        prodList.push(pc);
        var list = prodList.map(prodConfig => ({
          Id: prodConfig.Id,
          ProductTypeId: prodConfig.ProductTypeId,
          ProductTypeName: prodConfig.ProductTypeName,
          ManufacturerId: prodConfig.ManufacturerId,
          ManufacturerName: prodConfig.ManufacturerName,
          MakeOrModelNameId: prodConfig.MakeOrModelNameId,
          MakeOrModelName: prodConfig.MakeOrModelName,
          YearModel: prodConfig.YearModel,
          ConfigId: prodConfig.ConfigurationId,
          ConfigName: prodConfig.ConfigurationName,
          CBM: prodConfig.CBM,
          Height: prodConfig.Height,
          Weight: prodConfig.Weight,
          Length: prodConfig.Length,
          Width: prodConfig.Width,
          Title: prodConfig.Title,
          MetaTitle: prodConfig.MetaTitle,
          MetaDescription: prodConfig.MetaDescription,
          MetaKeywords: prodConfig.MetaKeywords,
          MetaURL: prodConfig.MetaURL,
          FeaturedImage: prodConfig.FeaturedImage,
          DistributorId: this.distributorId,
        }))
        var xb = JSON.stringify(list);
        this.http.post(url, xb, httpOptions).subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      })
    });
  }
  
  async deleteProductConfiguration(productConfigurationId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + `api/ProductConfigs/Delete/${productConfigurationId}`;
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, null, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }
  
  async getFeaturedImage(id: number, cancelled: Subject<void> = null): Promise<string> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/ProductConfigs/GetFeaturedImage/${id}?distributorId=${this.distributorId}`;

      this.http.get(url)
      .pipe(takeUntil(cancelled))
      .subscribe((data: string) => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async importProductConfigurations(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.constants.SERVICE_URL}api/ProductConfigs/ReadExcelFile`;
      const formData = new FormData();
      formData.append('excel', file, file.name);

      this.http.post(url, formData, {
        headers: this.utilities.getAuthorizationTokenHeader(),
        params: new HttpParams().set("distributorId", this.constants.DISTRIBUTOR_ID.toString())
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  
  async syncAll(): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/ProductConfigs/SyncAll/${this.distributorId}`;
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, null, headers).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getSyncStatus() : Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/ProductConfigs/GetSyncStatus/${this.distributorId}`;

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }
}
