import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DistributorService } from 'src/app/services/http/distributor/distributor.service';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { Distributor } from 'src/app/models/Distributor';
import { UtilitiesService } from 'src/app/services/common/utilities/utilities.service';
import { ConstantsService } from 'src/app/services/common/constants/constants.service';

@Component({
  selector: 'app-edit-distributor',
  templateUrl: './edit-distributor.component.html',
  styleUrls: ['./edit-distributor.component.scss']
})
export class EditDistributorComponent implements OnInit {
  @Output() distributorEdited = new EventEmitter();
  @Output() distributorAdded = new EventEmitter();
	@ViewChild('EditDistributor', {static: false}) editDistributor: NgxSmartModalComponent;
  currentUser: any;

  form: FormGroup;
  
  fullScreenSpinner = 'fullScreenSpinner';
  isSubmitted: any;
  modalTitle: string;

  constructor(private spinnerService: NgxSpinnerService,
    private distributorService: DistributorService,
    private utilities: UtilitiesService,
    private constants: ConstantsService) { 
      this.currentUser = JSON.parse(this.utilities.getStorage(this.constants.CURRENT_USER));
    }

  ngOnInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      distributorName: new FormControl(null, Validators.required),
      company: new FormControl(null, Validators.required),
      directorName: new FormControl(null, Validators.required),
      telephoneNo: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      website: new FormControl(null, Validators.required),
      bankAccountNumber: new FormControl(null, Validators.required),
      bankAccountName: new FormControl(null, Validators.required),
      payPalEmail: new FormControl(null, Validators.required),
      abn: new FormControl(null, Validators.required),
      ein: new FormControl(null, Validators.required),
      accountsContact: new FormControl(null, Validators.required),
      everydayContactPerson: new FormControl(null, Validators.required),
      hexColor: new FormControl(null, Validators.required),
      logo: new FormControl(null, Validators.required)
    });
  }

  get id() { return this.form.controls['id']; }
  get distributorName() { return this.form.controls['distributorName']; }
  get company() { return this.form.controls['company']; }
  get directorName() { return this.form.controls['directorName']; }
  get telephoneNo() { return this.form.controls['telephoneNo']; }
  get email() { return this.form.controls['email']; }
  get address() { return this.form.controls['address']; }
  get website() { return this.form.controls['website']; }
  get bankAccountNumber() { return this.form.controls['bankAccountNumber']; }
  get bankAccountName() { return this.form.controls['bankAccountName']; }
  get payPalEmail() { return this.form.controls['payPalEmail']; }
  get abn() { return this.form.controls['abn']; }
  get ein() { return this.form.controls['ein']; }
  get accountsContact() { return this.form.controls['accountsContact']; }
  get everydayContactPerson() { return this.form.controls['everydayContactPerson']; }
  get hexColor() { return this.form.controls['hexColor']; }
  get logo() { return this.form.controls['logo']; }

  async onDataAdded() {
    this.isSubmitted = false;
    let data = this.editDistributor.getData() as Distributor;

    if (!data) return;

    if (data.Id == null) {
      this.form.reset();
      this.refreshTitle();
      return;
    }

    this.form.setValue({
      id: data.Id,
      distributorName: data.DistributorName,
      company: data.Company,
      directorName: data.DirectorName,
      telephoneNo: data.TelephoneNo,
      email: data.Email,
      address: data.Address,
      website: data.Website,
      bankAccountNumber: data.BankAccountNumber,
      bankAccountName: data.BankAccountName,
      payPalEmail: data.PayPalEmail,
      abn: data.ABN,
      ein: data.EIN,
      accountsContact: data.AccountsContact,
      everydayContactPerson: data.EverydayContactPerson,
      hexColor: data.HexColor,
      logo: data.Logo
    });
  }

  async onSubmit() {
    this.isSubmitted = true;

    if (!this.form.valid) return;

    this.spinnerService.show(this.fullScreenSpinner);

    let isNew = !this.id.value;
    let isSuccessful = await this.save();

    this.spinnerService.hide(this.fullScreenSpinner);

    if (!isSuccessful) return;

    this.isSubmitted = true;
    this.form.reset();
    this.editDistributor.close();

    if (isNew)
      this.distributorAdded.emit();
    else
      this.distributorEdited.emit();
  }

  async save() {
    let distributor = new Distributor();
    distributor.Construct({
      Id: this.id.value, 
      DistributorName: this.distributorName.value,
      Company: this.company.value,
      DirectorName: this.directorName.value,
      TelephoneNo: this.telephoneNo.value,
      Email: this.email.value,
      Address: this.address.value,
      Website: this.website.value,
      BankAccountNumber: this.bankAccountNumber.value,
      BankAccountName: this.bankAccountName.value,
      PayPalEmail: this.payPalEmail.value,
      ABN: this.abn.value,
      EIN: this.ein.value,
      AccountsContact: this.accountsContact.value,
      EverydayContactPerson: this.everydayContactPerson.value,
      HexColor: this.hexColor.value,
      Logo: this.logo.value,
      IsRegistered: true,
      Active: true
    });
  
    let isSuccessful: boolean;

    if (distributor.Id == null)
      await this.distributorService.registerDistributor(this.currentUser.profile.sub, distributor)
      .then(_ => isSuccessful = true);
    else
      await this.distributorService.updateDistributor(distributor)
      .then(_ => isSuccessful = true);

    return isSuccessful;
  }

  refreshTitle(name: string = '') {
    this.modalTitle = name
      ? 'Editing ' + name
      : 'Adding Distributor';
  }
}
