import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FilterModel } from 'src/app/models/FilterModel';
import { FilterOption } from 'src/app/models/FilterOption';
import { TableControl } from 'src/app/models/TableControl';
import { CountryService } from 'src/app/services/http/country/country.service';
import { CurrencyService } from 'src/app/services/http/currency/currency.service';
import { RateTypeService } from 'src/app/services/http/rate-type/rate-type.service';
import { IncotermService } from 'src/app/services/http/incoterm/incoterm.service';
import { FreightRateService } from 'src/app/services/http/freight-rate/freight-rate.service';
import { EditFreightRateComponent } from 'src/app/components/pages/freight-rates/edit-freight-rate/edit-freight-rate.component';
import { FreightRate, Denomination, Charge, CarOceanFreight } from 'src/app/models/FreightRate';
import { ConstantsService } from 'src/app/services/common/constants/constants.service';

@Component({
	selector: 'app-view-freight-rates',
	templateUrl: './view-freight-rates.component.html',
	styleUrls: ['./view-freight-rates.component.scss'],
})
export class ViewFreightRatesComponent implements OnInit, AfterViewInit {

	@ViewChild(EditFreightRateComponent, { static: false }) editFreightRateComponent: EditFreightRateComponent;

	// Modal Identifier Constants
	private addFreightRateModal: string		= 'addFreightRate';
	private editFreightRateModal: string 	= 'editFreightRate';
	private deleteFreightRateModal: string  = 'deleteFreightRate';

	// Table Variables
	public tableControl: TableControl;
	public filterOptions: FilterOption[];

	// Local Variables
	public freightRates: any;
	public countries: any;
	public currencies: any;
	public rateTypes: any;
	public incotermsFrom: any;
	public incotermsTo: any;

	constructor(
		private freightRateService: FreightRateService,
		private countryService: CountryService,
		private currencyService: CurrencyService,
		private rateTypeService: RateTypeService,
		private incotermService: IncotermService,
		private spinner: NgxSpinnerService,
		private toasterService: ToastrService,
		public ngxSmartModalService: NgxSmartModalService,
		public constantsService: ConstantsService
	) {
		this.filterOptions = [
			new FilterOption('Id', 'Id'),
			new FilterOption('Origin', 'Origin Country'),
			new FilterOption('OriginRegion', 'Origin Port'),
			new FilterOption('Destination', 'Destination Country'),
			new FilterOption('DestinationRegion', 'Destination Port'),
			new FilterOption('TypeName', 'Rate Type'),
			new FilterOption('Rates', 'Rates'),
			new FilterOption('IncotermFromName', 'Incoterm From'),
			new FilterOption('IncotermToName', 'Incoterm To'),
			new FilterOption('Created', 'Modified On'),
			new FilterOption('CreatedByName', 'Modified By')
		];
		this.tableControl = new TableControl(10);
	}

	async ngOnInit() {
		this.spinner.show('fullScreenSpinner');
		this.loadCountries();
		this.loadCurrencies();
		this.loadRateTypes();
		this.loadRateTypes();
		this.loadIncoterms();
		await this.getItemCount();
		this.spinner.hide('fullScreenSpinner');
		await this.loadFreightRates();
	}

	ngAfterViewInit() {

	}

	private async loadFreightRates() {
		this.spinner.show('tableSpinner');
		this.freightRates = await this.freightRateService.getFreightRates(this.tableControl);
		this.tableControl.CurrentItemCount = this.freightRates.length;
		this.spinner.hide('tableSpinner');
	}

	private async getItemCount() {
		this.tableControl.TotalItemCount = await this.freightRateService.getFreightRateCount(this.tableControl.Filter);
	}

	private async loadCountries() {
		this.countries = await this.countryService.getCountries();
	}

	private async loadCurrencies() {
		this.currencies = await this.currencyService.getCurrencies();
		// this.addFreightRateComponent.freightRate.OceanFreight.CurrencyId =
		// 	this.currencyService.getCurrencyIdByISO(this.constantsService.CURRENCY_ISO_USD, this.currencies);
	}

	private async loadRateTypes() {
		this.rateTypes = await this.rateTypeService.getRateTypes();
	}

	private async loadIncoterms() {
		this.incotermsFrom =  await this.incotermService.getIncotermsFrom();
		this.incotermsTo =  await this.incotermService.getIncotermsTo();
	}

	addFreightRate() {
		this.ngxSmartModalService.resetModalData(this.editFreightRateModal);
		
		let freightRate = new FreightRate();

		freightRate.Clear();

		let currencyId = this.currencyService.getCurrencyIdByISO(this.constantsService.CURRENCY_ISO_USD, this.currencies);

		freightRate.OceanFreight = new Denomination(currencyId, null);

		freightRate.SortAddCharges([]);
		this.setEditModalDefaultImportExportCurrencyIds(freightRate);

		this.ngxSmartModalService.setModalData(freightRate, this.editFreightRateModal);

		this.ngxSmartModalService.open(this.editFreightRateModal);
	}

	editFreightRate(freightRate: any) {
		this.loadEditModalRegions(freightRate);

		let editFreightRate = new FreightRate();
		editFreightRate.Construct({
			Id: freightRate.Id,
			CarrierId: freightRate.CarrierId,
			OriginCountryId: freightRate.OriginCountryId,
			OriginId: freightRate.OriginId,
			DestinationCountryId: freightRate.DestinationCountryId,
			DestinationId: freightRate.DestinationId,
			RateTypeId: freightRate.TypeId,
			OceanFreight: new Denomination(freightRate.CurrencyId, freightRate.Rates),
			ExportCharges: Charge.Default(),
			ImportCharges: Charge.Default(),
			IncotermFromId: freightRate.IncotermFromId,
			IncotermToId: freightRate.IncotermToId,
			IsAllCategories: !!freightRate.AllCategories,
			CarOceanFreights: CarOceanFreight.Map(freightRate.FreightEngineRateForCar),
		});

		if (!editFreightRate.OceanFreight.CurrencyId)
			editFreightRate.OceanFreight.CurrencyId = this.currencyService.getCurrencyIdByISO(this.constantsService.CURRENCY_ISO_USD, this.currencies);

		editFreightRate.SortAddCharges(freightRate.FreightEngineCustomDistributorCharges);

		this.setEditModalDefaultImportExportCurrencyIds(editFreightRate);
		
		this.ngxSmartModalService.resetModalData(this.editFreightRateModal);
		this.ngxSmartModalService.setModalData(editFreightRate, this.editFreightRateModal);

		this.ngxSmartModalService.open(this.editFreightRateModal);
	}

	loadEditModalRegions(freightRate: any) {
		this.editFreightRateComponent.loadOriginRegions(freightRate.OriginCountryId);
		this.editFreightRateComponent.loadDestinationRegions(freightRate.DestinationCountryId);
	}

	setEditModalDefaultImportExportCurrencyIds(editFreightRate: FreightRate) {
		this.editFreightRateComponent.defaultExportCurrencyId = this.getLastChargeCurrencyId(editFreightRate.ExportCharges);
		this.editFreightRateComponent.defaultImportCurrencyId = this.getLastChargeCurrencyId(editFreightRate.ImportCharges);
	}

	deleteFreightRate(freightRate: any) {
		this.ngxSmartModalService.resetModalData(this.deleteFreightRateModal);
		this.ngxSmartModalService.setModalData(freightRate, this.deleteFreightRateModal);
		this.ngxSmartModalService.open(this.deleteFreightRateModal);
	}

	// EMMITED EVENT ACTIONS

	onFreightRateAdded() {
		this.tableControl.Page = 1;
		this.getItemCount();
		this.loadFreightRates();
		this.toasterService.success('Freight Rate successfully Added.', 'Add Freight Rate');
	}

	onFreightRateEdited() {
		this.loadFreightRates();
		this.toasterService.success('Freight Rate successfully Edited.', 'Edit Freight Rate');
	}

	onFreightRateDeleted() {
		this.getItemCount();
		this.loadFreightRates();
		this.toasterService.success('Freight Rate successfully Deleted.', 'Deleted Freight Rate');
	}

	onPageChanged({ page, maxPageSize}) {
		this.tableControl.Page = page;
		this.tableControl.MaxPageSize = maxPageSize;
		
		this.loadFreightRates();
	}

	onFiltered(filter: FilterModel) {
		this.tableControl.Page = 1;
		this.tableControl.Filter = filter;
		this.loadFreightRates();
		this.getItemCount();
	}

	onSorted(sort: string) {
		this.tableControl.Sorting = sort;
		this.tableControl.Page = 1;
		this.loadFreightRates();
	}
	
	getLastChargeCurrencyId(charge: Charge[]) {
		if (charge && charge.length)
			return charge[charge.length-1].Denomination.CurrencyId;
	}
}
