import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ConfigurationService } from 'src/app/services/http/configuration/configuration.service';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Configuration } from 'src/app/models/Configuration';

@Component({
  selector: 'app-edit-configuration',
  templateUrl: './edit-configuration.component.html',
  styleUrls: ['./edit-configuration.component.scss']
})
export class EditConfigurationComponent implements OnInit {
  @Output() configurationAdded = new EventEmitter();
  @Output() configurationEdited = new EventEmitter();
	@ViewChild('EditConfiguration', {static: false}) editConfiguration: NgxSmartModalComponent;

  modalTitle: string;

  configuration: Configuration = new Configuration();
  form: FormGroup;
  isSubmitted: boolean;

  charLimit = 500;
  fullScreenSpinner = 'fullScreenSpinner';

  constructor(private spinnerService: NgxSpinnerService,
    private configurationService: ConfigurationService) { }

  ngOnInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, [Validators.required, Validators.maxLength(this.charLimit)])
    });
  }

  get id() { return this.form.get('id') };
  get name() { return this.form.get('name') };

  async onSubmit() {
    this.isSubmitted = true;

    if (!this.form.valid) return;

    this.spinnerService.show(this.fullScreenSpinner);

    let isNew = !this.id.value;
    let isSuccessful = await this.save();

    this.spinnerService.hide(this.fullScreenSpinner);

    if (!isSuccessful) return;

    this.isSubmitted = false;
    this.form.reset();
    this.editConfiguration.close();

    if (isNew)
      this.configurationAdded.emit();
    else
      this.configurationEdited.emit();
  }

  async save() {
    let configuration = new Configuration();

    configuration.Construct(this.id.value, this.name.value);

    let isSuccessful: boolean;

    await this.configurationService.editConfiguration(configuration)
      .then(_ => isSuccessful = true)
      .catch(err => {
        if (err.status === 409) {
          this.name.setErrors({ duplicate: true });
        }
      });

    return isSuccessful;
  }

  onDataAdded() {
    this.isSubmitted = false;

    let data = this.editConfiguration.getData() as Configuration;

    if (!data) return;

    if (data.Id == null) {
      this.form.reset();
      this.refreshTitle();
      return;
    }

    this.form.setValue({
      id: data.Id,
      name: data.Name
    });

    this.refreshTitle(data.Name);
  }

  refreshTitle(name: string = '') {
    this.modalTitle = name
      ? 'Editing: ' + name
      : 'Adding Configuration';
  }
}
