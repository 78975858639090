import { Component, OnInit } from '@angular/core';
import { Distributor } from 'src/app/models/Distributor';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { DistributorService } from 'src/app/services/http/distributor/distributor.service';
import { ToastrService } from 'ngx-toastr';
import { UtilitiesService } from 'src/app/services/common/utilities/utilities.service';
import { ConstantsService } from 'src/app/services/common/constants/constants.service';
import { TableControl } from 'src/app/models/TableControl';

@Component({
  selector: 'app-edit-account-distributor',
  templateUrl: './edit-account-distributor.component.html',
  styleUrls: ['./edit-account-distributor.component.scss']
})
export class EditAccountDistributorComponent implements OnInit {
  emailPattern = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"; 
  currentUser: any;
  distributor: Distributor = new Distributor();
  distributors: any;
  selectedDistributor: any;
  selectedDistributorId: any = null;
  //Table Variables
  tableControl: TableControl;

  constructor(private spinner: NgxSpinnerService,
    private toasterService: ToastrService,
    private distributorService: DistributorService,
    private utilities: UtilitiesService,
    private constants: ConstantsService) { 
      this.currentUser = JSON.parse(this.utilities.getStorage(this.constants.CURRENT_USER));
    }

  async ngOnInit() {
    this.spinner.show("fullScreenSpinner");
    this.distributors = await this.getDistributors();

    if (this.currentUser.profile.role != "System Administrator"){
      this.selectedDistributorId = this.distributors.Id;
      await this.loadDistributor();
    }

    this.spinner.hide("fullScreenSpinner");
  }

  async getDistributors(){
    if (this.currentUser.profile.role != "System Administrator"){
      return this.distributorService.getDistributorByUserId(this.currentUser.profile.sub);
    } else{
      return this.distributorService.getDistributors(this.tableControl);
    }
  }

  async selectDistributor(){
    if (this.selectedDistributorId){
      this.spinner.show("editSectionSpinner");
      await this.loadDistributor();
      this.spinner.hide("editSectionSpinner");
    } else{
      this.toasterService.warning("Select Distributor to Edit.", "Edit Distributor");
    }
  }

  async loadDistributor(){
    var result: any = await this.distributorService.getDistributorById(this.selectedDistributorId);
    this.distributor.Construct(result);
    this.selectedDistributor = result;
  }
  
  async editDistributor(distributor: NgForm) {
    if (distributor.valid) {
      this.spinner.show("fullScreenSpinner");
      var result;

      if (this.distributor.Id == null)
        result = await this.distributorService.registerDistributor(this.currentUser.profile.sub, this.distributor);
      else
        result = await this.distributorService.updateDistributor(this.distributor);

      this.spinner.hide("fullScreenSpinner");

      if (result == "OK") {
        this.toasterService.success("Distributor successfully Edited.", "Edit Distributor");
      } else if (result == "Email address already exist!"){
        this.toasterService.error("Email Address Already Exists!", "Failed to Edit Distributor");
      }
    }
  }
}
