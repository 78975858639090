import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ProductConfigurationService } from "src/app/services/http/product-configuration/product-configuration.service";
import { NgxSmartModalComponent } from "ngx-smart-modal";
import { ProductConfiguration } from "src/app/models/ProductConfiguration";
import { DomSanitizer } from "@angular/platform-browser";
import { MakeModelService } from "src/app/services/http/make-model/make-model.service";
import { ConfigurationService } from "src/app/services/http/configuration/configuration.service";
import { FilterModel } from "src/app/models/FilterModel";
import { Subject } from "rxjs";
import { ProductTypeService } from "src/app/services/http/product-type/product-type.service";
import { ManufacturerService } from "src/app/services/http/manufacturer/manufacturer.service";
import { NgSelectComponent } from "@ng-select/ng-select";

@Component({
  selector: "app-edit-product-configuration",
  templateUrl: "./edit-product-configuration.component.html",
  styleUrls: ["./edit-product-configuration.component.scss"],
})
export class EditProductConfigurationComponent implements OnInit, OnDestroy {
  @Output() productConfigurationAdded = new EventEmitter();
  @Output() productConfigurationEdited = new EventEmitter();
  @ViewChild("EditProductConfiguration", { static: false })
  editProductConfiguration: NgxSmartModalComponent;
  @ViewChild("model", { static: false })
  modelSelectComponent: NgSelectComponent;

  form: FormGroup;
  isSubmitted: boolean;

  productConfiguration: ProductConfiguration = new ProductConfiguration();

  bufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;

  productTypeBuffer = {};
  productTypeBufferArr: { Id: number; Name: string }[] = [];
  productTypeLength = 0;
  productTypePageIndex = 0;

  productTypesLoading = false;
  productTypesInputLength = 0;
  productTypesInput = "";
  productTypeSearchTimer: any;

  manufacturerBuffer = {};
  manufacturerBufferArr: { Id: number; Name: string }[] = [];
  manufacturerLength = 0;
  manufacturerPageIndex = 0;

  manufacturersLoading = false;
  manufacturersInputLength = 0;
  manufacturersInput = "";
  manufacturerSearchTimer: any;

  makeModelBuffer = {};
  makeModelBufferArr: {
    Id: number;
    Name: string;
    NameId: number;
    ProductTypeId: number;
    ManufacturerId: number;
    ManufacturerName: string;
    YearModel: number;
  }[] = [];
  makeModelLength = 0;
  makeModelPageIndex = 0;

  makeModelsLoading = false;
  makeModelsInputLength = 0;
  makeModelsInput = "";
  makeModelSearchTimer: any;

  configurationBuffer = {};
  configurationBufferArr: { Id: number; Name: string }[] = [];
  configurationLength = 0;
  configurationPageIndex = 0;

  configurationsLoading = false;
  configurationsInputLength = 0;
  configurationsInput = "";
  configurationSearchTimer: any;

  featuredImageLoading = false;

  // charLimit = 1000000;

  submitDisabled = false;

  fullscreenSpinner = "fullScreenSpinner";
  isNewProductType = false;
  isNewManufacturer = false;
  isForceNewModel = false;
  isNewModel = false;
  isNewConfiguration = false;

  cancelLoadFeaturedImage = new Subject<void>();
  that = this;

  modelProductTypeId: number;
  modelManufacturerId: number;
  modelManufacturerName: string;
  modelYear: number;
  selectedModelName: string;
  newModelYear: number;

  //defaultMetaKeyword: string;

  constructor(
    private spinnerService: NgxSpinnerService,
    private productTypeService: ProductTypeService,
    private manufacturerService: ManufacturerService,
    private makeModelService: MakeModelService,
    private configurationService: ConfigurationService,
    private productConfigurationService: ProductConfigurationService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.modelSearch = this.modelSearch.bind(this);

    //this.defaultMetaKeyword = 'Vehicle dimensions instant international shipping quotes';

    this.form = new FormGroup({
      id: new FormControl(null),
      modelId: new FormControl(null, Validators.required),
      modelNameId: new FormControl(null),
      modelName: new FormControl(null),
      yearModel: new FormControl(null),
      productTypeId: new FormControl(null),
      productTypeName: new FormControl(null),
      manufacturerId: new FormControl(null),
      manufacturerName: new FormControl(null),
      configurationId: new FormControl(null, Validators.required),
      configurationName: new FormControl(null),
      length: new FormControl(null, Validators.required),
      width: new FormControl(null, Validators.required),
      height: new FormControl(null, Validators.required),
      weight: new FormControl(null, Validators.required),
      cbm: new FormControl(null),
      title: new FormControl(null, Validators.required),
      metaTitle: new FormControl(null, Validators.required),
      // metaDescription: new FormControl(null, [Validators.required, Validators.maxLength(this.charLimit)]),
      metaDescription: new FormControl(null, [Validators.required]),
      metaKeywords: new FormControl(null, Validators.required),
      metaUrl: new FormControl(null, Validators.required),
      featuredImage: new FormControl(null),
      featuredImagePath: new FormControl(null),
    });

    this.productTypeLength = await this.productTypeService.getProductTypeCount(
      this.productTypeWordFilter(this.productTypesInput)
    );
    this.manufacturerLength =
      await this.manufacturerService.getManufacturerCount(
        this.manufacturerWordFilter(this.manufacturersInput)
      );
    this.makeModelLength = await this.makeModelService.getMakeModelCount(
      this.makeModelNameFilter(this.makeModelsInput)
    );
    this.configurationLength =
      await this.configurationService.getConfigurationCount(
        this.configurationNameFilter(this.configurationsInput)
      );
  }

  ngOnDestroy() {
    this.cancelFeaturedImageLoad();
  }

  get id() {
    return this.form.get("id");
  }
  get modelId() {
    return this.form.get("modelId");
  }
  get modelNameId() {
    return this.form.get("modelNameId");
  }
  get modelName() {
    return this.form.get("modelName");
  }
  get yearModel() {
    return this.form.get("yearModel");
  }
  get productTypeId() {
    return this.form.get("productTypeId");
  }
  get productTypeName() {
    return this.form.get("productTypeName");
  }
  get manufacturerId() {
    return this.form.get("manufacturerId");
  }
  get manufacturerName() {
    return this.form.get("manufacturerName");
  }
  get configurationId() {
    return this.form.get("configurationId");
  }
  get configurationName() {
    return this.form.get("configurationName");
  }
  get length() {
    return this.form.get("length");
  }
  get width() {
    return this.form.get("width");
  }
  get height() {
    return this.form.get("height");
  }
  get weight() {
    return this.form.get("weight");
  }
  get cbm() {
    return this.form.get("cbm");
  }
  get title() {
    return this.form.get("title");
  }
  get metaTitle() {
    return this.form.get("metaTitle");
  }
  get metaDescription() {
    return this.form.get("metaDescription");
  }
  get metaKeywords() {
    return this.form.get("metaKeywords");
  }
  get metaUrl() {
    return this.form.get("metaUrl");
  }
  get featuredImage() {
    return this.form.get("featuredImage");
  }
  get featuredImagePath() {
    return this.form.get("featuredImagePath");
  }

  async onDataAdded() {
    this.isSubmitted = false;

    let data = this.editProductConfiguration.getData() as ProductConfiguration;

    if (!data) return;

    this.resetModelDropdownSelection();
    this.form.reset();

    this.isNewProductType = false;
    this.isNewManufacturer = false;
    this.isNewModel = false;
    this.isNewConfiguration = false;
    this.isForceNewModel = false;

    this.toggleProductTypeRequiredValidator();
    this.toggleManufacturerRequiredValidator();
    this.toggleModelRequiredValidator();
    this.toggleConfigurationRequiredValidator();

    if (data.Id == null) {
      //this.metaKeywords.setValue(this.defaultMetaKeyword);
      return;
    }

    this.form.setValue({
      id: data.Id,
      modelId: data.MakeOrModelId || null,
      modelNameId: data.MakeOrModelNameId || null,
      modelName: "",
      yearModel: data.YearModel || null,
      productTypeId: data.ProductTypeId || null,
      productTypeName: "",
      manufacturerId: data.ManufacturerId || null,
      manufacturerName: "",
      configurationId: data.ConfigurationId || null,
      configurationName: "",
      length: data.Length,
      width: data.Width,
      height: data.Height,
      weight: data.Weight,
      cbm: data.CBM,
      title: data.Title,
      metaTitle: data.MetaTitle,
      metaDescription: data.MetaDescription,
      metaKeywords: data.MetaKeywords,
      metaUrl: data.MetaURL,
      featuredImage: "",
      featuredImagePath: "",
    });

    this.submitDisabled = true;

    if (data.FeaturedImage != null)
      this.featuredImage.setValue(data.FeaturedImage);
    else this.loadFeaturedImage();

    this.selectProductTypeDropdownItem(data.ProductTypeName);
    this.selectManufacturerDropdownItem(data.ManufacturerName);
    this.selectModelDropdownItem(data.MakeOrModelName);
    this.selectConfigurationDropdownItem(data.ConfigurationName);
  }

  private resetModelDropdownSelection() {
    this.modelProductTypeId = null;
    this.modelManufacturerId = null;
    this.modelYear = null;
    this.selectedModelName = null;
  }

  private productTypeWordFilter(term: string) {
    return new FilterModel("Word", term);
  }

  private manufacturerWordFilter(term: string) {
    return new FilterModel("Word", term);
  }

  private makeModelNameFilter(term: string) {
    return new FilterModel("MakeOrModelName", term);
  }

  private configurationNameFilter(term: string) {
    return new FilterModel("Name", term);
  }

  async onSubmit() {
    this.isSubmitted = true;

    if (!this.form.valid) return;

    this.submitDisabled = true;
    this.spinnerService.show(this.fullscreenSpinner);

    let isNew = !this.id.value;
    let isSuccessful = await this.save();

    this.spinnerService.hide(this.fullscreenSpinner);
    this.submitDisabled = false;

    if (!isSuccessful) return;

    this.isSubmitted = false;
    this.resetModelDropdownSelection();
    this.form.reset();
    this.editProductConfiguration.close();

    if (isNew) this.productConfigurationAdded.emit();
    else this.productConfigurationEdited.emit();
  }

  async save() {
    let productConfig = new ProductConfiguration();

    productConfig.Construct({
      Id: this.id.value,
      CBM: this.cbm.value,
      Height: this.height.value,
      Weight: this.weight.value,
      Length: this.length.value,
      Width: this.width.value,
      Title: this.title.value,
      MetaTitle: this.metaTitle.value,
      MetaDescription: this.metaDescription.value,
      MetaKeywords: this.metaKeywords.value,
      MetaURL: this.metaUrl.value,
      FeaturedImage: this.featuredImage.value || "",
    });

    if (this.isNewProductType) {
      productConfig.ProductTypeName = this.productTypeName.value;
    } else {
      if (this.isNewModel || this.isForceNewModel) {
        productConfig.ProductTypeId = this.productTypeId.value;
      } else {
        productConfig.ProductTypeId =
          this.modelProductTypeId || this.productTypeId.value;
      }
    }

    if (this.isNewManufacturer) {
      productConfig.ManufacturerName = this.manufacturerName.value;
    } else {
      if (this.isNewModel || this.isForceNewModel) {
        productConfig.ManufacturerId = this.manufacturerId.value;
      } else {
        productConfig.ManufacturerId =
          this.modelManufacturerId || this.manufacturerId.value;
      }
    }

    if (this.isNewModel || this.isForceNewModel) {
      productConfig.MakeOrModelName = this.modelName.value;
      productConfig.YearModel = this.yearModel.value;
    } else {
      productConfig.MakeOrModelId = this.modelId.value;
      productConfig.MakeOrModelNameId = this.modelNameId.value;
    }

    if (this.isNewConfiguration)
      productConfig.ConfigurationName = this.configurationName.value;
    else productConfig.ConfigurationId = this.configurationId.value;

    let isSuccessful: boolean;

    await this.productConfigurationService
      .editProductConfiguration(productConfig)
      .then((_) => (isSuccessful = true));

    return isSuccessful;
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => this.featuredImage.setValue(reader.result);
      return;
    }

    this.removeImage();
  }

  async getFeaturedImage(id: number) {
    return await this.productConfigurationService.getFeaturedImage(
      id,
      this.cancelLoadFeaturedImage
    );
  }

  cancelFeaturedImageLoad() {
    this.cancelLoadFeaturedImage.next();
    this.cancelLoadFeaturedImage.complete();
  }

  removeImage() {
    this.featuredImage.setValue("");
    this.featuredImagePath.setValue("");
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  async onMakeModelScrollToEnd() {
    if (
      this.makeModelsLoading ||
      this.makeModelLength <= this.makeModelsInputLength
    )
      return;

    await this.fetchMoreMakeModels();
  }

  onMakeModelChange(model: any) {
    this.modelNameId.setValue(model ? model.NameId : null);
    this.yearModel.setValue(model ? model.YearModel : null);
    this.modelProductTypeId = model ? model.ProductTypeId : null;
    this.modelManufacturerId = model ? model.ManufacturerId : null;
    this.modelManufacturerName = model ? model.ManufacturerName : null;
    this.modelYear = model ? model.YearModel : null;
    this.selectedModelName = model ? model.Name : null;

    this.autoFillMetaTitle();
    this.autoFillMetaKeywords();
    this.autoFillMetaDesc();
  }

  async onProductTypeChange() {
    await this.onMakeModelClear();
  }

  async onManufacturerChange() {
    await this.onMakeModelClear();

    if (this.isNewModel || this.isForceNewModel) {
      this.autoFillMetaTitle();
      this.autoFillMetaKeywords();
      this.autoFillMetaDesc();
    }
  }

  async onMakeModelClear() {
    this.makeModelsInput = "";

    this.makeModelsLoading = true;

    this.makeModelService
      .getMakeModelCountWithProductTypeAndManufacturer(
        this.makeModelNameFilter(this.makeModelsInput),
        this.productTypeId.value,
        this.manufacturerId.value
      )
      .then((data) => {
        this.makeModelLength = data;

        this.makeModelBufferArr = [];
        this.makeModelPageIndex = 0;

        if (!this.makeModelLength) {
          this.makeModelsInputLength = 0;
          this.makeModelsLoading = false;

          return;
        }

        this.fetchMoreMakeModels();
      });
  }

  async onProductTypeScrollToEnd() {
    if (
      this.productTypesLoading ||
      this.productTypeLength <= this.productTypesInputLength
    )
      return;

    await this.fetchMoreProductTypes();
  }

  async onManufacturerScrollToEnd() {
    if (
      this.manufacturersLoading ||
      this.manufacturerLength <= this.manufacturersInputLength
    )
      return;

    await this.fetchMoreManufacturers();
  }

  onProductTypeClear() {
    this.productTypesInput = "";

    this.productTypesLoading = true;

    this.productTypeService
      .getProductTypeCount(this.productTypeWordFilter(this.productTypesInput))
      .then((data) => {
        this.productTypeLength = data;

        if (!this.productTypeLength) {
          this.productTypesInputLength = 0;
          this.productTypesLoading = false;

          return;
        }

        this.productTypeBufferArr = [];
        this.productTypePageIndex = 0;

        this.fetchMoreProductTypes();
      });
  }

  onManufacturerClear() {
    this.manufacturersInput = "";

    this.manufacturersLoading = true;

    this.manufacturerService
      .getManufacturerCount(this.productTypeWordFilter(this.manufacturersInput))
      .then((data) => {
        this.manufacturerLength = data;

        if (!this.manufacturerLength) {
          this.manufacturersInputLength = 0;
          this.manufacturersLoading = false;

          return;
        }

        this.manufacturerBufferArr = [];
        this.manufacturerPageIndex = 0;

        this.fetchMoreManufacturers();
      });
  }

  onProductTypeSearch(obj: { term: string; items: any[] }) {
    if (this.productTypeSearchTimer) clearTimeout(this.productTypeSearchTimer);

    this.productTypeSearchTimer = setTimeout(() => {
      let term = obj.term;
      let items = obj.items;

      this.productTypesInput = term.trim();

      this.productTypesLoading = true;

      this.productTypeService
        .getProductTypeCount(this.productTypeWordFilter(this.productTypesInput))
        .then((data) => {
          this.productTypeLength = data;

          if (
            !this.productTypeLength ||
            this.productTypeLength == items.length
          ) {
            this.productTypesInputLength = items.length;
            this.productTypesLoading = false;

            return;
          }

          this.productTypeBufferArr = [];
          this.productTypePageIndex = 0;

          this.fetchMoreProductTypes();
        });
    }, 1000);
  }

  onManufacturerSearch(obj: { term: string; items: any[] }) {
    if (this.manufacturerSearchTimer)
      clearTimeout(this.manufacturerSearchTimer);

    this.manufacturerSearchTimer = setTimeout(() => {
      let term = obj.term;
      let items = obj.items;

      this.manufacturersInput = term.trim();

      this.manufacturersLoading = true;

      this.manufacturerService
        .getManufacturerCount(
          this.productTypeWordFilter(this.manufacturersInput)
        )
        .then((data) => {
          this.manufacturerLength = data;

          if (
            !this.manufacturerLength ||
            this.manufacturerLength == items.length
          ) {
            this.manufacturersInputLength = items.length;
            this.manufacturersLoading = false;

            return;
          }

          this.manufacturerBufferArr = [];
          this.manufacturerPageIndex = 0;

          this.fetchMoreManufacturers();
        });
    }, 1000);
  }

  onMakeModelSearch(obj: { term: string; items: any[] }) {
    if (this.makeModelSearchTimer) clearTimeout(this.makeModelSearchTimer);

    this.makeModelSearchTimer = setTimeout(() => {
      let term = obj.term;
      let items = obj.items;

      this.makeModelsInput = term.trim();

      this.makeModelsLoading = true;

      this.makeModelService
        .getMakeModelCountWithProductTypeAndManufacturer(
          this.makeModelNameFilter(this.makeModelsInput),
          this.productTypeId.value,
          this.manufacturerId.value
        )
        .then((data) => {
          this.makeModelLength = data;

          if (!this.makeModelLength || this.makeModelLength == items.length) {
            this.makeModelsInputLength = items.length;
            this.makeModelsLoading = false;

            return;
          }

          this.makeModelBufferArr = [];
          this.makeModelPageIndex = 0;

          this.fetchMoreMakeModels();
        });
    }, 1000);
  }

  async onMakeModelScroll({ end }) {
    if (
      this.makeModelsLoading ||
      this.makeModelLength <= this.makeModelsInputLength
    )
      return;

    if (
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.makeModelsInputLength
    )
      await this.fetchMoreMakeModels();
  }

  private async fetchMoreMakeModels() {
    this.makeModelsLoading = true;

    let newMakeModels = await this.makeModelService.getMoreMakemodels(
      this.makeModelsInput,
      this.bufferSize,
      ++this.makeModelPageIndex,
      this.productTypeId.value,
      this.manufacturerId.value
    );

    this.makeModelBuffer = { ...this.makeModelBuffer, ...newMakeModels };

    this.makeModelBufferArr = Object.keys(this.makeModelBuffer)
      .filter((key) => this.filterByProductTypeAndManufacturer(key, this))
      .map((key) => {
        return {
          Id: Number(key),
          Name: this.makeModelBuffer[key].Name,
          NameId: this.makeModelBuffer[key].NameId,
          ProductTypeId: this.makeModelBuffer[key].ProductTypeId,
          ProductTypeName: this.makeModelBuffer[key].ProductTypeName,
          ManufacturerId: this.makeModelBuffer[key].ManufacturerId,
          ManufacturerName: this.makeModelBuffer[key].ManufacturerName,
          YearModel: this.makeModelBuffer[key].YearModel,
        };
      })
      .sort(this.sortNameProperty);

    let bufferLength = Object.keys(this.makeModelBuffer).length;

    this.makeModelsInputLength =
      bufferLength > this.makeModelLength ? this.makeModelLength : bufferLength;

    this.makeModelsLoading = false;
  }

  sortNameProperty(a: any, b: any) {
    return (a.Name || "").toLowerCase() > (b.Name || "").toLowerCase()
      ? 1
      : (b.Name || "").toLowerCase() > (a.Name || "").toLowerCase()
      ? -1
      : 0;
  }

  filterByProductTypeAndManufacturer(key: string, self: any) {
    return (
      (!self.productTypeId.value ||
        self.makeModelBuffer[key].ProductTypeId == self.productTypeId.value) &&
      (!self.manufacturerId.value ||
        self.makeModelBuffer[key].ManufacturerId == self.manufacturerId.value)
    );
  }

  async onConfigurationScrollToEnd() {
    if (
      this.configurationsLoading ||
      this.configurationLength <= this.configurationsInputLength
    )
      return;

    await this.fetchMoreConfigurations();
  }

  async onConfigurationChange() {
    this.autoFillMetaDesc();
  }

  onConfigurationClear() {
    this.configurationsInput = "";

    this.configurationsLoading = true;

    this.configurationService
      .getConfigurationCount(
        this.configurationNameFilter(this.configurationsInput)
      )
      .then((data) => {
        this.configurationLength = data;

        if (!this.configurationLength) {
          this.configurationsInputLength = 0;
          this.configurationsLoading = false;

          return;
        }

        this.configurationBufferArr = [];
        this.configurationPageIndex = 0;

        this.fetchMoreConfigurations();
      });
  }

  onConfigurationSearch(obj: { term: string; items: any[] }) {
    if (this.configurationSearchTimer)
      clearTimeout(this.configurationSearchTimer);

    this.configurationSearchTimer = setTimeout(() => {
      let term = obj.term;
      let items = obj.items;

      this.configurationsInput = term.trim();

      this.configurationsLoading = true;

      this.configurationService
        .getConfigurationCount(
          this.configurationNameFilter(this.configurationsInput)
        )
        .then((data) => {
          this.configurationLength = data;

          if (
            !this.configurationLength ||
            this.configurationLength == items.length
          ) {
            this.configurationsInputLength = items.length;
            this.configurationsLoading = false;

            return;
          }

          this.configurationBufferArr = [];
          this.configurationPageIndex = 0;

          this.fetchMoreConfigurations();
        });
    }, 1000);
  }

  async onConfigurationScroll({ end }) {
    if (
      this.configurationsLoading ||
      this.configurationLength <= this.configurationsInputLength
    )
      return;

    if (
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.configurationsInputLength
    )
      await this.fetchMoreConfigurations();
  }

  private async fetchMoreConfigurations() {
    this.configurationsLoading = true;

    let newConfigurations =
      await this.configurationService.getMoreConfigurations(
        this.configurationsInput,
        this.bufferSize,
        ++this.configurationPageIndex
      );

    this.configurationBuffer = {
      ...this.configurationBuffer,
      ...newConfigurations,
    };

    this.configurationBufferArr = Object.keys(this.configurationBuffer)
      .map((key) => {
        return { Id: Number(key), Name: this.configurationBuffer[key] };
      })
      .sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

    let bufferLength = Object.keys(this.configurationBuffer).length;

    this.configurationsInputLength =
      bufferLength > this.configurationLength
        ? this.configurationLength
        : bufferLength;

    this.configurationsLoading = false;
  }

  async loadFeaturedImage() {
    this.featuredImageLoading = true;

    if (this.id.value) {
      let featuredImage = (await this.getFeaturedImage(this.id.value)) || "";
      this.featuredImage.setValue(featuredImage);
    }

    this.featuredImageLoading = false;

    this.checkEnableSubmit();
  }

  async selectProductTypeDropdownItem(name: string) {
    this.productTypesLoading = true;
    this.productTypesInput = name;

    let filter = this.productTypeWordFilter(this.productTypesInput);
    this.productTypeLength = await this.productTypeService.getProductTypeCount(
      filter
    );

    if (this.productTypeLength) {
      this.productTypeBufferArr = [];
      this.productTypePageIndex = 0;

      await this.fetchMoreProductTypes();

      this.productTypesInputLength = this.productTypeBufferArr.length;
      this.productTypeLength = this.productTypeBufferArr.length;
    }

    this.productTypesLoading = false;

    if (!this.manufacturersLoading) this.submitDisabled = false;
  }

  async selectManufacturerDropdownItem(name: string) {
    this.manufacturersLoading = true;
    this.manufacturersInput = name;

    let filter = this.manufacturerWordFilter(this.manufacturersInput);
    this.manufacturerLength =
      await this.manufacturerService.getManufacturerCount(filter);

    if (this.manufacturerLength) {
      this.manufacturerBufferArr = [];
      this.manufacturerPageIndex = 0;

      await this.fetchMoreManufacturers();

      this.manufacturersInputLength = this.manufacturerBufferArr.length;
      this.manufacturerLength = this.manufacturerBufferArr.length;
    }

    this.manufacturersLoading = false;

    if (!this.productTypesLoading) this.submitDisabled = false;
  }

  async fetchMoreProductTypes() {
    this.productTypesLoading = true;

    let newProductTypes = await this.productTypeService.getMoreProductTypes(
      this.productTypesInput,
      this.bufferSize,
      ++this.productTypePageIndex
    );

    this.productTypeBuffer = { ...this.productTypeBuffer, ...newProductTypes };

    this.productTypeBufferArr = Object.keys(this.productTypeBuffer)
      .map((key) => {
        return { Id: Number(key), Name: this.productTypeBuffer[key] };
      })
      .sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

    let bufferLength = Object.keys(this.productTypeBuffer).length;

    this.productTypesInputLength =
      bufferLength > this.productTypeLength
        ? this.productTypeLength
        : bufferLength;

    this.productTypesLoading = false;
  }

  async fetchMoreManufacturers() {
    this.manufacturersLoading = true;

    let newManufacturers = await this.manufacturerService.getMoreManufacturers(
      this.manufacturersInput,
      this.bufferSize,
      ++this.manufacturerPageIndex
    );

    this.manufacturerBuffer = {
      ...this.manufacturerBuffer,
      ...newManufacturers,
    };

    this.manufacturerBufferArr = Object.keys(this.manufacturerBuffer)
      .map((key) => {
        return { Id: Number(key), Name: this.manufacturerBuffer[key] };
      })
      .sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

    let bufferLength = Object.keys(this.manufacturerBuffer).length;

    this.manufacturersInputLength =
      bufferLength > this.manufacturerLength
        ? this.manufacturerLength
        : bufferLength;

    this.manufacturersLoading = false;
  }

  async onProductTypeScroll({ end }) {
    if (
      this.productTypesLoading ||
      this.productTypeLength <= this.productTypesInputLength
    )
      return;

    if (
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.productTypesInputLength
    )
      await this.fetchMoreProductTypes();
  }

  async onManufacturerScroll({ end }) {
    if (
      this.manufacturersLoading ||
      this.manufacturerLength <= this.manufacturersInputLength
    )
      return;

    if (
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.manufacturersInputLength
    )
      await this.fetchMoreManufacturers();
  }

  async selectModelDropdownItem(modelName: string) {
    this.makeModelsLoading = true;
    this.makeModelsInput = modelName;

    let filter = this.makeModelNameFilter(this.makeModelsInput);
    this.makeModelLength = await this.makeModelService.getMakeModelCount(
      filter
    );

    if (this.makeModelLength) {
      this.makeModelBufferArr = [];
      this.makeModelPageIndex = 0;

      await this.fetchMoreMakeModels();

      this.makeModelsInputLength = this.makeModelBufferArr.length;
      this.makeModelLength = this.makeModelBufferArr.length;
    }

    this.makeModelsLoading = false;

    this.checkEnableSubmit();
  }

  async selectConfigurationDropdownItem(configName: string) {
    this.configurationsLoading = true;
    this.configurationsInput = configName;

    let filter = this.configurationNameFilter(this.configurationsInput);
    this.configurationLength =
      await this.configurationService.getConfigurationCount(filter);

    if (this.configurationLength) {
      this.configurationBufferArr = [];
      this.configurationPageIndex = 0;

      await this.fetchMoreConfigurations();

      this.configurationsInputLength = this.configurationBufferArr.length;
      this.configurationLength = this.configurationBufferArr.length;
    }

    this.configurationsLoading = false;

    this.checkEnableSubmit();
  }

  async checkEnableSubmit() {
    if (
      !this.makeModelsLoading &&
      !this.configurationsLoading &&
      !this.featuredImageLoading
    )
      this.submitDisabled = false;
  }

  onClose() {
    this.cancelFeaturedImageLoad();
  }

  toggleProductType() {
    this.isNewProductType = !this.isNewProductType;

    this.toggleForceNewModel();
  }

  toggleManufacturer() {
    this.isNewManufacturer = !this.isNewManufacturer;

    this.toggleForceNewModel();
  }

  toggleForceNewModel() {
    this.isForceNewModel = this.isNewProductType || this.isNewManufacturer;

    this.toggleModelRequiredValidator();
    this.toggleProductTypeRequiredValidator();
    this.toggleManufacturerRequiredValidator();
  }

  toggleModel() {
    this.isNewModel = !this.isNewModel;

    if (this.isNewModel) this.yearModel.setValue(this.newModelYear);
    else {
      this.newModelYear = this.yearModel.value;
      this.yearModel.setValue(this.modelYear);
    }

    this.toggleModelRequiredValidator();
    this.toggleProductTypeRequiredValidator();
    this.toggleManufacturerRequiredValidator();
  }

  toggleConfiguration() {
    this.isNewConfiguration = !this.isNewConfiguration;

    this.toggleConfigurationRequiredValidator();
  }

  toggleManufacturerRequiredValidator() {
    if (this.isNewManufacturer) {
      this.manufacturerName.setValidators(Validators.required);
      this.manufacturerId.clearValidators();
    } else {
      if (this.isNewModel || this.isForceNewModel)
        this.manufacturerId.setValidators(Validators.required);
      else this.manufacturerId.clearValidators();

      this.manufacturerName.clearValidators();
    }

    this.manufacturerId.updateValueAndValidity();
    this.manufacturerName.updateValueAndValidity();
  }

  toggleProductTypeRequiredValidator() {
    if (this.isNewProductType) {
      this.productTypeName.setValidators(Validators.required);
      this.productTypeId.clearValidators();
    } else {
      if (this.isNewModel || this.isForceNewModel)
        this.productTypeId.setValidators(Validators.required);
      else this.productTypeId.clearValidators();

      this.productTypeName.clearValidators();
    }

    this.productTypeId.updateValueAndValidity();
    this.productTypeName.updateValueAndValidity();
  }

  toggleModelRequiredValidator() {
    if (this.isNewModel || this.isForceNewModel) {
      this.modelName.setValidators(Validators.required);
      this.modelId.clearValidators();
    } else {
      this.modelId.setValidators(Validators.required);
      this.modelName.clearValidators();
    }

    this.modelName.updateValueAndValidity();
    this.modelId.updateValueAndValidity();
  }

  toggleConfigurationRequiredValidator() {
    if (this.isNewConfiguration) {
      this.configurationName.setValidators(Validators.required);
      this.configurationId.clearValidators();
    } else {
      this.configurationId.setValidators(Validators.required);
      this.configurationName.clearValidators();
    }

    this.configurationId.updateValueAndValidity();
    this.configurationName.updateValueAndValidity();
  }

  autoFillMetaTitle() {
    let manufacturerName = "";
    let modelName = "";

    if (this.isNewModel || this.isForceNewModel) {
      modelName = (this.modelName.value || "").trim();

      if (!this.isNewManufacturer || !this.manufacturerName.value) {
        let selectedManufacturer = this.manufacturerBufferArr.filter(
          (m) => m.Id == this.manufacturerId.value
        );

        if (selectedManufacturer && selectedManufacturer.length)
          manufacturerName = selectedManufacturer[0].Name;
      } else {
        manufacturerName = (this.manufacturerName.value || "").trim();
      }
    } else {
      if (this.selectedModelName) {
        modelName = this.selectedModelName;
        manufacturerName = this.modelManufacturerName;
      } else {
        let selectedModel = this.makeModelBufferArr.filter(
          (m) => m.Id == this.modelId.value
        );

        if (selectedModel && selectedModel.length) {
          modelName = selectedModel[0].Name;
          manufacturerName = selectedModel[0].ManufacturerName;
        }
      }
    }

    if (manufacturerName && modelName) {
      this.metaTitle.setValue(
        `${manufacturerName} ${modelName}` + " Dimensions - Depth RoRo"
      );
    }
  }

  autoFillMetaKeywords() {
    let manufacturerName = "";
    let modelName = "";

    if (this.isNewModel || this.isForceNewModel) {
      modelName = (this.modelName.value || "").trim();

      if (!this.isNewManufacturer || !this.manufacturerName.value) {
        let selectedManufacturer = this.manufacturerBufferArr.filter(
          (m) => m.Id == this.manufacturerId.value
        );

        if (selectedManufacturer && selectedManufacturer.length)
          manufacturerName = selectedManufacturer[0].Name;
      } else {
        manufacturerName = (this.manufacturerName.value || "").trim();
      }
    } else {
      if (this.selectedModelName) {
        modelName = this.selectedModelName;
        manufacturerName = this.modelManufacturerName;
      } else {
        let selectedModel = this.makeModelBufferArr.filter(
          (m) => m.Id == this.modelId.value
        );

        if (selectedModel && selectedModel.length) {
          modelName = selectedModel[0].Name;
          manufacturerName = selectedModel[0].ManufacturerName;
        }
      }
    }

    if (manufacturerName && modelName) {
      this.metaKeywords.setValue(
        `${manufacturerName} ${modelName}` + " Dimensions"
      );
    }
  }

  autoFillMetaDesc() {
    let manufacturerName = "";
    let modelName = "";
    let configName = "";

    if (this.isNewModel || this.isForceNewModel) {
      modelName = (this.modelName.value || "").trim();

      if (!this.isNewManufacturer || !this.manufacturerName.value) {
        let selectedManufacturer = this.manufacturerBufferArr.filter(
          (m) => m.Id == this.manufacturerId.value
        );

        if (selectedManufacturer && selectedManufacturer.length)
          manufacturerName = selectedManufacturer[0].Name;
      } else {
        manufacturerName = (this.manufacturerName.value || "").trim();
      }
    } else {
      if (this.selectedModelName) {
        modelName = this.selectedModelName;
        manufacturerName = this.modelManufacturerName;
      } else {
        let selectedModel = this.makeModelBufferArr.filter(
          (m) => m.Id == this.modelId.value
        );

        if (selectedModel && selectedModel.length) {
          modelName = selectedModel[0].Name;
          manufacturerName = selectedModel[0].ManufacturerName;
        }
      }
    }

    if (!this.isNewConfiguration) {
      let selectedConfiguration = this.configurationBufferArr.filter(
        (c) => c.Id == this.configurationId.value
      );

      if (selectedConfiguration && selectedConfiguration.length)
        configName = (selectedConfiguration[0].Name || "").trim();
    } else {
      configName = (this.configurationName.value || "").trim();
    }

    if (manufacturerName && modelName && configName) {
      let title = `${manufacturerName} ${modelName} ${configName}`;
      let metaDesc: string = `View ${title} dimensions and weights.`;

      this.metaDescription.setValue(metaDesc);
      this.title.setValue(title);
      this.autoFillMetaURL(title + "-dimensions");
    }
  }

  autoFillMetaURL(term: string) {
    let metaURL: string = term;

    metaURL = metaURL.trim().toLowerCase().replace("+", "plus");
    metaURL = metaURL.replace("-", " ");
    metaURL = metaURL.replace(/[^a-zA-Z0-9 ]/g, "");
    metaURL = metaURL.replace(/\s\s+/g, " ");
    metaURL = metaURL.replace(/\s/g, "-");

    this.metaUrl.setValue(metaURL);
  }

  onManufacturerNameChange() {
    this.autoFillMetaTitle();
    this.autoFillMetaKeywords();
    this.autoFillMetaDesc();
  }

  onModelNameChange() {
    this.autoFillMetaTitle();
    this.autoFillMetaKeywords();
    this.autoFillMetaDesc();
  }

  onConfigurationNameChange() {
    this.autoFillMetaDesc();
  }

  calculateCMB() {
    const cmb =
      parseFloat(this.length.value) *
      parseFloat(this.width.value) *
      parseFloat(this.height.value);

    this.cbm.setValue(!isNaN(cmb) ? Math.round(cmb * 100) / 100 : "");
  }

  modelSearch(
    term: string,
    item: { Name: string; ProductTypeId: number; ManufacturerId: number }
  ): boolean {
    return (
      item.Name.toLocaleLowerCase().includes(term.toLocaleLowerCase()) &&
      (!this.productTypeId.value ||
        item.ProductTypeId == this.productTypeId.value) &&
      (!this.manufacturerId.value ||
        item.ManufacturerId == this.manufacturerId.value)
    );
  }

  async resetModelDropdown() {
    this.makeModelLength = await this.makeModelService.getMakeModelCount(
      this.makeModelNameFilter(this.makeModelsInput)
    );
    this.modelSelectComponent.handleClearClick();
  }
}
