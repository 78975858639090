import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TableControl } from 'src/app/models/TableControl';
import { MakeModel } from 'src/app/models/MakeModel';
import { FilterModel } from 'src/app/models/FilterModel';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { ConstantsService } from '../../common/constants/constants.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MakeModelService {

	public distributorId: number;

  constructor(private http: HttpClient,
    private utilities: UtilitiesService,
    private constants: ConstantsService) { 
      // let currentUser = JSON.parse(localStorage.getItem('_currentUser'));
      // this.distributorId = currentUser.profile.sub;
      this.distributorId = this.constants.DISTRIBUTOR_ID;
    }
  
  async getMakeModels(tableControl: TableControl) {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Products/GetMakeModelsPaginatedByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableControls(url, tableControl);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getAllMakeModels() {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Products/GetMakeModelsPaginatedByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableControls(url, new TableControl(999));

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getMakeModelCount(filter: FilterModel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Products/GetMakeModelsCountByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getMakeModelCountWithProductTypeAndManufacturer(filter: FilterModel, productTypeId: string, manufacturerId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Products/GetMakeModelsCountByDistributorId?distributorId=${this.distributorId}&productTypeId=${productTypeId}&manufacturerId=${manufacturerId}`;
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async saveMakeModel(makeModel: MakeModel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Products/Save";
      var body = {
        MakeOrModelName: makeModel.Name,
        YearModel: makeModel.YearModel,
        ProductTypeId: makeModel.ProductTypeId,
        ManufacturerId: makeModel.ManufacturerId,
        DistributorId: this.distributorId,
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async editMakeModel(makeModel: MakeModel): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + "api/Products/Save";
      var body = {
        Id: makeModel.Id,
        MakeOrModelName: makeModel.Name,
        YearModel: makeModel.YearModel,
        ProductTypeId: makeModel.ProductTypeId,
        ManufacturerId: makeModel.ManufacturerId,
        DistributorId: this.distributorId,
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async deleteMakeModel(makeModelId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + `api/Products/Delete/${makeModelId}`;
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, null, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async importMakeModels(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.constants.SERVICE_URL}api/Products/ReadExcelFile`;
      const formData = new FormData();
      formData.append('excel', file, file.name);

      this.http.post(url, formData, {
        headers: this.utilities.getAuthorizationTokenHeader(),
        params: new HttpParams().set("distributorId", this.constants.DISTRIBUTOR_ID.toString())
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  getMoreMakemodels(term: string, bufferSize: number, pageIndex: number, productTypeId: number, manufacturerId: number): Promise<{}> {
      return new Promise((resolve, reject) => {
        var url = this.constants.SERVICE_URL + `api/Products/GetMakeModelsPaginatedByDistributorId?distributorId=${this.distributorId}&productTypeId=${productTypeId}&manufacturerId=${manufacturerId}`;

        var tableCntrl = new TableControl(bufferSize);
        tableCntrl.Filter = new FilterModel("MakeOrModelName", term);
        tableCntrl.Sorting = "MakeOrModelNameASC";
        tableCntrl.Page = pageIndex;

        url = this.utilities.applyTableControls(url, tableCntrl);

        this.http.get<any[]>(url).pipe(
          map((items: {Id:number, MakeOrModelNameId:number, MakeOrModelName:string, ProductTypeId:number, ProductTypeName:string, ManufacturerId:number, ManufacturerName:string, YearModel: number}[]) => {
            var obj = {};
            items.forEach(item => {
              obj[item.Id] = {
                Name: item.MakeOrModelName,
                NameId: item.MakeOrModelNameId,
                ProductTypeId: item.ProductTypeId,
                ManufacturerId: item.ManufacturerId,
                ProductTypeName: item.ProductTypeName,
                ManufacturerName: item.ManufacturerName,
                YearModel: item.YearModel
              };
            });
            return obj;
            })
          )
          .subscribe(data => resolve(data),
                    err => reject(err));
      })
  }

  async syncAll(): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Products/SyncAll/${this.distributorId}`;
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, null, headers).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getSyncStatus() : Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Products/GetSyncStatus/${this.distributorId}`;

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }
}
