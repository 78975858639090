import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChildren, ElementRef, Renderer2 } from '@angular/core';
import { ProductConfigurationService } from 'src/app/services/http/product-configuration/product-configuration.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterOption } from 'src/app/models/FilterOption';
import { FilterModel } from 'src/app/models/FilterModel';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';
import { ProductConfiguration } from 'src/app/models/ProductConfiguration';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-view-product-configurations',
  templateUrl: './view-product-configurations.component.html',
  styleUrls: ['./view-product-configurations.component.scss']
})
export class ViewProductConfigurationsComponent implements OnInit, OnDestroy {
  @ViewChildren('checkboxRef', { read: ElementRef}) checkboxRefs: ElementRef[];
  //Modal Identifier Constants
  addProductConfigurationModal: string = 'addProductConfiguration';
  editProductConfigurationModal: string = 'editProductConfiguration';
  deleteProductConfigurationModal: string = 'deleteProductConfiguration';
  
  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];

 dict = new Map<string, ProductConfiguration>();
  
  //Local Variables
  imagePreviewerModal: string = 'imagePreviewer';
  productConfigurations: any;
	productTypes: any;
  manufacturers: any;
  makeModels: any;
  configurations: any;

  isSyncing = false;
  isSyncingProducts = false;
  syncPoll: any;
  progressRate = 0;
  loadedOngoingSync = false;

  cancelFeaturedImageLoad = new Subject<void>();

  constructor(private productConfigurationService: ProductConfigurationService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService : ToastrService, private renderer: Renderer2) { 

    const filterOptionHasFeaturedImage = new FilterOption('HasFeaturedImage', 'Featured Image');
    filterOptionHasFeaturedImage.Type = 'truefalse';
    filterOptionHasFeaturedImage.Options = [new FilterOption('true', 'Included'), new FilterOption('false', 'Not Included')];

    this.filterOptions = [
      new FilterOption('Id', 'Id'),
      new FilterOption('ConfigName', 'Configuration'),
      new FilterOption('ProductTypeName', 'Product Type'),
      new FilterOption('ManufacturerName', 'Manufacturer'),
      new FilterOption('MakeOrModelName', 'Model'),
      new FilterOption('Length', 'Length (meters)'),
      new FilterOption('Width', 'Width (meters)'),
      new FilterOption('Height', 'Height (meters)'),
      new FilterOption('Weight', 'Weight (kilograms)'),
      new FilterOption('CBM', 'CBM'),
      filterOptionHasFeaturedImage,
      new FilterOption('Created', 'Modified On'),
      new FilterOption('CreatedBy', 'Modified By')
    ];

    this.tableControl = new TableControl(10);
  }

  async ngOnInit() {
    if (await this.getSyncStatus())
      await this.initializeSyncPolling();

    this.spinner.show("fullScreenSpinner");
    await this.getItemCount();
    this.spinner.hide("fullScreenSpinner");

    await this.loadProductConfigurations();
  }
  
  ngOnDestroy(): void {
    clearInterval(this.syncPoll);
  }

  private async loadProductConfigurations(){
    this.spinner.show("tableSpinner");
    this.cancelLoadFeaturedImage();
    this.productConfigurations = await this.getProductConfigurations() || [];
    this.tableControl.CurrentItemCount = this.productConfigurations.length;
    this.spinner.hide("tableSpinner");

    this.loadFeaturedImages(this.productConfigurations);
  }

  async loadFeaturedImages(productConfigs: any[])
  {
    productConfigs.forEach(async pc => {
        pc.FeaturedImage = await this.getFeaturedImage(pc.Id) || '';
    });
  }

  async getFeaturedImage(id: number) { 
    return await this.productConfigurationService.getFeaturedImage(id, this.cancelFeaturedImageLoad); 
  };

  cancelLoadFeaturedImage() {
    this.cancelFeaturedImageLoad.next();
    this.cancelFeaturedImageLoad.complete();
    this.cancelFeaturedImageLoad = new Subject<void>();
  }
  
  private async getProductConfigurations(){
    return this.productConfigurationService.getProductConfigurations(this.tableControl);
  }

  private async getItemCount(){
    this.tableControl.TotalItemCount = await this.productConfigurationService.getProductConfigurationCount(this.tableControl.Filter) || 0;
  }
  
  async syncProductConfigs() {
    this.isSyncing = true;

    let result = await this.productConfigurationService.syncAll();

    if (this.isSyncing = result && result.syncId)
      this.initializeSyncPolling();
  }

  private async getSyncStatus(): Promise<any> {
    return await this.productConfigurationService.getSyncStatus();
  }

  async initializeSyncPolling() {
    this.isSyncing = true;

    clearInterval(this.syncPoll);

    this.syncPoll = setInterval(() => {
      this.getSyncStatus().then(result => {
        this.isSyncing = !!result.syncId;
        
        this.progressRate = parseFloat((result.progress * 100).toFixed(2));

        if (this.isSyncing)
          this.loadedOngoingSync = true;

        if (!this.isSyncing && this.syncPoll) {
          clearInterval(this.syncPoll);
          this.syncPoll = null;

          if (this.loadedOngoingSync) {
            this.loadedOngoingSync = false;
            this.toasterService.success("Syncronisation was completed.", "Sync Product Configurations");
          }
        }

      });
    }, 2000);
  }

  async addProductConfiguration(){
    this.ngxSmartModalService.resetModalData(this.editProductConfigurationModal);
    this.ngxSmartModalService.setModalData(new ProductConfiguration(), this.editProductConfigurationModal);
    this.ngxSmartModalService.open(this.editProductConfigurationModal);
  }

    editProductConfiguration(productConfig: any){
      this.ngxSmartModalService.resetModalData(this.editProductConfigurationModal);
      
      let productConfigForm = new ProductConfiguration();
      productConfigForm.Construct({
        Id: productConfig.Id,
        ProductTypeId: productConfig.ProductTypeId,
        ProductTypeName: productConfig.ProductTypeName,
        ManufacturerId: productConfig.ManufacturerId,
        ManufacturerName: productConfig.ManufacturerName,
        MakeOrModelId: productConfig.MakeOrModelId,
        MakeOrModelNameId: productConfig.MakeOrModelNameId,
        MakeOrModelName: productConfig.MakeOrModelName,
        YearModel: productConfig.YearModel,
        ConfigurationId: productConfig.ConfigId,
        ConfigurationName: productConfig.ConfigName,
        CBM: productConfig.CBM,
        Height: productConfig.Height,
        Weight: productConfig.Weight,
        Length: productConfig.Length,
        Width: productConfig.Width,
        Title: productConfig.Title,
        MetaTitle: productConfig.MetaTitle,
        MetaDescription: productConfig.MetaDescription,
        MetaKeywords: productConfig.MetaKeywords,
        MetaURL: productConfig.MetaURL,
        FeaturedImage: productConfig.FeaturedImage
      });

      this.ngxSmartModalService.setModalData(productConfigForm, this.editProductConfigurationModal);
      this.ngxSmartModalService.open(this.editProductConfigurationModal);
    }

    deleteProductConfiguration(productConfig: any){
      this.ngxSmartModalService.resetModalData(this.deleteProductConfigurationModal);
      this.ngxSmartModalService.setModalData(productConfig, this.deleteProductConfigurationModal);
      this.ngxSmartModalService.open(this.deleteProductConfigurationModal);
    }

    importProductConfigs(){
      this.ngxSmartModalService.open("importProductConfigs");
    }

    expandImage({ FeaturedImage, MetaTitle}) {
      this.ngxSmartModalService.resetModalData(this.imagePreviewerModal);
      this.ngxSmartModalService.setModalData({ image: FeaturedImage, alt: MetaTitle}, this.imagePreviewerModal);
      this.ngxSmartModalService.open(this.imagePreviewerModal);
    }
  
    //EMMITED EVENT ACTIONS
    onProductConfigurationAdded(){
      this.tableControl.Page = 1; 
      this.getItemCount();
      this.loadProductConfigurations();
      this.toasterService.success("Product Configuration successfully Added.", "Add Product Configuration");
    }
  
    onProductConfigurationEdited(){
      this.loadProductConfigurations();
      this.toasterService.success("Product Configuration successfully Edited.", "Edit Product Configuration");
    }

    onProductConfigurationSynced(){
      this.loadProductConfigurations();
      this.toasterService.success("Products Configuration successfully Synced.", "Sync Product Configuration");
    }

    onProductConfigurationSyncedFailed(){
      this.loadProductConfigurations();
      this.toasterService.error("Products Configuration Syncing failed.", "Sync Product Configuration");
    }
  
    onProductConfigurationDeleted() {
      this.loadProductConfigurations();
      this.toasterService.success("Product Configuration successfully Deleted.", "Delete Product Configuration");
    }

    onProductConfigsImported() {
      this.getItemCount();
      this.loadProductConfigurations();
      this.toasterService.success("Product Configurations successfully Imported.", "Import Product Configurations");
    }

    onPageChanged({ page, maxPageSize }){
      this.tableControl.Page = page; 
      this.tableControl.MaxPageSize = maxPageSize;
      this.loadProductConfigurations();
    }
  
    onFiltered(filter : FilterModel){
      this.tableControl.Page = 1; 
      this.tableControl.Filter = filter;
      this.getItemCount();
      this.loadProductConfigurations();
    }
  
    onSorted(sort: string){
      this.tableControl.Sorting = sort;
      this.tableControl.Page = 1; 
      this.loadProductConfigurations();
    }

    addRemoveProducts(product :any){
    
      let productConfig = this.mapProductConfiguration(product)     
      
      if(product.selected){
        this.dict.set(product.Id, productConfig);
      }
      else{
        this.dict.delete(product.Id);
      }
      
    }

   async importSelectedProductConfigs(){
    this.isSyncingProducts = true;
    let isSuccessful: boolean;
      await this.productConfigurationService.editSelectedProductConfiguration(Array.from(this.dict.values()))
      .then(_ => isSuccessful = true);

      this.dict = new Map<string, ProductConfiguration>();

      if(isSuccessful){
         this.onProductConfigurationSynced();   
         this.isSyncingProducts = false;
      }
      else{
        this.onProductConfigurationSyncedFailed();
      }
      return isSuccessful;
    }

    selectedAllProductConfigs(){
    
      if (this.checkboxRefs) {
       
        this.checkboxRefs.forEach((checkbox: ElementRef) => {
          this.renderer.setProperty(checkbox.nativeElement, 'checked', true);
        });
      }

     for(let i=0;i<this.productConfigurations.length;i++){
      let prod = this.mapProductConfiguration(this.productConfigurations[i]);
      this.dict.set(this.productConfigurations[i].Id, prod);
     }

    }

    mapProductConfiguration(product: any){
      let productConfig = new ProductConfiguration();
      productConfig.Id= product.Id;
      productConfig.CBM= product.CBM;
      productConfig.Height= product.Height;
      productConfig.Weight= product.Weight;
      productConfig.Length= product.Length;
      productConfig.Width= product.Width,
      productConfig.Title= product.Title;
      productConfig.MetaTitle= product.MetaTitle;
      productConfig.MetaDescription= product.MetaDescription;
      productConfig.MetaKeywords= product.MetaKeywords;
      productConfig.MetaURL= product.MetaURL;
      productConfig.FeaturedImage= product.FeaturedImage;  
      productConfig.ProductTypeName = product.ProductTypeName; 
      productConfig.ProductTypeId = product.ProductTypeId;
      productConfig.ManufacturerName = product.ManufacturerName;
      productConfig.ManufacturerId = product.ManufacturerId;
      productConfig.MakeOrModelName = product.MakeOrModelName;
      productConfig.YearModel = product.YearModel;
      productConfig.MakeOrModelId = product.MakeOrModelId;
      productConfig.MakeOrModelNameId = product.MakeOrModelNameId;
      productConfig.ConfigurationName = product.ConfigName;  
      productConfig.ConfigurationId = product.ConfigId;

      return productConfig;
    }
}
