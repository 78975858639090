import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { SailingSchedule } from 'src/app/models/SailingScedule';
import { SailingScheduleService } from 'src/app/services/http/sailing-schedule/sailing-schedule.service';
import { VoyageService } from 'src/app/services/http/voyage/voyage.service';
import { PortService } from 'src/app/services/http/port/port.service';

@Component({
	selector: 'app-edit-sailing-schedule',
	templateUrl: './edit-sailing-schedule.component.html',
	styleUrls: ['./edit-sailing-schedule.component.scss']
})
export class EditSailingScheduleComponent implements OnInit {

	@Output() sailingScheduleEdited = new EventEmitter();
	@Input() vessels: any;
	@Input() countries: any;
	voyages: any;
	originPorts: any;
	destinationPorts: any;

	sailingSchedule: SailingSchedule = new SailingSchedule();

	constructor(
		private spinner: NgxSpinnerService,
		private ngxSmartModalService: NgxSmartModalService,
		private sailingScheduleService: SailingScheduleService,
		private voyageService: VoyageService,
		private portService: PortService
	) {
		this.sailingSchedule.DistributorId = this.sailingScheduleService.distributorId;
	}

	async ngOnInit() {
	}

	async editSailingSchedules(sailingSchedule: NgForm) {
		if (sailingSchedule.valid) {
			this.spinner.show('fullScreenSpinner');
			this.sailingSchedule.Construct(
				sailingSchedule.value.sailingScheduleId,
				sailingSchedule.value.voyage,
				sailingSchedule.value.originPortId,
				sailingSchedule.value.destinationPortId,
				sailingSchedule.value.originCountryId,
				sailingSchedule.value.destinationCountryId,
				sailingSchedule.value.ETA,
				sailingSchedule.value.ETD,
				this.sailingSchedule.DistributorId
			);
			await this.sailingScheduleService.editSailingSchedule(this.sailingSchedule);
			this.spinner.hide('fullScreenSpinner');
			this.ngxSmartModalService.getModal('editSailingSchedule').close();
			this.sailingScheduleEdited.emit();
		}
	}

	async loadVoyages(vesselId: number) {
		if (vesselId) {
			this.spinner.show('modalSpinner');
			this.voyages = await this.voyageService.getVoyagesByVessel(vesselId);
			this.spinner.hide('modalSpinner');
		}
	}

	async loadOriginPorts(originCountryId: number) {
		if (originCountryId) {
			this.spinner.show('modalSpinner');
			this.originPorts = await this.portService.getPortsByCountry(originCountryId);
			this.spinner.hide('modalSpinner');
		}
	}

	async loadDestinationPorts(destinationCountryId: number) {
		if (destinationCountryId) {
			this.spinner.show('modalSpinner');
			this.destinationPorts = await this.portService.getPortsByCountry(destinationCountryId);
			this.spinner.hide('modalSpinner');
		}
	}
}
