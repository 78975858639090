import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar-maker',
  templateUrl: './avatar-maker.component.html',
  styleUrls: ['./avatar-maker.component.scss']
})
export class AvatarMakerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
