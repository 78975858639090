import { Component, OnInit, OnDestroy } from '@angular/core';
import { DistributorService } from 'src/app/services/http/distributor/distributor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterOption } from 'src/app/models/FilterOption';
import { FilterModel } from 'src/app/models/FilterModel';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';
import { Distributor } from 'src/app/models/Distributor';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-view-distributors',
  templateUrl: './view-distributors.component.html',
  styleUrls: ['./view-distributors.component.scss']
})
export class ViewDistributorsComponent implements OnInit, OnDestroy {
  //Modal Identifier Constants
  addDistributorModal: string = 'addDistributor';
  editDistributorModal: string = 'editDistributor';
  deleteDistributorModal: string = 'deleteDistributor';

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];
  
  //Local Variables
  distributors: any;

  isSyncing = false;
  syncPoll: any;
  progressRate = 0;
  loadedOngoingSync = false;

  constructor(private distributorService: DistributorService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService : ToastrService) { 
      this.filterOptions = [
        new FilterOption('Id', 'Id'),
        new FilterOption('DistributorName', 'Distributor Name'),
        new FilterOption('Company', 'Company'),
        new FilterOption('DirectorName', 'Director Name'),
        new FilterOption('TelephoneNo', 'Telephone No.'),
        new FilterOption('Email', 'Email')
      ];

      this.tableControl = new TableControl(10);
    }

  async ngOnInit() {
    this.spinner.show("fullScreenSpinner");
    await this.getItemCount();
    this.spinner.hide("fullScreenSpinner");

    await this.loadDistributors();
  }

  ngOnDestroy(): void {
    clearInterval(this.syncPoll);
  }

  private async loadDistributors(){
    this.spinner.show("tableSpinner");
    this.distributors = await this.getDistributors();
    this.tableControl.CurrentItemCount = this.distributors.length;
    this.spinner.hide("tableSpinner");
  }

  private async getDistributors(){
    return this.distributorService.getDistributors(this.tableControl);
  }

  private async getItemCount(){
    this.tableControl.TotalItemCount = await this.distributorService.getDistributorCount(this.tableControl.Filter);
  }

  addDistributor(){
    this.openEditDistributor();
  }

  editDistributor(distributor: any){
    this.openEditDistributor(distributor);
  }

  openEditDistributor(distributor: any = null) {
    this.ngxSmartModalService.resetModalData(this.editDistributorModal);

    let distributorForm = new Distributor();

    if (distributor)
      distributorForm.Construct({
        Id: distributor.Id, 
        DistributorName: distributor.DistributorName,
        Company: distributor.Company,
        DirectorName: distributor.DirectorName,
        TelephoneNo: distributor.TelephoneNo,
        Email: distributor.Email,
        Address: distributor.Address,
        Website: distributor.Website,
        BankAccountNumber: distributor.BankAccountNumber,
        BankAccountName: distributor.BankAccountName,
        PayPalEmail: distributor.PayPalEmail,
        ABN: distributor.ABN,
        EIN: distributor.EIN,
        AccountsContact: distributor.AccountsContact,
        EverydayContactPerson: distributor.EverydayContactPerson,
        HexColor: distributor.HexColor,
        Logo: distributor.Logo,
        IsRegistered: true,
        Active: true
      });
  
    this.ngxSmartModalService.setModalData(distributorForm, this.editDistributorModal);
    this.ngxSmartModalService.open(this.editDistributorModal);
  }
  
  deleteDistributor(distributor: any){
    this.ngxSmartModalService.resetModalData(this.deleteDistributorModal);
    this.ngxSmartModalService.setModalData(distributor, this.deleteDistributorModal);
    this.ngxSmartModalService.open(this.deleteDistributorModal);
  }

  //EMMITED EVENT ACTIONS
  onDistributorAdded(){
    this.tableControl.Page = 1; 
    this.getItemCount();
    this.loadDistributors();
    this.toasterService.success("Distributor successfully Added.", "Add Distributor");
  }

  onDistributorEdited(){
    this.loadDistributors();
    this.toasterService.success("Distributor successfully Edited.", "Edit Distributor");
  }

  onDistributorDeleted(){
    this.getItemCount();
    this.loadDistributors();
    this.toasterService.success("Distributor successfully Deleted.", "Delete Distributor");
  }

  onDistributorsImported(){
    this.getItemCount();
    this.loadDistributors();
    this.toasterService.success("Distributors successfully Imported.", "Import Distributors");
  }

  onPageChanged({ page, maxPageSize }){
    this.tableControl.Page = page;
    this.tableControl.MaxPageSize = maxPageSize;

    this.loadDistributors();
  }

  onFiltered(filter : FilterModel){
    this.tableControl.Page = 1; 
    this.tableControl.Filter = filter;
    this.getItemCount();
    this.loadDistributors();
  }

  onSorted(sort: string){
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1; 
    this.loadDistributors();
  }
}
