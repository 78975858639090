import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TableControl } from 'src/app/models/TableControl';
import { ProductType } from 'src/app/models/ProductType';
import { FilterModel } from 'src/app/models/FilterModel';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { ConstantsService } from '../../common/constants/constants.service';
import { map } from 'rxjs/operators';


export class KeyVal {
  public Id:number;
  public Name:string;
}

@Injectable({
  providedIn: 'root'
})
export class ProductTypeService {

	public distributorId: number;

  constructor(private http: HttpClient,
    private utilities: UtilitiesService,
    private constants: ConstantsService) {
      // let currentUser = JSON.parse(localStorage.getItem('_currentUser'));
      // this.distributorId = currentUser.profile.sub;
      this.distributorId = this.constants.DISTRIBUTOR_ID;
    }

    async getMoreProductTypes(term: string, bufferSize: number, pageIndex: number): Promise<{}> {
        return new Promise((resolve, reject) => {
          var url = this.constants.SERVICE_URL + `api/Products/GetProductTypesPaginatedByDistributorId?distributorId=${this.distributorId}`;

          var tableCntrl = new TableControl(bufferSize);
          tableCntrl.Filter = new FilterModel("Word", term);
          tableCntrl.Sorting = "WordASC";
          tableCntrl.Page = pageIndex;

          url = this.utilities.applyTableControls(url, tableCntrl);

          this.http.get<any[]>(url).pipe(
            map((items: {Id:number, ProductTypeName:string}[]) => {
              var obj = {};
              items.forEach(item => {
                obj[item.Id] = item.ProductTypeName;
              });
              return obj;
              })
            )
            .subscribe(data => resolve(data),
                      err => reject(err));
        })
    }

    async getAllProductTypes() {
      return new Promise((resolve, reject) => {
        var url = this.constants.SERVICE_URL + `api/Products/GetProductTypesPaginatedByDistributorId?distributorId=${this.distributorId}`;
        url = this.utilities.applyTableControls(url, new TableControl(999));
  
        this.http.get(url).subscribe(data => {
          resolve(data);
        }, err => {
          resolve(err);
        });
      });
    }
  
  async getProductTypes(tableControl: TableControl) {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Products/GetProductTypesPaginatedByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableControls(url, tableControl);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getProductTypeCount(filter: FilterModel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Products/GetProductTypesCountByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async saveProductType(productType: ProductType): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/ProductTypes/Save";
      var body = {
        Word: productType.Name,
        DistributorId: this.distributorId,
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async editProductType(productType: ProductType): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + "api/ProductTypes/Save";
      var body = {
        Id: productType.Id,
        Word: productType.Name,
        DistributorId: this.distributorId
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async disableProductType(productTypeId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + "api/ProductTypes/Disable";
      var body = "=" + productTypeId;
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, body, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async deleteProductType(productTypeId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + `api/ProductTypes/Delete/${productTypeId}`;
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, null, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async importProductTypes(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.constants.SERVICE_URL}api/ProductTypes/ReadExcelFile`;
      const formData = new FormData();
      formData.append('excel', file, file.name);

      this.http.post(url, formData, {
        headers: this.utilities.getAuthorizationTokenHeader(),
        params: new HttpParams().set("distributorId", this.constants.DISTRIBUTOR_ID.toString())
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  async syncAll(): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/ProductTypes/SyncAll/${this.distributorId}`;
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, null, headers).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getSyncStatus() : Promise<any> {
    
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/ProductTypes/GetSyncStatus/${this.distributorId}`;

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }
}
