import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { TableControl } from 'src/app/models/TableControl';
import { Configuration } from 'src/app/models/Configuration';
import { FilterModel } from 'src/app/models/FilterModel';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { ConstantsService } from '../../common/constants/constants.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

	private distributorId: number;

  constructor(private http: HttpClient,
    private utilities: UtilitiesService,
    private constants: ConstantsService) {
      this.distributorId = this.constants.DISTRIBUTOR_ID;
    }

  async getConfigurations(tableControl: TableControl) {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Configs/GetPaginatedByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableControls(url, tableControl);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getAllConfigurations() {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Configs/GetPaginatedByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableControls(url, new TableControl(999));

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getConfigurationCount(filter: FilterModel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Configs/GetCountByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async saveConfiguration(configuration: Configuration): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Configs/Save";
      var body = {
        Name: configuration.Name,
        DistributorId: this.distributorId,
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async editConfiguration(configuration: Configuration): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + "api/Configs/Save";
      var body = {
        Id: configuration.Id,
        Name: configuration.Name,
        DistributorId: this.distributorId
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async deleteConfiguration(configurationId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + `api/Configs/Delete/${configurationId}`;
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, null, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }
  
  async importConfigurations(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.constants.SERVICE_URL}api/Configs/ReadExcelFile`;
      const formData = new FormData();
      formData.append('excel', file, file.name);

      this.http.post(url, formData, {
        headers: this.utilities.getAuthorizationTokenHeader(),
        params: new HttpParams().set("distributorId", this.constants.DISTRIBUTOR_ID.toString())
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  async getMoreConfigurations(term: string, bufferSize: number, pageIndex: number): Promise<{}> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Configs/GetPaginatedByDistributorId?distributorId=${this.distributorId}`;

      var tableCntrl = new TableControl(bufferSize);
      tableCntrl.Filter = new FilterModel("Word", term);
      tableCntrl.Sorting = "WordASC";
      tableCntrl.Page = pageIndex;

      url = this.utilities.applyTableControls(url, tableCntrl);

      this.http.get<any[]>(url).pipe(
        map((items: {Id:number, Name:string}[]) => {
          var obj = {};
          items.forEach(item => {
            obj[item.Id] = item.Name;
          });
          return obj;
          })
        )
        .subscribe(data => resolve(data),
                  err => reject(err));
    })
  }

  
  async syncAll(): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Configs/SyncAll/${this.distributorId}`;
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, null, headers).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getSyncStatus() : Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Configs/GetSyncStatus/${this.distributorId}`;

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }
}
