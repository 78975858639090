export class Vessel {
    public Id: number;
    public Name: string;
    public CarrierId: number;
    public RampCapacity: number;
    public HatchHeight: number;

    public Clear(){
        this.Name = "";
        this.CarrierId = null;
        this.RampCapacity = null;
        this.HatchHeight = null;
    }

    public Construct(vessel: {id:number, carrierId: number, name: string, rampCapacity: number, hatchHeight: number}){
        this.Id = vessel.id;
        this.Name = vessel.name;
        this.CarrierId = vessel.carrierId;
        this.RampCapacity = vessel.rampCapacity;
        this.HatchHeight = vessel.hatchHeight;
    }
}