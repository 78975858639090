import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../common/constants/constants.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  currencies: any[];

  constructor(private http: HttpClient,
    private constants: ConstantsService) { 
      this.currencies = [];
    }

  async getCurrencies(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Currencies/GetAll";

      this.http.get(url).subscribe((data: any[]) => {
        this.currencies = data;
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  
	getCurrencyIdByISO(code: string, currencies: any[]) {
		let currency = currencies.find(c => c.ISO.toLocaleLowerCase() == code.toLocaleLowerCase());
		return currency.Id;
  }
  
  getCurrencyById(id: number, currencies: any[]): any {
    return currencies.find(c => c.Id == id);
  }
}