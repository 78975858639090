import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ConfigurationService } from 'src/app/services/http/configuration/configuration.service';
import { CarrierService } from 'src/app/services/http/carrier/carrier.service';

@Component({
  selector: 'app-delete-carrier',
  templateUrl: './delete-carrier.component.html',
  styleUrls: ['./delete-carrier.component.scss']
})
export class DeleteCarrierComponent implements OnInit {
  @Output() carrierDeleted = new EventEmitter();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private carrierService: CarrierService) { }

  ngOnInit() {
  }

  async deleteCarriers(carrierId: number) {
    this.spinner.show("fullScreenSpinner");
    await this.carrierService.deleteCarrier(carrierId);
    this.spinner.hide("fullScreenSpinner");

    this.ngxSmartModalService.getModal('deleteCarrier').close()
    this.carrierDeleted.emit();
  }
}
