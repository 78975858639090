import { Component, OnInit } from '@angular/core';
import { FilterModel } from 'src/app/models/FilterModel';
import { FilterOption } from 'src/app/models/FilterOption';
import { ReportService } from 'src/app/services/http/report/report.service';
import { DistributorService } from 'src/app/services/http/distributor/distributor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TableControl } from 'src/app/models/TableControl';
import { UtilitiesService } from 'src/app/services/common/utilities/utilities.service';
import { ConstantsService } from 'src/app/services/common/constants/constants.service';

@Component({
  selector: 'app-view-insurance-certificate-reports',
  templateUrl: './view-insurance-certificate-reports.component.html',
  styleUrls: ['./view-insurance-certificate-reports.component.scss']
})
export class ViewInsuranceCertificateReportsComponent implements OnInit {
  //Modal Identifier Constants
  detailedReportModal: string = "detailedInsuranceCertificateReport";

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];

  //Local Variables
  currentUser: any;
  distributors: any;
  distributorIds: string = "";
  reports: any;
  selectedDistributor: any;
  selectedDistributorId: any = null;

  constructor(private reportService: ReportService,
    private distributorService: DistributorService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private utilities: UtilitiesService,
    private constants: ConstantsService) { 
      this.filterOptions = [
        new FilterOption("CertificatesId", "Certificate Id"),
        new FilterOption("Currency", "Currency"),
        new FilterOption("Premium", "Premium"),
        new FilterOption("CountryOrigin", "Origin"),
        new FilterOption("CountryDestination", "Destination"),
        new FilterOption("ETA", "ETA"),
        new FilterOption("Distributor", "Distributor"),
        new FilterOption("Created", "Created On"),
        new FilterOption("CreatedByName", "Created By")
      ];
  
      this.currentUser = JSON.parse(this.utilities.getStorage(this.constants.CURRENT_USER));
      this.tableControl = new TableControl(10);
    }

  async ngOnInit() {
    this.spinner.show("fullScreenSpinner");
    await this.loadDistributors();
    await this.loadItemCount();
    this.spinner.hide("fullScreenSpinner");

    await this.loadReports();
  }

  private async loadReports() {
    this.spinner.show("tableSpinner");
    this.reports = await this.getInsuraceCertificates();
    this.tableControl.CurrentItemCount = this.reports.length;
    this.spinner.hide("tableSpinner");
  }

  private async loadItemCount() {
    this.tableControl.TotalItemCount = await this.reportService.GetCountInsuranceCertificatesByDistributors(this.distributorIds, this.tableControl.Filter);
  }

  private async loadDistributors() {
    this.distributors = await this.getDistributors();

    if (this.currentUser.profile.role != "System Administrator"){
      this.distributorIds = this.distributors.Id;
      this.selectedDistributor = [this.distributors];
    } else {
      this.distributors.forEach(distributor => {
        this.distributorIds += distributor.Id + "|";
      });

      this.distributorIds = this.distributorIds.slice(0, -1);
      this.selectedDistributor = null;
    }
  }

  private getInsuraceCertificates() {
    return this.reportService.GetInsuranceCertificatesByDistributors(this.distributorIds, this.tableControl);
  }

  private getDistributors() {
    if (this.currentUser.profile.role != "System Administrator"){
      return this.distributorService.getDistributorByUserId(this.currentUser.profile.sub);
    } else{
      return this.distributorService.getDistributors(this.tableControl);
    }
  }

  selectDistributor(){
    this.distributorIds = "";

    if (this.selectedDistributorId){
      this.distributorIds = this.selectedDistributorId;
      this.selectedDistributor = this.distributors.filter(x => x.Id == this.distributorIds);
    } else{
      this.distributors.forEach(distributor => {
        this.distributorIds += distributor.Id + "|";
      });

      this.distributorIds = this.distributorIds.slice(0, -1);
      this.selectedDistributor = null;
    }

    this.tableControl.Page = 1;
    this.loadReports();
    this.loadItemCount();
  }

  viewDetails(report: any) {
    this.ngxSmartModalService.resetModalData(this.detailedReportModal);
    this.ngxSmartModalService.setModalData(report, this.detailedReportModal);
    this.ngxSmartModalService.open(this.detailedReportModal);
  }

  //EMMITED EVENT ACTIONS
  onPageChanged({ page, maxPageSize }) {
    this.tableControl.Page = page;
    this.tableControl.MaxPageSize = maxPageSize;

    this.loadReports();
  }

  onFiltered(filter: FilterModel) {
    this.tableControl.Page = 1;
    this.tableControl.Filter = filter;
    this.loadReports();
    this.loadItemCount();
  }

  onSorted(sort: string) {
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1;
    this.loadReports();
  }

}
