import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hitsteps',
  templateUrl: './hitsteps.component.html',
  styleUrls: ['./hitsteps.component.scss']
})
export class HitstepsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}