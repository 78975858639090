export class LookUpProductCategories {
    public ProductTypeId: number;
    public CategoryId: number;
    public ProductTypeName: string;
    public CategoryName: string;
    
    public Clear(){
        this.ProductTypeId = null;
        this.CategoryId = null;
        this.CategoryName = "";
        this.ProductTypeName = "";
    }

    public Construct(prodId: number, catId: number) {
        this.ProductTypeId = prodId;
        this.CategoryId = catId;
    }
}