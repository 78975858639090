export class Distributor {
    public Id: number;
    public DistributorName: string;
    public Company: string;
    public DirectorName: string;
    public TelephoneNo: string;
    public Email: string;
    public Address: string;
    public Website: string;
    public BankAccountNumber: string;
    public BankAccountName: string;
    public PayPalEmail: string;
    public ABN: string;
    public EIN: string;
    public AccountsContact: string;
    public EverydayContactPerson: string;
    public Logo: string;
    public IsRegistered: boolean;
    public Active: boolean;
    public HexColor: string;

    public Clear(){
        this.DistributorName = "";
        this.Company = "";
        this.DirectorName = "";
        this.TelephoneNo = "";
        this.Email = "";
        this.Address = "";
        this.Website = "";
        this.BankAccountNumber = "";
        this.BankAccountName = "";
        this.PayPalEmail = "";
        this.ABN = "";
        this.EIN = "";
        this.AccountsContact = "";
        this.EverydayContactPerson = "";
        this.HexColor = "";
        this.Logo = "";
        this.IsRegistered = null;
        this.Active = null;
    }

    public Construct(object: any){
        this.Id = object.Id;
        this.DistributorName = object.DistributorName;
        this.Company = object.Company;
        this.DirectorName = object.DirectorName;
        this.TelephoneNo = object.TelephoneNo;
        this.Email = object.Email;
        this.Address = object.Address;
        this.Website = object.Website;
        this.BankAccountNumber = object.BankAccountNumber;
        this.BankAccountName = object.BankAccountName;
        this.PayPalEmail = object.PayPalEmail;
        this.ABN = object.ABN;
        this.EIN = object.EIN;
        this.AccountsContact = object.AccountsContact;
        this.EverydayContactPerson = object.EverydayContactPerson;
        this.HexColor = object.HexColor;
        this.Logo = object.Logo;
        this.IsRegistered = object.IsRegistered;
        this.Active = object.Active;
    }
}