import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TableControl } from 'src/app/models/TableControl';
import { FilterModel } from 'src/app/models/FilterModel';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { ConstantsService } from '../../common/constants/constants.service';
import { FreightRate, Charge, CarOceanFreight } from 'src/app/models/FreightRate';


@Injectable({
	providedIn: 'root'
})
export class FreightRateService {
	freightRateSelected = new EventEmitter<FreightRate>();

	private distributorId: number;

	constructor(
		private http: HttpClient,
		private utilities: UtilitiesService,
		private constants: ConstantsService
	) {
		// const currentUser = JSON.parse(localStorage.getItem('_currentUser'));
		// this.distributorId = currentUser.profile.sub;
		this.distributorId = this.constants.DISTRIBUTOR_ID;
	}

	async getFreightRates(tableControl: TableControl): Promise<any> {
		return new Promise((resolve, reject) => {
			let url = this.constants.SERVICE_URL + `api/FreightEngineCustomDistributorRates/GetPaginatedByDistributorId?distributorId=${this.distributorId}`;
			url = this.utilities.applyTableControls(url, tableControl);

			this.http.get(url).subscribe(data => {
				resolve(data);
			}, err => {
				resolve(err);
			});
		});
	}

	async getFreightRateCount(filter: FilterModel): Promise<any> {
		return new Promise((resolve, reject) => {
			let url = this.constants.SERVICE_URL + `api/FreightEngineCustomDistributorRates/GetCountByDistributorId?distributorId=${this.distributorId}`;
      		url = this.utilities.applyTableFilters(url, filter);

			this.http.get(url).subscribe(data => {
				resolve(data);
			}, err => {
				resolve(err);
			});
		});
	}

	async saveFreightRate(freightRate: FreightRate): Promise<any> {
		const body = this.buildJsonBody(freightRate);

		return new Promise((resolve, reject) => {
			const url = this.constants.SERVICE_URL + "api/FreightEngineCustomDistributorRates/Save";
			const headers	= this.utilities.getFormHeadersJson();
			
			this.http.post(url, body, headers).subscribe(data => {
				resolve(data);
			}, err => {
				reject(err);
			});
		});
	}

	async editFreightRate(freightRate: FreightRate): Promise<any> {
		const body = this.buildJsonBody(freightRate);

		return new Promise((resolve, reject) => {
			const url 	= this.constants.SERVICE_URL + 'api/FreightEngineCustomDistributorRates/Save';
			const headers = this.utilities.getFormHeadersJson();

			this.http.post(url, body, headers).subscribe(data => {
				resolve(data);
			}, err => {
				reject(err);
			});
		});
	}

	async deleteFreightRate(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
			const url 	= this.constants.SERVICE_URL + `api/FreightEngineCustomDistributorRates/Delete?id=${id}`;
			const headers = this.utilities.getFormHeadersJson();

			this.http.post(url, null, headers).subscribe(data => {
				resolve(data);
			}, err => {
				reject(err);
			});
		});
	}

	buildJsonBody(freightRate: FreightRate) {
		const charges = [
			...this.buildCharges(freightRate.ExportCharges, 1),
			...this.buildCharges(freightRate.ImportCharges, 2)
		];

		return {
			Id:						freightRate.Id || null,
			//CarrierId:		freightRate.CarrierId,
			OriginCountryId:		freightRate.OriginCountryId,
			DestinationCountryId:	freightRate.DestinationCountryId,
			OriginRegion:			freightRate.OriginId,
			DestinationRegion:		freightRate.DestinationId,
			TypeId: 				freightRate.RateTypeId,
			CurrencyId: 			freightRate.OceanFreight.CurrencyId,
			Rates: 					freightRate.OceanFreight.Amount,
			IncotermFromId:			freightRate.IncotermFromId,
			IncotermToId:			freightRate.IncotermToId,
			DistributorId:			this.distributorId,
			FreightEngineCustomDistributorCharges: charges,
			AllCategories:			freightRate.IsAllCategories ? 1 : 0,
			FreightEngineRateForCar: freightRate.IsAllCategories ? null : CarOceanFreight.ToJson(freightRate.CarOceanFreights),
		};
	}
	
	buildCharges(charges: any, chargeTypeId: number) {
		const newCharges = [];
		
		charges.forEach((c) => {
			newCharges.push({
				Id: c.Id,
				Name: c.Name,
				CurrencyId: c.Denomination.CurrencyId,
				Rates: c.Denomination.Amount,
				ChargeTypeId: chargeTypeId
			});
		});

		return newCharges;
	}
}
