import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ProductTypeService } from 'src/app/services/http/product-type/product-type.service';

@Component({
  selector: 'app-delete-product-type',
  templateUrl: './delete-product-type.component.html',
  styleUrls: ['./delete-product-type.component.scss']
})
export class DeleteProductTypeComponent implements OnInit {
  @Output() productDeleted = new EventEmitter();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private productTypeService: ProductTypeService) { }

  ngOnInit() {
  }

  async deleteProductTypes(productTypeId: number) {
    this.spinner.show("fullScreenSpinner");
    await this.productTypeService.deleteProductType(productTypeId);
    this.spinner.hide("fullScreenSpinner");

    this.ngxSmartModalService.getModal('deleteProductType').close()
    this.productDeleted.emit();
  }
}
