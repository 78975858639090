import { Injectable } from '@angular/core';
import { RateType } from 'src/app/models/RateType';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../common/constants/constants.service';

@Injectable({
  providedIn: 'root'
})

export class RateTypeService {
  // private rateTypes: RateType[];
  constructor(private http: HttpClient,
    private constants: ConstantsService) { 
    // this.rateTypes = [
    //   new RateType(1, 'Self-propelled'),
    //   new RateType(2, 'Towable'),
    //   new RateType(3, 'Static'),
    //   new RateType(4, 'FCL'),
    //   new RateType(5, 'LCL'),
    // ]
  }

  // async getRateTypes(): Promise<RateType[]> {
  //   return this.rateTypes;
  // }

  
  async getRateTypes(): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + "/api/FreightEngineRateTypes/Get";

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

}
