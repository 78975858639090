import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MakeModelService } from 'src/app/services/http/make-model/make-model.service';

@Component({
  selector: 'app-delete-make-model',
  templateUrl: './delete-make-model.component.html',
  styleUrls: ['./delete-make-model.component.scss']
})
export class DeleteMakeModelComponent implements OnInit {

  @Output() makeModelDeleted = new EventEmitter();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private makeModelService: MakeModelService) { }

  ngOnInit() {
  }

  async deleteMakeModels(makeModelId: number) {
    this.spinner.show("fullScreenSpinner");
    await this.makeModelService.deleteMakeModel(makeModelId);
    this.spinner.hide("fullScreenSpinner");

    this.ngxSmartModalService.getModal('deleteMakeModel').close()
    this.makeModelDeleted.emit();
  }
}
