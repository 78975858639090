import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterModel } from 'src/app/models/FilterModel';
import { FilterOption } from 'src/app/models/FilterOption';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';
import { SailingScheduleService } from 'src/app/services/http/sailing-schedule/sailing-schedule.service';
import { VesselService } from 'src/app/services/http/vessel/vessel.service';
import { CountryService } from 'src/app/services/http/country/country.service';
import { EditSailingScheduleComponent } from '../edit-sailing-schedule/edit-sailing-schedule.component';

@Component({
	selector: 'app-view-sailing-schedules',
	templateUrl: './view-sailing-schedules.component.html',
	styleUrls: ['./view-sailing-schedules.component.scss'],
})
export class ViewSailingSchedulesComponent implements OnInit {

	@ViewChild(EditSailingScheduleComponent, { static: false }) editSchedule: EditSailingScheduleComponent;

	//Modal Identifier Constants
	private addSailingScheduleModal: string 	= 'addSailingSchedule';
	private editSailingScheduleModal: string 	= 'editSailingSchedule';
	private disableSailingScheduleModal: string = 'disableSailingSchedule';

	//Table Variables
	public tableControl: TableControl;
	public filterOptions: FilterOption[];

	//Local Variables
	public sailingSchedules: any;
	public vessels: any;
	public countries: any;

	constructor(
		private sailingScheduleService: SailingScheduleService,
		private vesselService: VesselService,
		private countryService: CountryService,
		private spinner: NgxSpinnerService,
		public ngxSmartModalService: NgxSmartModalService,
		private toasterService: ToastrService
	) {
		this.filterOptions = [
			new FilterOption('Id', 'Id'),
			new FilterOption('Vessel', 'Vessel'),
			new FilterOption('VoyageNumber', 'Voyage Number'),
			new FilterOption('OriginCountry', 'Origin Country'),
			new FilterOption('OriginPort', 'Origin Port'),
			new FilterOption('DestinationCountry', 'Destination Country'),
			new FilterOption('DestinationPort', 'Destination Port'),
			new FilterOption('ETD', 'ETD'),
			new FilterOption('ETA', 'ETA'),
			new FilterOption('Created', 'Modified On'),
			new FilterOption('CreatedByName', 'Modified By')
		];
		this.tableControl = new TableControl(10);
	}

	async ngOnInit() {
		this.spinner.show('fullScreenSpinner');
		await this.loadItemCount();
		await this.loadVessels();
		await this.loadCountries();
		this.spinner.hide('fullScreenSpinner');
		await this.loadSailingSchedules();
	}

	private async loadSailingSchedules() {
		this.spinner.show('tableSpinner');
		this.sailingSchedules = await this.sailingScheduleService.getSailingSchedules(this.tableControl);
		this.tableControl.CurrentItemCount = this.sailingSchedules.length;
		this.spinner.hide('tableSpinner');
	}

	private async loadItemCount() {
		this.tableControl.TotalItemCount = await this.sailingScheduleService.getSailingSchedulesCount(this.tableControl.Filter);
	}

	private async loadVessels() {
		this.vessels = await this.vesselService.getAllVessels();
	}

	private async loadCountries() {
		this.countries = await this.countryService.getCountries();
	}

	addSailingSchedule() {
		this.ngxSmartModalService.open(this.addSailingScheduleModal);
	}

	editSailingSchedule(sailingSchedule: any) {
		this.editSchedule.loadOriginPorts(sailingSchedule.OriginCountryId);
		this.editSchedule.loadDestinationPorts(sailingSchedule.DestinationCountryId);
		this.editSchedule.loadVoyages(sailingSchedule.VesselId);

		this.ngxSmartModalService.resetModalData(this.editSailingScheduleModal);
		this.ngxSmartModalService.setModalData(sailingSchedule, this.editSailingScheduleModal);
		this.ngxSmartModalService.open(this.editSailingScheduleModal);
	}

	disableSailingSchedule(sailingSchedule: any) {
		this.ngxSmartModalService.resetModalData(this.disableSailingScheduleModal);
		this.ngxSmartModalService.setModalData(sailingSchedule, this.disableSailingScheduleModal);
		this.ngxSmartModalService.open(this.disableSailingScheduleModal);
	}

	//EMMITED EVENT ACTIONS
	onSailingScheduleAdded() {
		this.tableControl.Page = 1;
		this.loadItemCount();
		this.loadSailingSchedules();
		this.toasterService.success('Sailing Schedule successfully Added.', 'Add Sailing Schedule');
	}

	onSailingScheduleEdited() {
		this.loadSailingSchedules();
		this.toasterService.success('Sailing Schedule successfully Edited.', 'Edit Sailing Schedule');
	}

	onSailingScheduleDisabled() {
		this.loadItemCount();
		this.loadSailingSchedules();
		this.toasterService.success('Sailing Schedule successfully Deleted.', 'Delete Sailing Schedule');
	}

	onPageChanged({ page, maxPageSize }) {
		this.tableControl.Page = page;
		this.tableControl.MaxPageSize = maxPageSize;
		this.loadSailingSchedules();
	}

	onFiltered(filter: FilterModel) {
		this.tableControl.Page = 1;
		this.tableControl.Filter = filter;
		this.loadSailingSchedules();
		this.loadItemCount();
	}

	onSorted(sort: string) {
		this.tableControl.Sorting = sort;
		this.tableControl.Page = 1;
		this.loadSailingSchedules();
	}
}
