import { Component, OnInit, OnDestroy } from '@angular/core';
import { InsuranceCertificateService } from 'src/app/services/http/insurance-certificate/insurance-certificate.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterOption } from 'src/app/models/FilterOption';
import { FilterModel } from 'src/app/models/FilterModel';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';

@Component({
  selector: 'app-view-premium-distribution-payable',
  templateUrl: './view-premium-distribution.component.html',
  styleUrls: ['./view-premium-distribution.component.scss']
})
export class ViewPremiumDistributionComponent implements OnInit, OnDestroy {
  //Modal Identifier Constants
  detailedPremiumDistributionModal: string = "detailedPremiumDistribution";

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];
  
  //Local Variables
  premiumDistributions: any;
  isSyncing = false;
  syncPoll: any;
  loadedOngoingSync = false;
  progressRate = 0;

  constructor(private insuranceCertificatesService: InsuranceCertificateService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService : ToastrService) { 
      this.filterOptions = [
        new FilterOption("CertificatesId", "Policy No"),
        new FilterOption("AssuredBy", "Name of Insured"),
        new FilterOption("CreatedDTString", "Certificate Date"),
        new FilterOption("Currency", "Currency"),
        new FilterOption("PBRChubb", "Chubb"),
        new FilterOption("PBRHonan", "Honan"),
        new FilterOption("PBRmyCargo", "myCargo"),
        new FilterOption("Total", "Total"),
        new FilterOption("PBRDueToHonan", "Due to Honan")
      ];

      this.tableControl = new TableControl(10);
    }

  async ngOnInit() {
    this.spinner.show("fullScreenSpinner");
    await this.getItemCount();
    this.spinner.hide("fullScreenSpinner");

    await this.loadInsuranceCertificates();
  }

  async ngOnDestroy() {
    clearInterval(this.syncPoll);
  }

  private async loadInsuranceCertificates(){
    this.spinner.show("tableSpinner");
    this.premiumDistributions = await this.getInsuranceCertificates();
    this.tableControl.CurrentItemCount = this.premiumDistributions.length;
    this.spinner.hide("tableSpinner");
  }

  private async getInsuranceCertificates(){
    return this.insuranceCertificatesService.getInsuranceCertificates(this.tableControl);
  }

  private async getItemCount(){
    this.tableControl.TotalItemCount = await this.insuranceCertificatesService.getInsuranceCertificateCount(this.tableControl.Filter);
  }

  viewDetails(insuranceCertificate: any){
    this.ngxSmartModalService.resetModalData(this.detailedPremiumDistributionModal);
    this.ngxSmartModalService.setModalData(insuranceCertificate, this.detailedPremiumDistributionModal);
    this.ngxSmartModalService.open(this.detailedPremiumDistributionModal);
  }

  //EMMITED EVENT ACTIONS

  onPageChanged({ page, maxPageSize }){
    this.tableControl.Page = page;
    this.tableControl.MaxPageSize = maxPageSize;

    this.loadInsuranceCertificates();
  }

  onFiltered(filter : FilterModel){
    this.tableControl.Page = 1; 
    this.tableControl.Filter = filter;
    this.getItemCount();
    this.loadInsuranceCertificates();
  }

  onSorted(sort: string){
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1; 
    this.loadInsuranceCertificates();
  }

}
