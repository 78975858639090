import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-previewer',
  templateUrl: './image-previewer.component.html',
  styleUrls: ['./image-previewer.component.scss']
})
export class ImagePreviewerComponent implements OnInit {
  constructor() {}

  ngOnInit() { }
}
