import { Component, OnInit, OnDestroy } from '@angular/core';
import { ManufacturerService } from 'src/app/services/http/manufacturer/manufacturer.service';
import { DistributorService } from 'src/app/services/http/distributor/distributor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterOption } from 'src/app/models/FilterOption';
import { FilterModel } from 'src/app/models/FilterModel';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';
import { UtilitiesService } from 'src/app/services/common/utilities/utilities.service';
import { ConstantsService } from 'src/app/services/common/constants/constants.service';
import { Manufacturer } from 'src/app/models/Manufacturer';
import { TimeInterval } from 'rxjs/internal/operators/timeInterval';

@Component({
  selector: 'app-view-manufacturers',
  templateUrl: './view-manufacturers.component.html',
  styleUrls: ['./view-manufacturers.component.scss']
})
export class ViewManufacturersComponent implements OnInit, OnDestroy {
  //Modal Identifier Constants
  addManufacturerModal: string = "addManufacturer";
  editManufacturerModal: string = "editManufacturer";
  deleteManufacturerModal: string = "deleteManufacturer";

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];
  
  //Local Variables
  manufacturers: any;
  isSyncing = false;
  syncPoll: any;
  progressRate = 0;
  loadedOngoingSync = false;

  constructor(private manufacturerService: ManufacturerService,
    private distributorService: DistributorService,
    private spinner: NgxSpinnerService,
    private utilities: UtilitiesService,
    private constants: ConstantsService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService : ToastrService) { 
      this.filterOptions = [
        new FilterOption("Id", "Id"),
        new FilterOption("Word", "Manufacturer"),
        new FilterOption("Created", "Modified On"),
        new FilterOption("CreatedBy", "Modified By"),
      ];

      this.tableControl = new TableControl(10);
    }

  async ngOnInit() {
    this.spinner.show("fullScreenSpinner");
    await this.getItemCount();
    this.spinner.hide("fullScreenSpinner");

    await this.loadManufacturers();
    
    if (await this.getSyncStatus())
      await this.initializeSyncPolling();
  }

  async ngOnDestroy() {
    clearInterval(this.syncPoll);
  }

  private async loadManufacturers(){
    this.spinner.show("tableSpinner");
    this.manufacturers = await this.getManufacturers();
    this.tableControl.CurrentItemCount = this.manufacturers.length;
    this.spinner.hide("tableSpinner");
  }

  private async getManufacturers(){
    return this.manufacturerService.getManufacturers(this.tableControl);
  }

  private async getItemCount(){
    this.tableControl.TotalItemCount = await this.manufacturerService.getManufacturerCount(this.tableControl.Filter);
  }
  
  async syncManufacturers() {
    let result = await this.manufacturerService.syncAll();

    this.isSyncing = result && result.syncId;

    if (this.isSyncing)
      this.initializeSyncPolling();
  }

  private async getSyncStatus(): Promise<any> {
    return await this.manufacturerService.getSyncStatus();
  }

  async initializeSyncPolling() {
    this.isSyncing = true;

    clearInterval(this.syncPoll);

    this.syncPoll = setInterval(() => {
      this.getSyncStatus().then(result => {
        this.isSyncing = !!result.syncId;
        
        this.progressRate = parseFloat((result.progress * 100).toFixed(2));

        if (this.isSyncing)
          this.loadedOngoingSync = true;

        if (!this.isSyncing && this.syncPoll) {
          clearInterval(this.syncPoll);
          this.syncPoll = null;

          if (this.loadedOngoingSync) {
            this.loadedOngoingSync = false;
            this.toasterService.success("Syncronisation was completed.", "Sync Manufacturers");
          }
        }

      });
    }, 2000);
  }

  addManufacturer(){
    this.openManufacturer();
  }

  editManufacturer(manufacturer: any){
    this.openManufacturer(manufacturer);
  }

  openManufacturer(manufacturer: any = null) {
    this.ngxSmartModalService.resetModalData(this.editManufacturerModal);

    let manufacturerForm = new Manufacturer();

    if (manufacturer)
      manufacturerForm.Construct(manufacturer.Id, manufacturer.Word);
    
     this.ngxSmartModalService.setModalData(manufacturerForm, this.editManufacturerModal);
    
    this.ngxSmartModalService.open(this.editManufacturerModal);
  }

  deleteManufacturer(manufacturer: any){
    this.ngxSmartModalService.resetModalData(this.deleteManufacturerModal);
    this.ngxSmartModalService.setModalData(manufacturer, this.deleteManufacturerModal);
    this.ngxSmartModalService.open(this.deleteManufacturerModal);
  }

  importManufacturers() {
   this.ngxSmartModalService.open("importManufacturers"); 
  }

  //EMMITED EVENT ACTIONS
  onManufacturerAdded(){
    this.tableControl.Page = 1; 
    this.getItemCount();
    this.loadManufacturers();
    this.toasterService.success("Manufacturer successfully Added.", "Add Manufacturer");
  }

  onManufacturerEdited(){
    this.loadManufacturers();
    this.toasterService.success("Manufacturer successfully Edited.", "Edit Manufacturer");
  }

  onManufacturerDeleted(){
    this.getItemCount();
    this.loadManufacturers();
    this.toasterService.success("Manufacturer successfully Deleted.", "Delete Manufacturer");
  }

  onManufacturersImported() {
    this.getItemCount();
    this.loadManufacturers();
    this.toasterService.success("Manufacturers successfully Imported.", "Import Manufacturers");
  }

  onPageChanged({ page, maxPageSize }){
    this.tableControl.Page = page; 
    this.tableControl.MaxPageSize = maxPageSize;
    
    this.loadManufacturers();
  }

  onFiltered(filter : FilterModel){
    this.tableControl.Page = 1; 
    this.tableControl.Filter = filter;
    this.getItemCount();
    this.loadManufacturers();
  }

  onSorted(sort: string){
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1; 
    this.loadManufacturers();
  }
}
