import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ProductTypeService } from 'src/app/services/http/product-type/product-type.service';

@Component({
  selector: 'app-disable-product-type',
  templateUrl: './disable-product-type.component.html',
  styleUrls: ['./disable-product-type.component.scss']
})
export class DisableProductTypeComponent implements OnInit {
  @Output() productDisabled = new EventEmitter();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private productTypeService: ProductTypeService) { }

  ngOnInit() {
  }

  async disableProductTypes(productTypeId) {
    this.spinner.show("fullScreenSpinner");
    await this.productTypeService.disableProductType(productTypeId);
    this.spinner.hide("fullScreenSpinner");

    this.ngxSmartModalService.getModal('disableProductType').close()
    this.productDisabled.emit();
  }
}
