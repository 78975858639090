import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFilter'
})
export class ArrayFilterPipe implements PipeTransform {

  transform(arr: any, searchType: string, searchValue: any): any {
    if (!searchValue) 
      return arr;

    return arr.find(f => f[searchType] === searchValue);
  }
}
