import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterOption } from 'src/app/models/FilterOption';
import { FilterModel } from 'src/app/models/FilterModel';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';
import { UserService } from 'src/app/services/http/user/user.service';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { ChildUser } from 'src/app/models/ChildUser';

@Component({
  selector: 'app-view-user-profile',
  templateUrl: './view-user-profile.component.html',
  styleUrls: ['./view-user-profile.component.scss']
})
export class ViewUserProfileComponent implements OnInit, OnDestroy {

  //Modal Identifier Constants
  addUserProfileModal: string = 'addUserProfile';
  editUserProfileModal: string = 'editUserProfile';
  deleteUserProfileModal: string = 'aeleteUserProfile';

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];

  //Local Variables
  childUsers: any;

  syncPoll: any;

  constructor(private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService : ToastrService,
    private userService : UserService) { 

      this.filterOptions = [
        new FilterOption('Id', 'Id'),
        new FilterOption('ParentUserId', 'Parent Id'),
        new FilterOption('ChildUserId', 'Child Id'),
        new FilterOption('Email', 'Child User Email'),
      ];

      this.tableControl = new TableControl(10);
    }

  async ngOnInit() {
    this.spinner.show("fullScreenSpinner");
    await this.getItemCount();
    this.spinner.hide("fullScreenSpinner");

    await this.loadChildUser();
  }

  ngOnDestroy(): void {
    clearInterval(this.syncPoll);
  }

  addUserProfile(){
    this.openEditUserProfile();
  }

  editUserProfile(user: any){
    this.openEditUserProfile(user);
  }

  deleteUserProfile(distributor: any){
    //this.ngxSmartModalService.resetModalData(this.deleteDistributorModal);
    //this.ngxSmartModalService.setModalData(distributor, this.deleteDistributorModal);
    //this.ngxSmartModalService.open(this.deleteDistributorModal);
  }
  

  openEditUserProfile(childUser: any = null) {
    this.ngxSmartModalService.resetModalData(this.editUserProfileModal);

    let childUserForm = new ChildUser();

    if (childUser)
      childUserForm.Construct({
        ParentUserId: childUser.ParentUserId,
        ChildUserId: childUser.ChildUserId,
        Email: childUser.ChildUserEmail
      });
    
    this.ngxSmartModalService.setModalData(childUser, this.editUserProfileModal);

    this.ngxSmartModalService.open(this.editUserProfileModal);
  }

  private async loadChildUser(){
    this.spinner.show("tableSpinner");
    this.childUsers = await this.getChildUser();
    this.tableControl.CurrentItemCount = this.childUsers.length;
    this.spinner.hide("tableSpinner");
  }

  private async getChildUser(){
    return this.userService.getChildUser(this.tableControl);
  }
  
  private async getItemCount(){
    this.tableControl.TotalItemCount = await this.userService.getChildUserCount(this.tableControl.Filter);
  }


  //EMMITED EVENT ACTIONS
  onUserProfileAdded(){
    this.tableControl.Page = 1; 
    this.getItemCount();
    this.loadChildUser();
    this.toasterService.success("User Profile successfully Added.", "Add User Profile");
  }

  onUserProfileEdited(){
    this.loadChildUser();
    this.toasterService.success("User Profile successfully Edited.", "Edit User Profile");
  }

  onUserProfileDeleted(){
    this.getItemCount();
    this.loadChildUser();
    this.toasterService.success("User Profile successfully Deleted.", "Delete User Profile");
  }

  onUserProfileImported(){
    this.getItemCount();
    this.loadChildUser();
    this.toasterService.success("User Profile successfully Imported.", "Import User Profile");
  }

  onPageChanged({ page, maxPageSize }){
    this.tableControl.Page = page;
    this.tableControl.MaxPageSize = maxPageSize;

    this.loadChildUser();
  }

  onFiltered(filter : FilterModel){
    this.tableControl.Page = 1; 
    this.tableControl.Filter = filter;
    this.getItemCount();
    this.loadChildUser();
  }

  onSorted(sort: string){
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1; 
    this.loadChildUser();
  }

}
