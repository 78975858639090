import { Injectable } from '@angular/core';
import { Incoterm } from 'src/app/models/Incoterm';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../common/constants/constants.service';

@Injectable({
  providedIn: 'root'
})
export class IncotermService {
  incoterms: Incoterm[];

  constructor(private http: HttpClient,
    private constants: ConstantsService) {
      this.incoterms = [
        new Incoterm(1, 'FAS', 1),
        new Incoterm(2, 'FOB', 1),
        new Incoterm(3, 'CFR', 2),
        new Incoterm(4, 'CIF', 2),
      ];
  }

  async getIncoterms() {
    return {
      From: await this.getIncotermsFrom(),
      To: await this.getIncotermsFrom()
    };
    
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/FreightEngineRateIncoterms/Get";

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getIncotermsFrom(): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + "api/FreightEngineRateIncotermFrom/Get";

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getIncotermsTo() {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/FreightEngineRateIncotermTo/Get";

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }
}
