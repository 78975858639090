import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgSelectModule } from '@ng-select/ng-select';

//LIBRARY MODULES
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { ToastrModule } from 'ngx-toastr';

//LAYOUT COMPONENTS
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { MenuComponent } from './components/layout/menu/menu.component';
import { SettingsComponent } from './components/layout/settings/settings.component';

//PAGE COMPONENTS
import { ViewProductTypesComponent } from './components/pages/product-types/view-product-types/view-product-types.component';
import { PaginationComponent } from './components/shared/pagination/pagination.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { SearchFilterComponent } from './components/shared/search-filter/search-filter.component';
import { DetailedProductTypeComponent } from './components/pages/product-types/detailed-product-type/detailed-product-type.component';
import { EditProductTypeComponent } from './components/pages/product-types/edit-product-type/edit-product-type.component';
import { DisableProductTypeComponent } from './components/pages/product-types/disable-product-type/disable-product-type.component';
import { ArrayFilterPipe } from './services/pipes/array-filter/array-filter.pipe';
import { TableSorterComponent } from './components/shared/table-sorter/table-sorter.component';
import { ViewInsuranceQuoteReportsComponent } from './components/pages/reports/insurance-quotes/view-insurance-quote-reports/view-insurance-quote-reports.component';
import { DetailedInsuranceQuoteReportsComponent } from './components/pages/reports/insurance-quotes/detailed-insurance-quote-reports/detailed-insurance-quote-reports.component';
import { ViewInsuranceCertificateReportsComponent } from './components/pages/reports/insurance-certificates/view-insurance-certificate-reports/view-insurance-certificate-reports.component';
import { DetailedInsuranceCertificateReportsComponent } from './components/pages/reports/insurance-certificates/detailed-insurance-certificate-reports/detailed-insurance-certificate-reports.component';
import { ViewVesselsComponent } from './components/pages/vessels/view-vessels/view-vessels.component';
import { AddVesselComponent } from './components/pages/vessels/add-vessel/add-vessel.component';
import { DisableVesselComponent } from './components/pages/vessels/disable-vessel/disable-vessel.component';
import { EditVesselComponent } from './components/pages/vessels/edit-vessel/edit-vessel.component';
import { ViewVoyageComponent } from './components/pages/voyages/view-voyage/view-voyage.component';
import { EditVoyageComponent } from './components/pages/voyages/edit-voyage/edit-voyage.component';
import { DisableVoyageComponent } from './components/pages/voyages/disable-voyage/disable-voyage.component';
import { AddVoyageComponent } from './components/pages/voyages/add-voyage/add-voyage.component';
import { ViewSailingSchedulesComponent } from './components/pages/sailing-schedules/view-sailing-schedules/view-sailing-schedules.component';
import { AddSailingScheduleComponent } from './components/pages/sailing-schedules/add-sailing-schedule/add-sailing-schedule.component';
import { EditSailingScheduleComponent } from './components/pages/sailing-schedules/edit-sailing-schedule/edit-sailing-schedule.component';
import { DisableSailingScheduleComponent } from './components/pages/sailing-schedules/disable-sailing-schedule/disable-sailing-schedule.component';
import { EditDistributorComponent } from './components/pages/administration/distributors/edit-distributor/edit-distributor.component';
import { ViewDistributorsComponent } from './components/pages/administration/distributors/view-distributors/view-distributors.component';
import { DeleteDistributorComponent } from './components/pages/administration/distributors/delete-distributor/delete-distributor.component';
import { ChangeUserPasswordComponent } from './components/pages/administration/users/change-user-password/change-user-password.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { HeaderBarComponent } from './components/layout/header-bar/header-bar.component';
import { AvatarMakerComponent } from './components/shared/avatar-maker/avatar-maker.component';
import { CardComponent } from './components/shared/card/card.component';
import { CardTitleComponent } from './components/shared/card/card-title/card-title.component';
import { CardActionBarComponent } from './components/shared/card/card-action-bar/card-action-bar.component';
import { FooterBarComponent } from './components/layout/footer-bar/footer-bar.component';
import { ViewFreightRatesComponent } from './components/pages/freight-rates/view-freight-rates/view-freight-rates.component';
import { EditFreightRateComponent } from './components/pages/freight-rates/edit-freight-rate/edit-freight-rate.component';
import { TwoDigitDecimaNumberDirective } from './directives/two-digit-decimal-number.directive';
import { DeleteFreightRateComponent } from './components/pages/freight-rates/delete-freight-rate/delete-freight-rate.component';
import { ViewManufacturersComponent } from './components/pages/manufacturers/view-manufacturers/view-manufacturers.component';
import { EditManufacturerComponent } from './components/pages/manufacturers/edit-manufacturer/edit-manufacturer.component';
import { ViewMakeModelsComponent } from './components/pages/make-models/view-make-models/view-make-models.component';
import { EditMakeModelComponent } from './components/pages/make-models/edit-make-model/edit-make-model.component';
import { ViewConfigurationsComponent } from './components/pages/configurations/view-configurations/view-configurations.component';
import { EditConfigurationComponent } from './components/pages/configurations/edit-configuration/edit-configuration.component';
import { ViewProductConfigurationsComponent } from './components/pages/product-configurations/view-product-configurations/view-product-configurations.component';
import { EditProductConfigurationComponent } from './components/pages/product-configurations/edit-product-configuration/edit-product-configuration.component';
import { DeleteProductTypeComponent } from './components/pages/product-types/delete-product-type/delete-product-type.component';
import { DeleteManufacturerComponent } from './components/pages/manufacturers/delete-manufacturer/delete-manufacturer.component';
import { DeleteMakeModelComponent } from './components/pages/make-models/delete-make-model/delete-make-model.component';
import { DeleteConfigurationComponent } from './components/pages/configurations/delete-configuration/delete-configuration.component';
import { DeleteProductConfigurationComponent } from './components/pages/product-configurations/delete-product-configuration/delete-product-configuration.component';
import { ImportProductTypeComponent } from './components/pages/product-types/import-product-type/import-product-type.component';
import { ImportManufacturersComponent } from './components/pages/manufacturers/import-manufacturers/import-manufacturers.component';
import { ImportConfigurationsComponent } from './components/pages/configurations/import-configurations/import-configurations.component';
import { ImportMakeModelsComponent } from './components/pages/make-models/import-make-models/import-make-models.component';
import { ImportProductConfigurationsComponent } from './components/pages/product-configurations/import-product-configurations/import-product-configurations.component';
import { ViewCarriersComponent } from './components/pages/carriers/view-carriers/view-carriers.component';
import { AddCarrierComponent } from './components/pages/carriers/add-carrier/add-carrier.component';
import { EditCarrierComponent } from './components/pages/carriers/edit-carrier/edit-carrier.component';
import { DeleteCarrierComponent } from './components/pages/carriers/delete-carrier/delete-carrier.component';
import { ImagePreviewerComponent } from './components/shared/image-previewer/image-previewer.component';
import { HitstepsComponent } from './components/shared/snippet/hitsteps/hitsteps.component';
import { ViewBordereauComponent } from './components/pages/marsh/bordereau/view-bordereau/view-bordereau.component';
import { DetailedBordereauComponent } from './components/pages/marsh/bordereau/detailed-bordereau/detailed-bordereau.component';
import { DetailedCommissionPayableComponent } from './components/pages/marsh/commission-payable/detailed-commission-payable/detailed-commission-payable.component';
import { ViewCommissionPayableComponent } from './components/pages/marsh/commission-payable/view-commission-payable/view-commission-payable.component';
import { ViewPremiumDistributionComponent } from './components/pages/marsh/premium-distribution/view-premium-distribution/view-premium-distribution.component';
import { DetailedPremiumDistributionComponent } from './components/pages/marsh/premium-distribution/detailed-premium-distribution/detailed-premium-distribution.component';
import { EditAccountDistributorComponent } from './components/pages/administration/distributors/edit-account-distributor/edit-account-distributor.component';
import { ViewDistributorFeesComponent } from './components/pages/administration/distributor-fees/view-distributor-fees/view-distributor-fees.component';
import { EditDistributorFeeComponent } from './components/pages/administration/distributor-fees/edit-distributor-fee/edit-distributor-fee.component';
import { DeleteDistributorFeeComponent } from './components/pages/administration/distributor-fees/delete-distributor-fee/delete-distributor-fee.component';
//import { MarginComponent } from './components/pages/administration/margin/margin/margin.component';
import { ViewUserProfileComponent } from './components/pages/administration/users/view-user-profile/view-user-profile.component';
import { EditUserProfileComponent } from './components/pages/administration/users/edit-user-profile/edit-user-profile.component';
import { DeleteUserProfileComponent } from './components/pages/administration/users/delete-user-profile/delete-user-profile.component';

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		MenuComponent,
		SettingsComponent,
		ViewProductTypesComponent,
		PaginationComponent,
		DashboardComponent,
		SearchFilterComponent,
		DetailedProductTypeComponent,
		EditProductTypeComponent,
		DisableProductTypeComponent,
		ArrayFilterPipe,
		TableSorterComponent,
		ViewInsuranceQuoteReportsComponent,
		DetailedInsuranceQuoteReportsComponent,
		ViewInsuranceCertificateReportsComponent,
		DetailedInsuranceCertificateReportsComponent,
		ViewVesselsComponent,
		AddVesselComponent,
		DisableVesselComponent,
		EditVesselComponent,
		ViewVoyageComponent,
		EditVoyageComponent,
		DisableVoyageComponent,
		AddVoyageComponent,
		ViewSailingSchedulesComponent,
		AddSailingScheduleComponent,
		EditSailingScheduleComponent,
		DisableSailingScheduleComponent,
		DeleteDistributorComponent,
		EditDistributorComponent,
		ViewDistributorsComponent,
		ChangeUserPasswordComponent,
		SidebarComponent,
		HeaderBarComponent,
		AvatarMakerComponent,
		CardComponent,
		CardTitleComponent,
		CardActionBarComponent,
		FooterBarComponent,
		ViewFreightRatesComponent,
		EditFreightRateComponent,
		DeleteFreightRateComponent,
		TwoDigitDecimaNumberDirective,
		ViewManufacturersComponent,
		EditManufacturerComponent,
		ViewMakeModelsComponent,
		EditMakeModelComponent,
		ViewConfigurationsComponent,
		EditConfigurationComponent,
		ViewProductConfigurationsComponent,
		EditProductConfigurationComponent,
		DeleteProductTypeComponent,
		DeleteManufacturerComponent,
		DeleteMakeModelComponent,
		DeleteConfigurationComponent,
		DeleteProductConfigurationComponent,
		ImportProductTypeComponent,
		ImportManufacturersComponent,
		ImportConfigurationsComponent,
		ImportMakeModelsComponent,
		ImportProductConfigurationsComponent,
		ViewCarriersComponent,
		AddCarrierComponent,
		EditCarrierComponent,
		DeleteCarrierComponent,
		ImagePreviewerComponent,
		HitstepsComponent,
		ViewBordereauComponent,
		DetailedBordereauComponent,
		DetailedCommissionPayableComponent,
		ViewCommissionPayableComponent,
		ViewPremiumDistributionComponent,
		DetailedPremiumDistributionComponent,
		EditAccountDistributorComponent,
		ViewDistributorFeesComponent,
		EditDistributorFeeComponent,
		DeleteDistributorFeeComponent,
		//MarginComponent,
		ViewUserProfileComponent,
		EditUserProfileComponent,
		DeleteUserProfileComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		HttpClientModule,
		NgSelectModule,
		NgxSpinnerModule,
		NgxPaginationModule,
		NgxSmartModalModule.forRoot(),
		ToastrModule.forRoot()
	],
	providers: [],
	bootstrap: [
		AppComponent,
		HitstepsComponent,
	]
})
export class AppModule { }
