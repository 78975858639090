import { FilterModel } from './FilterModel';

export class TableControl {
    public Page: number;
    public MaxPageSize: number;
    public CurrentItemCount: number;
    public TotalItemCount: number;
    public Filter: FilterModel;
    public Sorting: string;

    constructor(maxPageSize: number) {
        this.Page = 1;
        this.MaxPageSize = maxPageSize;
        this.Sorting = "";
        this.Filter = new FilterModel("","");
    }
}