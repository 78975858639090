import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FreightRateService } from 'src/app/services/http/freight-rate/freight-rate.service';

@Component({
	selector: 'app-delete-freight-rate',
	templateUrl: './delete-freight-rate.component.html',
	styleUrls: ['./delete-freight-rate.component.scss']
})
export class DeleteFreightRateComponent implements OnInit {

	@Output() freightRateDeleted = new EventEmitter();

	constructor(
		private spinner: NgxSpinnerService,
		private ngxSmartModalService: NgxSmartModalService,
		private freightRateService: FreightRateService
	) {

	}

	ngOnInit() {

	}

	async deleteFreightRates(id) {

		this.spinner.show('fullScreenSpinner');
		await this.freightRateService.deleteFreightRate(id);
		this.spinner.hide('fullScreenSpinner');

		this.ngxSmartModalService.getModal('deleteFreightRate').close();
		this.freightRateDeleted.emit();
	}
}
