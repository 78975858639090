export class Incoterm {
    Id: number;
    Incoterm: string;
    TypeId: number;

    constructor(id: number, incoterm: string, typeId: number)
    {
        this.Id = id;
        this.Incoterm = incoterm;
        this.TypeId = typeId;
    }
}