import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductTypeService } from 'src/app/services/http/product-type/product-type.service';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ProductType } from 'src/app/models/ProductType';
import { CategoryService } from 'src/app/services/http/category/category.service';
import { FilterModel } from 'src/app/models/FilterModel';
import { LookUpProductCategories } from 'src/app/models/LookUpProductCategories';
import { LookUp } from 'src/app/models/LookUp';

@Component({
  selector: 'app-edit-product-type',
  templateUrl: './edit-product-type.component.html',
  styleUrls: ['./edit-product-type.component.scss']
})
export class EditProductTypeComponent implements OnInit {
  @Output() productEdited = new EventEmitter();
  @Output() productAdded = new EventEmitter();
	@ViewChild('EditProductType', {static: false}) editProductType: NgxSmartModalComponent;

  form: FormGroup;
  
  fullScreenSpinner = 'fullScreenSpinner';
  isSubmitted: any;
  modalTitle: string;

  numberOfItemsFromEndBeforeFetchingMore = 10;
  bufferSize = 50;
  categoryBuffer = {};
  categoryBufferArr: {Id: number, Name:string}[] = [];
  categoryLength = 12;
  categoryPageIndex = 0;

  categoryLoading = false;
  categoryInputLength = 0;
  categoryInput = '';
  categorySearchTimer: any;

  constructor(private spinnerService: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private productTypeService: ProductTypeService,
    private categoryService: CategoryService,) { }

  ngOnInit() {

    this.fetchMoreCategory();
    
    this.form = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      categoryId: new FormControl(null, Validators.required)
    });
  }

  get id() { return this.form.controls['id']; }
  get name() { return this.form.controls['name']; }
  get categoryId() { return this.form.controls['categoryId']; }

  async selectCategoryDropdownItem(name: string) {
    this.categoryLoading = true;
    this.categoryInput = name || '';

    if (this.categoryLength){
      this.categoryBufferArr = [];
      this.categoryPageIndex = 0;

      await this.fetchMoreCategory();

      this.categoryInputLength = this.categoryBufferArr.length;
      this.categoryLength = this.categoryBufferArr.length;
    }

    this.categoryLoading = false;
  }

  async onDataAdded() {
    this.isSubmitted = false;
    let data = this.editProductType.getData() as ProductType;

    if (!data) return;

    if (data.Id == null) {
      this.form.reset();
      this.refreshTitle();
      return;
    }
    
    this.fetchMoreCategory();
    let newCategory : any; 
    newCategory =  await this.categoryService.GetCategoryByProductTypeId(data.Id);

    this.form.setValue({
      id: data.Id,
      name: data.Name,
      categoryId: newCategory == null ? null : newCategory.CategoryId
    });

    this.refreshTitle(data.Name);
  }

  async onSubmit() {
    this.isSubmitted = true;

    if (!this.form.valid) return;

    this.spinnerService.show(this.fullScreenSpinner);

    let isNew = !this.id.value;
    let isSuccessful = await this.save();

    this.spinnerService.hide(this.fullScreenSpinner);

    if (!isSuccessful) return;

    this.isSubmitted = true;
    this.form.reset();
    this.editProductType.close();

    if (isNew)
      this.productEdited.emit();
    else
      this.productAdded.emit();
  }

  async save() {
    let productType = new ProductType();
  
    productType.Construct(this.id.value, this.name.value);

    let isSuccessful: boolean;

    await this.productTypeService.editProductType(productType)
    .then(_ => isSuccessful = true)
    .catch(err => { 
      if (err.status === 409)
        this.name.setErrors({ duplicate: true });
    });

    let category = new LookUpProductCategories();

    category.Construct(this.id.value, this.categoryId.value);
    await this.categoryService.saveCategory(category)
    .then(_ => isSuccessful = true)
    .catch(err => { 
      if (err.status === 409)
        this.name.setErrors({ duplicate: true });
    });

    return isSuccessful;
  }

  refreshTitle(name: string = '') {
    this.modalTitle = name
      ? 'Editing ' + name
      : 'Adding Product Type';
  }

  async onCategoryScrollToEnd() {
    if (this.categoryLoading || this.categoryLength <= this.categoryInputLength)
        return;
      
    await this.fetchMoreCategory();
  }

  onCategoryClear()
  {
    this.categoryInput = '';

    this.categoryLoading = true;

      if (!this.categoryLength) {
        this.categoryInputLength = 0;
        this.categoryLoading = false;

        return;
      }
      
      this.categoryBufferArr = [];
      this.categoryPageIndex = 0;
    
      this.fetchMoreCategory();
  }

  onCategorySearch(obj: {term:string, items:any[]})
  {
    if (this.categorySearchTimer)
      clearTimeout(this.categorySearchTimer);
    
    this.categorySearchTimer = setTimeout(() => {
      let term = obj.term;
      let items = obj.items;

      this.categoryInput = term.trim();

      this.categoryLoading = true;
          
         if (!this.categoryLength || this.categoryLength == items.length) {
           this.categoryInputLength = items.length;
           this.categoryLoading = false;
          return;
         }
      
         this.categoryBufferArr = [];
         this.categoryPageIndex = 0;
         this.fetchMoreCategory();

    }, 1000);
  }

  async onCategoryScroll({ end }) {
    if (this.categoryLoading || this.categoryLength <= this.categoryInputLength)
        return;

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.categoryInputLength)
        await this.fetchMoreCategory();
  }

  async fetchMoreCategory() {
    this.categoryLoading = true;
  
    let newCategory = await this.categoryService.getMoreCategories();
    this.categoryBuffer = {...this.categoryBuffer, ...newCategory};

    this.categoryBufferArr = Object.keys(this.categoryBuffer).map(key => {
        return { Id: Number(key), Name: this.categoryBuffer[key] };
      }).sort((a,b) => 
        (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));

    let bufferLength = Object.keys(this.categoryBuffer).length;
    
    this.categoryInputLength = bufferLength > this.categoryLength ? this.categoryLength : bufferLength;

    this.categoryLoading = false;
  }
}
