import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TableControl } from 'src/app/models/TableControl';
import { Vessel } from 'src/app/models/Vessel';
import { FilterModel } from 'src/app/models/FilterModel';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { ConstantsService } from '../../common/constants/constants.service';

@Injectable({
  providedIn: 'root'
})
export class VesselService {
  constructor(private http: HttpClient,
    private utilities: UtilitiesService,
    private constants: ConstantsService) { }

  async getAllVessels() {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Vessel/Get";

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getVessels(tableControl: TableControl) {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Vessel/GetPaginatedByDistributorId";
      url = this.utilities.applyTableControls(url, tableControl);

      this.http.get(url, {
          params: new HttpParams().set("distributorId", this.constants.DISTRIBUTOR_ID.toString())
      }).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getVesselsCount(filter: FilterModel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Vessel/GetCountByDistributorId";
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url, {
        params: new HttpParams().set("distributorId", this.constants.DISTRIBUTOR_ID.toString())
      }).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async saveVessel(vessel: Vessel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Vessel/Save";
      var body = {
        Name: vessel.Name,
        CarrierId: vessel.CarrierId,
        RampCapacity: vessel.RampCapacity,
        HatchHeight: vessel.HatchHeight,
        DistributorId: this.constants.DISTRIBUTOR_ID,
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async editVessel(vessel: Vessel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Vessel/Save";
      var body = {
        Id: vessel.Id,
        Name: vessel.Name,
        CarrierId: vessel.CarrierId,
        RampCapacity: vessel.RampCapacity,
        HatchHeight: vessel.HatchHeight,
        DistributorId: this.constants.DISTRIBUTOR_ID,
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async disableVessel(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Vessel/Disable";
      var body = "=" + id;

      var headers = this.utilities.getFormHeaders();

      this.http.post(url, body, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

}
