import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TableControl } from 'src/app/models/TableControl';
import { FilterModel } from 'src/app/models/FilterModel';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { ConstantsService } from '../../common/constants/constants.service';
import { SailingSchedule } from 'src/app/models/SailingScedule';


@Injectable({
	providedIn: 'root'
})
export class SailingScheduleService {

	public distributorId: number;

	constructor(
		private http: HttpClient,
		private utilities: UtilitiesService,
		private constants: ConstantsService
	) {
		// let currentUser = JSON.parse(localStorage.getItem('_currentUser'));
		// this.distributorId = currentUser.profile.sub;
		this.distributorId = this.constants.DISTRIBUTOR_ID;
      this.distributorId = this.constants.DISTRIBUTOR_ID;
	}

	async getSailingSchedules(tableControl: TableControl) {
		return new Promise((resolve, reject) => {
			let url = this.constants.SERVICE_URL + `api/SailingSchedule/GetPaginatedByDistributorId?distributorId=${this.distributorId}`;
			url 	= this.utilities.applyTableControls(url, tableControl);

			this.http.get(url).subscribe(data => {
				resolve(data);
			}, err => {
				resolve(err);
			});
		});
	}

	async getSailingSchedulesCount(filter: FilterModel): Promise<any> {
		return new Promise((resolve, reject) => {
			let url = this.constants.SERVICE_URL + `api/SailingSchedule/GetCountByDistributorId?distributorId=${this.distributorId}`;
			url 	= this.utilities.applyTableFilters(url, filter);

			this.http.get(url).subscribe(data => {
				resolve(data);
			}, err => {
				resolve(err);
			});
		});
	}

	async saveSailingSchedule(sailingSchedule: SailingSchedule): Promise<any> {
		return new Promise((resolve, reject) => {
			let url 		= this.constants.SERVICE_URL + "api/SailingSchedule/Save";
			let body 		= {
				VoyageId: 				sailingSchedule.VoyageId,
				OriginPortId: 			sailingSchedule.OriginPortId,
				DestinationPortId: 		sailingSchedule.DestinationPortId,
				OriginCountryId: 		sailingSchedule.OriginCountryId,
				DestinationCountryId:	sailingSchedule.DestinationCountryId,
				ETA: 					sailingSchedule.ETA,
				ETD: 					sailingSchedule.ETD,
				DistributorId: 			sailingSchedule.DistributorId
			};
			let xb 			= this.utilities.getEncodedBody(body);
			let headers 	= this.utilities.getFormHeaders();

			this.http.post(url, xb, headers).subscribe(data => {
				resolve(data);
			}, err => {
				reject(err);
			});
		});
	}

	async editSailingSchedule(sailingSchedule: SailingSchedule): Promise<any> {
		return new Promise((resolve, reject) => {
			let url 	= this.constants.SERVICE_URL + "api/SailingSchedule/Save";
			let body 	= {
				Id: 					sailingSchedule.Id,
				VoyageId: 				sailingSchedule.VoyageId,
				OriginPortId: 			sailingSchedule.OriginPortId,
				DestinationPortId: 		sailingSchedule.DestinationPortId,
				OriginCountryId: 		sailingSchedule.OriginCountryId,
				DestinationCountryId:	sailingSchedule.DestinationCountryId,
				ETA: 					sailingSchedule.ETA,
				ETD: 					sailingSchedule.ETD,
				DistributorId: 			sailingSchedule.DistributorId
			};
			let xb 		= this.utilities.getEncodedBody(body);
			let headers = this.utilities.getFormHeaders();

			this.http.post(url, xb, headers).subscribe(data => {
				resolve(data);
			}, err => {
				reject(err);
			});
		});
	}

	async disableSailingSchedule(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
			let url 	= this.constants.SERVICE_URL + "api/SailingSchedule/Disable";
			let body 	= "=" + id;
			let headers = this.utilities.getFormHeaders();

			this.http.post(url, body, headers).subscribe(data => {
				resolve(data);
			}, err => {
				reject(err);
			});
		});
	}
}
