import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../common/constants/constants.service';

@Injectable({
  providedIn: 'root'
})
export class PortService {

  ports: any;

  constructor(private http: HttpClient,
    private constants: ConstantsService) { 
      this.ports = {};
    }

  async getPortsByCountry(countryId: number): Promise<any> {
    let inMemoryPorts = this.getPortsByCountryid(countryId);

    if (inMemoryPorts) return inMemoryPorts;

    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/CountryPorts/GetPorts?countryId=" + countryId;

      this.http.get(url).subscribe(data => {
        this.ports[countryId] = data;
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  private getPortsByCountryid(countryId: number): [] {
    return this.ports[countryId];
  }
}
