import { Component, OnInit } from '@angular/core';
import { FilterModel } from 'src/app/models/FilterModel';
import { FilterOption } from 'src/app/models/FilterOption';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';
import { VoyageService } from 'src/app/services/http/voyage/voyage.service';
import { VesselService } from 'src/app/services/http/vessel/vessel.service';

@Component({
  selector: 'app-view-voyage',
  templateUrl: './view-voyage.component.html',
  styleUrls: ['./view-voyage.component.scss']
})
export class ViewVoyageComponent implements OnInit {
  //Modal Identifier Constants
  addVoyageModal: string = "addVoyage";
  editVoyageModal: string = "editVoyage";
  disableVoyageModal: string = "disableVoyage";

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];

  //Local Variables
  voyages: any;
  vessels: any;

  constructor(private voyageService: VoyageService,
    private vesselService: VesselService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService: ToastrService) {
    this.filterOptions = [
      new FilterOption("Id", "Id"),
      new FilterOption("VoyageNumber", "Voyage number"),
      new FilterOption("Vessel", "Vessel"),
      new FilterOption("Created", "Modified On"),
      new FilterOption("CreatedByName", "Modified By")
    ];

    this.tableControl = new TableControl(10);
  }

  async ngOnInit() {
    this.spinner.show("fullScreenSpinner");
    await this.loadItemCount();
    await this.loadVessels();
    this.spinner.hide("fullScreenSpinner");

    await this.loadVoyages();
  }

  private async loadVoyages() {
    this.spinner.show("tableSpinner");
    this.voyages = await this.getVoyages();
    this.tableControl.CurrentItemCount = this.voyages.length;
    this.spinner.hide("tableSpinner");
  }

  private async loadItemCount() {
    this.tableControl.TotalItemCount = await this.voyageService.getVoyagesCount(this.tableControl.Filter);
  }

  private async loadVessels(){
    this.vessels = await this.getVessels();
  }

  private async getVessels() {
    return this.vesselService.getAllVessels();
  }

  private async getVoyages() {
    return this.voyageService.getVoyages(this.tableControl);
  }

  addVoyage() {
    this.ngxSmartModalService.open(this.addVoyageModal);
  }

  editVoyage(voyage: any) {
    this.ngxSmartModalService.resetModalData(this.editVoyageModal);
    this.ngxSmartModalService.setModalData(voyage, this.editVoyageModal);
    this.ngxSmartModalService.open(this.editVoyageModal);
  }

  disableVoyage(voyage: any) {
    this.ngxSmartModalService.resetModalData(this.disableVoyageModal);
    this.ngxSmartModalService.setModalData(voyage, this.disableVoyageModal);
    this.ngxSmartModalService.open(this.disableVoyageModal);
  }

  //EMMITED EVENT ACTIONS
  onVoyageAdded() {
    this.tableControl.Page = 1;
    this.loadItemCount();
    this.loadVoyages();
    this.toasterService.success("Voyage successfully Added.", "Add Voyage");
  }

  onVoyageEdited() {
    this.loadVoyages();
    this.toasterService.success("Voyage successfully Edited.", "Edit Voyage");
  }

  onVoyageDisabled() {
    this.loadItemCount();
    this.loadVoyages();
    this.toasterService.success("Voyage successfully Deleted.", "Delete Voyage");
  }

  onPageChanged({ page, maxPageSize }) {
    this.tableControl.Page = page;
    this.tableControl.MaxPageSize = maxPageSize;
    this.loadVoyages();
  }

  onFiltered(filter: FilterModel) {
    this.tableControl.Page = 1;
    this.tableControl.Filter = filter;
    this.loadVoyages();
    this.loadItemCount();
  }

  onSorted(sort: string) {
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1;
    this.loadVoyages();
  }

}
