import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { VesselService } from 'src/app/services/http/vessel/vessel.service';

@Component({
  selector: 'app-disable-vessel',
  templateUrl: './disable-vessel.component.html',
  styleUrls: ['./disable-vessel.component.scss']
})
export class DisableVesselComponent implements OnInit {
  @Output() vesselDisabled = new EventEmitter();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private vesselService: VesselService) { }

  ngOnInit() {
  }

  async disableVessels(id) {
    this.spinner.show("fullScreenSpinner");
    await this.vesselService.disableVessel(id);
    this.spinner.hide("fullScreenSpinner");

    this.ngxSmartModalService.getModal('disableVessel').close()
    this.vesselDisabled.emit();
  }
}
