import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductTypeService } from 'src/app/services/http/product-type/product-type.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterOption } from 'src/app/models/FilterOption';
import { FilterModel } from 'src/app/models/FilterModel';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';
import { ProductType } from 'src/app/models/ProductType';

@Component({
  selector: 'app-view-product-types',
  templateUrl: './view-product-types.component.html',
  styleUrls: ['./view-product-types.component.scss']
})
export class ViewProductTypesComponent implements OnInit, OnDestroy {
  //Modal Identifier Constants
  detailedProductTypeModal: string = "detailedProductType";
  addProductTypeModal: string = "addProductType";
  editProductTypeModal: string = "editProductType";
  deleteProductTypeModal: string = "deleteProductType";
  disableProductTypeModal: string = "disableProductType";

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];
  
  //Local Variables
  productTypes: any;
  isSyncing = false;
  syncPoll: any;
  loadedOngoingSync = false;
  progressRate = 0;

  constructor(private productTypeService: ProductTypeService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService : ToastrService) { 
      this.filterOptions = [
        new FilterOption("Id", "Id"),
        new FilterOption("Word", "Product Type"),
        new FilterOption("Created", "Modified On"),
        new FilterOption("CreatedBy", "Modified By"),
      ];

      this.tableControl = new TableControl(10);
    }

  async ngOnInit() {
    this.spinner.show("fullScreenSpinner");
    await this.getItemCount();
    this.spinner.hide("fullScreenSpinner");

    await this.loadProductTypes();

    if (await this.getSyncStatus())
      await this.initializeSyncPolling();
  }

  async ngOnDestroy() {
    clearInterval(this.syncPoll);
  }

  private async loadProductTypes(){
    this.spinner.show("tableSpinner");
    this.productTypes = await this.getProductTypes();
    this.tableControl.CurrentItemCount = this.productTypes.length;
    this.spinner.hide("tableSpinner");
  }

  private async getProductTypes(){
    return this.productTypeService.getProductTypes(this.tableControl);
  }

  private async getItemCount(){
    this.tableControl.TotalItemCount = await this.productTypeService.getProductTypeCount(this.tableControl.Filter);
  }

  async syncProductTypes() {
    let result = await this.productTypeService.syncAll();

    this.isSyncing = result && result.syncId;

    if (this.isSyncing)
      this.initializeSyncPolling();
  }

  private async getSyncStatus(): Promise<any> {
    return await this.productTypeService.getSyncStatus();
  }

  async initializeSyncPolling() {
    this.isSyncing = true;

    clearInterval(this.syncPoll);

    this.syncPoll = setInterval(() => {
      this.getSyncStatus().then(result => {
        this.isSyncing = !!result.syncId;
        
        this.progressRate = parseFloat((result.progress * 100).toFixed(2));

        if (this.isSyncing)
          this.loadedOngoingSync = true;

        if (!this.isSyncing && this.syncPoll) {
          clearInterval(this.syncPoll);
          this.syncPoll = null;

          if (this.loadedOngoingSync) {
            this.loadedOngoingSync = false;
            this.toasterService.success("Syncronisation was completed.", "Sync Product Types");
          }
        }

      });
    }, 2000);
  }

  viewDetails(productType: any){
    this.ngxSmartModalService.resetModalData(this.detailedProductTypeModal);
    this.ngxSmartModalService.setModalData(productType, this.detailedProductTypeModal);
    this.ngxSmartModalService.open(this.detailedProductTypeModal);
  }

  addProductType(){
    this.openProductType();
  }

  editProductType(productType: any){
    this.openProductType(productType);
  }

  openProductType(productType: any = null) {
    this.ngxSmartModalService.resetModalData(this.editProductTypeModal);

    let productTypeForm = new ProductType();

    if (productType)
      // productTypeForm.Construct({
      //   Id: productType.Id, 
      //   Name: productType.ProductTypeName,
      //   //CategoryId: productType.CategoryId,
      //   //CategoryName: productType.CategoryName
      // });

      productTypeForm.Construct(productType.Id, productType.ProductTypeName);
    
    this.ngxSmartModalService.setModalData(productTypeForm, this.editProductTypeModal);

    this.ngxSmartModalService.open(this.editProductTypeModal);
  }

  deleteProductType(productType: any){
    this.ngxSmartModalService.resetModalData(this.deleteProductTypeModal);
    this.ngxSmartModalService.setModalData(productType, this.deleteProductTypeModal);
    this.ngxSmartModalService.open(this.deleteProductTypeModal);
  }

  disableProductType(productType: any){
    this.ngxSmartModalService.resetModalData(this.disableProductTypeModal);
    this.ngxSmartModalService.setModalData(productType, this.disableProductTypeModal);
    this.ngxSmartModalService.open(this.disableProductTypeModal);
  }

  importProductTypes() {
   this.ngxSmartModalService.open("importProductType"); 
  }

  //EMMITED EVENT ACTIONS
  onProductAdded(){
    this.tableControl.Page = 1; 
    this.getItemCount();
    this.loadProductTypes();
    this.toasterService.success("Product Type successfully Added.", "Add Product Type");
  }

  onProductEdited(){
    this.loadProductTypes();
    this.toasterService.success("Product Type successfully Edited.", "Edit Product Type");
  }

  onProductDisabled(){
    this.getItemCount();
    this.loadProductTypes();
    this.toasterService.success("Product Type successfully Deleted.", "Delete Product Type");
  }

  onProductDeleted(){
    this.getItemCount();
    this.loadProductTypes();
    this.toasterService.success("Product Type successfully Deleted.", "Delete Product Type");
  }

  onProductImported() {
    this.loadProductTypes();
    this.toasterService.success("Product Types successfully Imported.", "Import Product Type");
  }

  onPageChanged({ page, maxPageSize }){
    this.tableControl.Page = page;
    this.tableControl.MaxPageSize = maxPageSize;

    this.loadProductTypes();
  }

  onFiltered(filter : FilterModel){
    this.tableControl.Page = 1; 
    this.tableControl.Filter = filter;
    this.getItemCount();
    this.loadProductTypes();
  }

  onSorted(sort: string){
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1; 
    this.loadProductTypes();
  }

}
