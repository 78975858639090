import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../common/constants/constants.service';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { LookUpProductCategories } from 'src/app/models/LookUpProductCategories';
import { TableControl } from 'src/app/models/TableControl';
import { FilterModel } from 'src/app/models/FilterModel';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private distributorId: number;
  category: any[];

  constructor(private http: HttpClient,
    private constants: ConstantsService,
    private utilities: UtilitiesService,) { 
      this.category = [];
      this.distributorId = this.constants.DISTRIBUTOR_ID;
    }

  async getMoreCategories(): Promise<{}> {
    return new Promise((resolve, reject) => {

      var url = this.constants.SERVICE_URL + "api/Categories/GetAll";

      this.http.get<any[]>(url).pipe(
        map((items: {Id:number, Description:string}[]) => {
          var obj = {};
          items.forEach(item => {
            obj[item.Id] = item.Description;
          });
          return obj;
          })
        )
        .subscribe(data => resolve(data),
                  err => reject(err));
    })
  }
  
  getCategoryByProdTypeId(id: number, category: any[]): any {
    return category.find(c => c.Id == id);
  }

  async GetCategoryByProductTypeId(id:number) {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + `api/Categories/GetCategoryByProductTypeId?id=${id}`;

    this.http.get(url).subscribe((data: LookUpProductCategories[]) => {
      resolve(data);
    }, err => {
      resolve(err);
    });
   });
  }

  async saveCategory(category: LookUpProductCategories): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + "api/Categories/Save";
      let body = {
        CategoryId: category.CategoryId,
        ProductTypeId: category.ProductTypeId,
      }

      let xb = this.utilities.getEncodedBody(body);
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }
}