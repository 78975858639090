import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ConfigurationService } from 'src/app/services/http/configuration/configuration.service';

@Component({
  selector: 'app-delete-configuration',
  templateUrl: './delete-configuration.component.html',
  styleUrls: ['./delete-configuration.component.scss']
})
export class DeleteConfigurationComponent implements OnInit {

  @Output() configurationDeleted = new EventEmitter();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private configurationService: ConfigurationService) { }

  ngOnInit() {
  }

  async deleteConfigurations(configurationId: number) {
    this.spinner.show("fullScreenSpinner");
    await this.configurationService.deleteConfiguration(configurationId);
    this.spinner.hide("fullScreenSpinner");

    this.ngxSmartModalService.getModal('deleteConfiguration').close()
    this.configurationDeleted.emit();
  }
}
