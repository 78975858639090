import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Carrier } from 'src/app/models/Carrier';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CarrierService } from 'src/app/services/http/carrier/carrier.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-carrier',
  templateUrl: './add-carrier.component.html',
  styleUrls: ['./add-carrier.component.scss']
})
export class AddCarrierComponent implements OnInit {
  @Output() carrierAdded = new EventEmitter();
  carrier: Carrier = new Carrier();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private carrierService: CarrierService) { }

  ngOnInit() {
  }

  async addCarriers(carrier: NgForm) {
    console.log(carrier);
    
    if (!carrier.valid) 
      return;
    
    this.spinner.show("fullScreenSpinner");

    let isSuccess = false;
    
    await this.carrierService.saveCarrier(this.carrier)
    .then(data => {
      if (data === "Carrier record exist!")
        carrier.controls['carrierName'].setErrors({ 'duplicate': true });
      else
        isSuccess = true;
    })
    .catch(err => {
      if (err.status === 409)
        carrier.controls['carrierName'].setErrors({ 'duplicate': true });
    });

    this.spinner.hide("fullScreenSpinner");
    
    if (isSuccess) {
      this.carrier.Clear();
      carrier.resetForm();
      this.ngxSmartModalService.getModal('addCarrier').close()
      this.carrierAdded.emit();
    }
  }
}
