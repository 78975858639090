export class ChildUser {
    public Id: number;
    public ParentUserId: number;
    public ChildUserId: number;
    public ChildUserEmail: string;
    public Password: string;
    public InitialClaim: string;

    
    public Clear(){
        this.Id = null;
        this.ParentUserId = null;
        this.ChildUserId = null;
        this.ChildUserEmail = "";
        this.Password = "";
        this.InitialClaim = "";
    }

    public Construct(object: any){
        this.Id = object.Id;
        this.ParentUserId = object.ParentUserId;
        this.ChildUserId = object.ChildUserId;
        this.ChildUserEmail = object.Email;
        this.Password = object.Password;
        this.InitialClaim = object.InitialClaim;
    }
}