import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ConstantsService } from 'src/app/services/common/constants/constants.service';
import { UtilitiesService } from 'src/app/services/common/utilities/utilities.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
	// openedMenu: Array<number> = [];
	isExpanded: boolean = false;
	currentUser: any;
	routes: Array<{
		icon: string,
		path?: string,
		text?: string,
		children?: Array<{ path: string, text: string, icon: string }>,
		isActive?: boolean
	}>;

	constructor(private utilities: UtilitiesService,
		private constants: ConstantsService) {
			this.currentUser = JSON.parse(this.utilities.getStorage(this.constants.CURRENT_USER));

			this.routes = this.currentUser.profile.role != "System Administrator" ? constants.routes : this.routes = constants.routesAdmin;
		}		

	ngOnInit() {
		this.isExpanded = localStorage.getItem('isExpanded') === '1';
		this.addSideBarClass(this.isExpanded);
	}

	// showSubNavigation(idx) {
	// 	if (!this.isExpanded) {
	// 		this.openedMenu = [idx];
	// 	}
	// }

	// hideSubNavigation() {
	// 	if (!this.isExpanded) {
	// 		this.openedMenu = [];
	// 	}
	// }

	toggleExpand() {
		this.isExpanded = !this.isExpanded;
		this.addSideBarClass(this.isExpanded);

		localStorage.setItem('isExpanded', this.isExpanded ? '1' : '0');
		// if (this.isExpanded) {
		// 	localStorage.setItem('isExpanded', '1');
		// } else {
		// 	localStorage.setItem('isExpanded', '0');
		// 	this.openedMenu = [];
		// }
	}

	addSideBarClass(isExpanded: boolean) {
		const el = document.querySelector('body');
		if (isExpanded) {
			el.classList.add('c-sidebar-expanded');
		} else {
			el.classList.remove('c-sidebar-expanded');
		}
	}

	// showExpandedOptions(idx) {
	// 	if (this.isExpanded) {
	// 		if (this.openedMenu.includes(idx)) {
	// 			this.openedMenu = this.openedMenu.filter(v => v !== idx);
	// 		} else {
	// 			this.openedMenu.push(idx);
	// 		}
	// 	}
	// }

}
