import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-sorter',
  templateUrl: './table-sorter.component.html',
  styleUrls: ['./table-sorter.component.scss']
})
export class TableSorterComponent {
  @Input() filterOptions: any;
  @Output() sorted = new EventEmitter<string>();

  orderByAsc : boolean = true;
  lastSortedBy : string;

  Sort(sortBy : string){
    if (!sortBy) return;

    this.orderByAsc = this.lastSortedBy == sortBy ? !this.orderByAsc : true;

    if (this.lastSortedBy){
      document.getElementById(this.lastSortedBy + "-order").setAttribute("class", "");
    }

    var order = "";
    if (this.orderByAsc){
      order = "ASC";
      document.getElementById(sortBy + "-order").setAttribute("class", "fa fa-caret-up");
    } else{
      order = "DESC";
      document.getElementById(sortBy + "-order").setAttribute("class", "fa fa-caret-down");
    }

    this.lastSortedBy = sortBy;
    this.sorted.emit(sortBy + order);
  }
}
