import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgForm } from '@angular/forms';
import { VesselService } from 'src/app/services/http/vessel/vessel.service';
import { Vessel } from 'src/app/models/Vessel';
import { CarrierService } from 'src/app/services/http/carrier/carrier.service';
import { FilterModel } from 'src/app/models/FilterModel';

@Component({
  selector: 'app-add-vessel',
  templateUrl: './add-vessel.component.html',
  styleUrls: ['./add-vessel.component.scss']
})
export class AddVesselComponent implements OnInit {
  @Output() vesselAdded = new EventEmitter();
  vessel: Vessel = new Vessel();

  bufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;

  carrierBuffer = {};
  carrierBufferArr: {Id: number, Name:string}[] = [];
  carrierLength = 0;
  carrierPageIndex = 0;

  carriersLoading = false;
  carriersInputLength = 0;
  carriersInput = '';
  carrierSearchTimer: any;

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private vesselService: VesselService,
    private carrierService: CarrierService) { }

  async ngOnInit() {
    this.carrierLength = await this.carrierService.getCarrierCount(this.productTypeWordFilter(this.carriersInput));
  }

  async addVessels(vessel: NgForm) {
    if (vessel.valid) {
      this.spinner.show("fullScreenSpinner");
      await this.vesselService.saveVessel(this.vessel);
      this.spinner.hide("fullScreenSpinner");

      this.vessel.Clear();
      vessel.resetForm();

      this.ngxSmartModalService.getModal('addVessel').close()
      this.vesselAdded.emit();
    }
  }
  
  onCarrierClear()
  {
    this.carriersInput = '';

    this.carriersLoading = true;

    this.carrierService.getCarrierCount(this.productTypeWordFilter(this.carriersInput))
    .then(data => {
      this.carrierLength = data;

      if (!this.carrierLength) {
        this.carriersInputLength = 0;
        this.carriersLoading = false;

        return;
      }
      
      this.carrierBufferArr = [];
      this.carrierPageIndex = 0;
    
      this.fetchMoreCarriers();
    });
  }

  onCarrierSearch(obj: {term:string, items:any[]})
  {
    if (this.carrierSearchTimer)
      clearTimeout(this.carrierSearchTimer);
    
    this.carrierSearchTimer = setTimeout(() => {
      let term = obj.term;
      let items = obj.items;

      this.carriersInput = term.trim();

      this.carriersLoading = true;

      this.carrierService.getCarrierCount(this.productTypeWordFilter(this.carriersInput))
      .then(data => {
        this.carrierLength = data;
          
        if (!this.carrierLength || this.carrierLength == items.length) {
          this.carriersInputLength = items.length;
          this.carriersLoading = false;

          return;
        }
        
        this.carrierBufferArr = [];
        this.carrierPageIndex = 0;
      
        this.fetchMoreCarriers();
      });
    }, 1000);
  }

  async onCarrierScroll({ end }) {
    if (this.carriersLoading || this.carrierLength <= this.carriersInputLength)
        return;

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.carriersInputLength)
        await this.fetchMoreCarriers();
  }

  async onCarrierScrollToEnd() {
    if (this.carriersLoading || this.carrierLength <= this.carriersInputLength)
        return;
      
    await this.fetchMoreCarriers();
  }

  private async fetchMoreCarriers() {
    this.carriersLoading = true;
  
    let newCarriers = await this.carrierService.getMoreCarriers(this.carriersInput, this.bufferSize, ++this.carrierPageIndex);

    this.carrierBuffer = {...this.carrierBuffer, ...newCarriers};

    this.carrierBufferArr = Object.keys(this.carrierBuffer).map(key => {
        return { Id: Number(key), Name: this.carrierBuffer[key] };
      }).sort((a,b) => 
        (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));

    let bufferLength = Object.keys(this.carrierBuffer).length;
    
    this.carriersInputLength = bufferLength > this.carrierLength ? this.carrierLength : bufferLength;

    this.carriersLoading = false;
  }

  private productTypeWordFilter(term: string)
  {
    return new FilterModel("Word", term);
  }
}
