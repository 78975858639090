export class FilterOption {
    public Value: string;
    public Text: string;
    public Type: string = 'text';
    public Options: FilterOption[];

    constructor(value:string, text:string) {
        this.Value = value;
        this.Text = text;
    }
}