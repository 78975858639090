import { Component, OnInit, OnDestroy } from '@angular/core';
import { DistributorFeeService } from 'src/app/services/http/DistributorFee/distributorfee.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterOption } from 'src/app/models/FilterOption';
import { FilterModel } from 'src/app/models/FilterModel';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { TableControl } from 'src/app/models/TableControl';
import { DistributorFee } from 'src/app/models/DistributorFee';
import { config } from 'rxjs';

@Component({
  selector: 'app-view-distributor-fees',
  templateUrl: './view-distributor-fees.component.html',
  styleUrls: ['./view-distributor-fees.component.scss']
})
export class ViewDistributorFeesComponent implements OnInit, OnDestroy {
  //Modal Identifier Constants
  addDistributorFeeModal: string = 'addDistributorFee';
  editDistributorFeeModal: string = 'editDistributorFee';
  deleteDistributorFeeModal: string = 'deleteDistributorFee';

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];
  
  //Local Variables
  distributorFees: any;

  isSyncing = false;
  syncPoll: any;
  progressRate = 0;
  loadedOngoingSync = false;

  constructor(private distributorFeeService: DistributorFeeService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService : ToastrService) { 
      this.filterOptions = [
        new FilterOption('Id', 'Id'),
        new FilterOption('FeeDescription', 'Fee Description'),
        new FilterOption('CurrencyISO', 'Currency'),
        new FilterOption('FeeRates', 'Fee Amount')
      ];

      this.tableControl = new TableControl(10);
    }

    async ngOnInit() {
      this.spinner.show("fullScreenSpinner");
      await this.getItemCount();
      this.spinner.hide("fullScreenSpinner");
  
      await this.loadDistributorFees();
    }
  
    ngOnDestroy(): void {
      clearInterval(this.syncPoll);
    }
  
    private async loadDistributorFees(){
      this.spinner.show("tableSpinner");
      this.distributorFees = await this.getDistributorFees();
      this.tableControl.CurrentItemCount = this.distributorFees.length;
      this.spinner.hide("tableSpinner");
    }
  
    private async getDistributorFees(){
      return this.distributorFeeService.getDistributorFees(this.tableControl);
    }
  
    private async getItemCount(){
      this.tableControl.TotalItemCount = await this.distributorFeeService.getDistributorFeeCount(this.tableControl.Filter);
    }

  addDistributorFee(){
    this.openEditDistributorFee();
  }

  editDistributorFee(distributorFee: any){
    this.openEditDistributorFee(distributorFee);
  }

  openEditDistributorFee(distributorFee: any = null) {
    this.ngxSmartModalService.resetModalData(this.editDistributorFeeModal);

    let distributorFeeForm = new DistributorFee();

    if (distributorFee)
      distributorFeeForm.Construct({
        Id : distributorFee.Id,
        FeeDescription : distributorFee.FeeDescription,
        FeeRates : distributorFee.FeeRates,
        FeeTypeName : distributorFee.FeeTypeName,
        CurrencyISO : distributorFee.CurrencyISO,
        CurrencyId: distributorFee.CurrencyId
      });
  
    this.ngxSmartModalService.setModalData(distributorFeeForm, this.editDistributorFeeModal);
    this.ngxSmartModalService.open(this.editDistributorFeeModal);
  }
  
  deleteDistributorFee(distributorFee: any){
    this.ngxSmartModalService.resetModalData(this.deleteDistributorFeeModal);
    this.ngxSmartModalService.setModalData(distributorFee, this.deleteDistributorFeeModal);
    this.ngxSmartModalService.open(this.deleteDistributorFeeModal);
  }

  //EMMITED EVENT ACTIONS
  onDistributorFeeAdded(){
    this.tableControl.Page = 1; 
    this.getItemCount();
    this.loadDistributorFees();
    this.toasterService.success("Distributor Fee successfully Added.", "Add Distributor Fee");
  }

  onDistributorFeeEdited(){
    this.loadDistributorFees();
    this.toasterService.success("Distributor Fee successfully Edited.", "Edit Distributor Fee");
  }

  onDistributorFeeDeleted(){
    this.getItemCount();
    this.loadDistributorFees();
    this.toasterService.success("Distributor Fee successfully Deleted.", "Delete Distributor Fee");
  }

  onPageChanged({ page, maxPageSize }){
    this.tableControl.Page = page;
    this.tableControl.MaxPageSize = maxPageSize;

    this.loadDistributorFees();
  }

  onFiltered(filter : FilterModel){
    this.tableControl.Page = 1; 
    this.tableControl.Filter = filter;
    this.getItemCount();
    this.loadDistributorFees();
  }

  onSorted(sort: string){
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1; 
    this.loadDistributorFees();
  }
}
