export class Carrier {
    public Id: number;
    public Name: string;
    
    public Clear(){
        this.Id = null;
        this.Name = "";
    }

    public Construct(id: number, name: string) {
        this.Id = id;
        this.Name = name;
    }
}