import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { VoyageService } from 'src/app/services/http/voyage/voyage.service';

@Component({
  selector: 'app-disable-voyage',
  templateUrl: './disable-voyage.component.html',
  styleUrls: ['./disable-voyage.component.scss']
})
export class DisableVoyageComponent implements OnInit {
  @Output() voyageDisabled = new EventEmitter();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private voyageService: VoyageService) { }

  ngOnInit() {
  }

  async disableVoyages(id: number) {
    this.spinner.show("fullScreenSpinner");
    await this.voyageService.disableVoyage(id);
    this.spinner.hide("fullScreenSpinner");

    this.ngxSmartModalService.getModal('disableVoyage').close()
    this.voyageDisabled.emit();
  }
}
