import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { VoyageService } from 'src/app/services/http/voyage/voyage.service';
import { Voyage } from 'src/app/models/Voyage';

@Component({
  selector: 'app-add-voyage',
  templateUrl: './add-voyage.component.html',
  styleUrls: ['./add-voyage.component.scss']
})
export class AddVoyageComponent implements OnInit {
  @Output() voyageAdded = new EventEmitter();
  @Input() vessels: any;
  voyage: Voyage = new Voyage();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private voyageService: VoyageService) { 
      this.voyage.VesselId = null;
    }

  ngOnInit() {
  }

  async addVoyages(voyage: NgForm) {
    if (voyage.valid) {
      this.spinner.show("fullScreenSpinner");
      await this.voyageService.saveVoyage(this.voyage);
      this.spinner.hide("fullScreenSpinner");
      this.voyage.Clear();
      voyage.resetForm();

      this.ngxSmartModalService.getModal('addVoyage').close()
      this.voyageAdded.emit();
    }
  }
}
