export class ProductType {
    public Id: number;
    public Name: string;
    //public CategoryId : number;
    //public CategoryName: string;

    public Clear() {
        this.Id = null;
        this.Name = "";
        //this.CategoryId = null;
        //this.CategoryName = "";
    }
    
    public Construct(id: number, name: string){
        this.Id = id;
        this.Name = name;
        //this.CategoryId = object.categoryId;
        //this.CategoryName = object.categoryName;
    }
}