import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ConstantsService {
  readonly CURRENT_USER: string = "_currentUser";

  // readonly DISTRIBUTOR_ID: number = this.getCurrentDistributorId();

  readonly CURRENCY_ISO_USD = "USD";
  readonly CURRENCY_ISO_AUD = "AUD";
  readonly CURRENCY_ISO_NZD = "NZD";
  readonly COUNTRY_CODE_AU = "AU";
  readonly COUNTRY_CODE_NZ = "NZ";

  get DISTRIBUTOR_ID(): number {
    return this.getCurrentDistributorId();
    //return 7;
  }

  // live
  public SERVICE_URL: string =
    "https://mycargowebapi-australia.azurewebsites.net/";
  public IDENTITY_URL: string =
    "https://mycargoidentity2-australia.azurewebsites.net/";

  //staging
  //public SERVICE_URL: string = "https://mycargowebapi-staging.azurewebsites.net/";
  //public IDENTITY_URL: string = "https://mycargoidentity-staging.azurewebsites.net/";

  // local
  //public SERVICE_URL: string = "https://localhost:44311/";
  //public IDENTITY_URL: string = "https://localhost:44310/";
  //public SERVICE_URL: string = environment.apiBaseUrl;
  // Not sure if this the best location to put this
  public routes: Array<{
    icon: string;
    path?: string;
    text?: string;
    children?: Array<{ path: string; text: string; icon: string }>;
    isActive?: boolean;
  }> = [
    {
      icon: "cubes",
      text: "Products",
      children: [
        {
          text: "Product Types",
          path: "/view-product-types/",
          icon: "folder-o",
        },
        {
          text: "Manufacturers",
          path: "/view-manufacturers/",
          icon: "folder-o",
        },
        { text: "Models", path: "/view-make-models/", icon: "folder-o" },
        {
          text: "Configurations",
          path: "/view-configurations/",
          icon: "folder-o",
        },
        {
          text: "Product Configs",
          path: "/view-product-configurations/",
          icon: "folder-o",
        },
      ],
    },
    {
      icon: "calendar",
      text: "Schedules",
      children: [
        {
          text: "Sailing Schedule",
          path: "/view-sailing-schedules/",
          icon: "folder-o",
        },
        { text: "Carriers", path: "/view-carriers/", icon: "folder-o" },
        { text: "Vessels", path: "/view-vessels/", icon: "folder-o" },
        { text: "Voyages", path: "/view-voyages/", icon: "folder-o" },
      ],
    },
    {
      icon: "ship",
      text: "Freight Rates",
      children: [
        {
          text: "Freight Rates",
          path: "/view-freight-rates/",
          icon: "folder-o",
        },
      ],
    },
    // {
    // 	icon: 'pie-chart',
    // 	text: 'Reports',
    // 	children: [
    // 		{ text: 'Insurance Quotes', path: '/view-insurance-quote-reports/', icon: 'folder-o' },
    // 		{ text: 'Insurance Certificates', path: '/view-insurance-certificate-reports/', icon: 'folder-o' }
    // 	]
    // },
    // {
    // 	icon: 'shield',
    // 	text: 'Marsh',
    // 	children: [
    // 		{ text: 'Bordereau', path: '/view-bordereau/', icon: 'folder-o' },
    // 		{ text: 'Premium Distribution', path: '/view-premium-distribution/', icon: 'folder-o' },
    // 		{ text: 'Commission Payable', path: '/view-commission-payable/', icon: 'folder-o' }
    // 	]
    // },
    // {
    // 	icon: 'shield',
    // 	text: 'Distributor',
    // 	children: [
    // 		{ text: 'Distributor Profile', path: '/edit-account-distributor/', icon: 'folder-o' },
    // 		{ text: 'Distributor Margin', path: '/margin/', icon: 'folder-o' },
    // 		{ text: 'Distributor Fees', path: '/view-distributor-fees/', icon: 'folder-o' }
    // 	]
    // },
    // {
    // 	icon: 'user-circle',
    // 	text: 'Administration',
    // 	children: [
    // 		{ text: 'Users Profiles', path: '/change-user-password/', icon: 'folder-o' },
    // 		{ text: 'Change Password', path: '/change-user-password/', icon: 'folder-o' }
    // 	]
    // }
  ];

  public routesAdmin: Array<{
    icon: string;
    path?: string;
    text?: string;
    children?: Array<{ path: string; text: string; icon: string }>;
    isActive?: boolean;
  }> = [
    {
      icon: "pie-chart",
      text: "Reports",
      children: [
        {
          text: "Insurance Quotes",
          path: "/view-insurance-quote-reports/",
          icon: "folder-o",
        },
        {
          text: "Insurance Certificates",
          path: "/view-insurance-certificate-reports/",
          icon: "folder-o",
        },
      ],
    },
    {
      icon: "shield",
      text: "Marsh",
      children: [
        { text: "Bordereau", path: "/view-bordereau/", icon: "folder-o" },
        {
          text: "Premium Distribution",
          path: "/view-premium-distribution/",
          icon: "folder-o",
        },
        {
          text: "Commission Payable",
          path: "/view-commission-payable/",
          icon: "folder-o",
        },
      ],
    },
    {
      icon: "server",
      text: "Distributors",
      children: [
        { text: "Distributors", path: "/view-distributors/", icon: "server" },
      ],
    },
    {
      icon: "folder-o",
      text: "Account",
      children: [
        {
          text: "Change Password",
          path: "/change-user-password/",
          icon: "server",
        },
        { text: "User Account", path: "/view-user-profile/", icon: "user" },
      ],
    },
  ];

  private getCurrentDistributorId() {
    let currentUser = JSON.parse(localStorage.getItem("_currentUser"));

    if (!currentUser) return;

    return currentUser.profile.sub;
  }
}
