import { Injectable } from '@angular/core';
import { PaymentType } from 'src/app/models/PaymentType';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService {
  paymentTypes: PaymentType[];

  constructor() { 
    this.paymentTypes = [
      new PaymentType(1, 'Lump-Sum Rate'),
      new PaymentType(2, 'Revenue Rate')
    ];
  }

  getPaymentTypes() {
    return this.paymentTypes.slice();
  }
}
