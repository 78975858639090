import { Component, OnInit } from '@angular/core';
import { TableControl } from 'src/app/models/TableControl';
import { FilterOption } from 'src/app/models/FilterOption';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { CarrierService } from 'src/app/services/http/carrier/carrier.service';
import { Carrier } from 'src/app/models/Carrier';
import { FilterModel } from 'src/app/models/FilterModel';

@Component({
  selector: 'app-view-carriers',
  templateUrl: './view-carriers.component.html',
  styleUrls: ['./view-carriers.component.scss']
})
export class ViewCarriersComponent implements OnInit {
  //Modal Identifier Constants
  addCarrierModal: string = 'addCarrier';
  editCarrierModal: string = 'editCarrier';
  deleteCarrierModal: string = 'deleteCarrier';

  //Table Variables
  tableControl: TableControl;
  filterOptions: FilterOption[];
  
  //Local Variables
  carriers: any;

  constructor(private carrierService: CarrierService,
    private spinner: NgxSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private toasterService : ToastrService) { 
      this.filterOptions = [
        new FilterOption('Id', 'Id'),
        new FilterOption('Name', 'Name'),
        new FilterOption('CreatedDT', 'Modified On'),
        new FilterOption('CreatedByName', 'Modified By')
      ];

      this.tableControl = new TableControl(10);
    }

  async ngOnInit() {
    this.spinner.show("fullScreenSpinner");
    await this.getItemCount();
    this.spinner.hide("fullScreenSpinner");

    await this.loadCarriers();
  }

  private async getItemCount(){
    this.tableControl.TotalItemCount = await this.carrierService.getCarrierCount(this.tableControl.Filter);
  }
  
  private async loadCarriers(){
    this.spinner.show("tableSpinner");
    this.carriers = await this.getCarriers();
    this.tableControl.CurrentItemCount = this.carriers.length;
    this.spinner.hide("tableSpinner");
  }

  private async getCarriers(){
    return this.carrierService.getCarriers(this.tableControl);
  }

  addCarrier(){
    this.ngxSmartModalService.open(this.addCarrierModal);
  }

  editCarrier(carrier: any){
    this.ngxSmartModalService.resetModalData(this.editCarrierModal);

    let carrierForm = new Carrier();
    carrierForm.Construct(carrier.Id, carrier.Name);
    this.ngxSmartModalService.setModalData(carrierForm, this.editCarrierModal);

    this.ngxSmartModalService.open(this.editCarrierModal);
  }
  
  deleteCarrier(configuration: any){
    this.ngxSmartModalService.resetModalData(this.deleteCarrierModal);
    this.ngxSmartModalService.setModalData(configuration, this.deleteCarrierModal);
    this.ngxSmartModalService.open(this.deleteCarrierModal);
  }

  //EMMITED EVENT ACTIONS
  onCarrierAdded(){
    this.tableControl.Page = 1; 
    this.getItemCount();
    this.loadCarriers();
    this.toasterService.success("Carrier successfully Added.", "Add Carrier");
  }

  onCarrierEdited(){
    this.loadCarriers();
    this.toasterService.success("Carrier successfully Edited.", "Edit Carrier");
  }

  onCarrierDeleted(){
    this.getItemCount();
    this.loadCarriers();
    this.toasterService.success("Carrier successfully Deleted.", "Delete Carrier");
  }

  onPageChanged({ page, maxPageSize }){
    this.tableControl.Page = page; 
    this.tableControl.MaxPageSize = maxPageSize;
    this.loadCarriers();
  }

  onFiltered(filter : FilterModel){
    this.tableControl.Page = 1; 
    this.tableControl.Filter = filter;
    this.getItemCount();
    this.loadCarriers();
  }

  onSorted(sort: string){
    this.tableControl.Sorting = sort;
    this.tableControl.Page = 1; 
    this.loadCarriers();
  }
}
