import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../common/constants/constants.service';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { Distributor } from 'src/app/models/Distributor';
import { TableControl } from 'src/app/models/TableControl';
import { FilterModel } from 'src/app/models/FilterModel';

@Injectable({
  providedIn: 'root'
})
export class DistributorService {
  constructor(private http: HttpClient,
    private utilities: UtilitiesService,
    private constants: ConstantsService) { }

  // async getDistributors(active: boolean, register: boolean) {
  //   return new Promise((resolve, reject) => {
  //     var url = this.constants.SERVICE_URL + "api/Distributor/Get?active=" + active + "&register=" + register;

  //     var headers = this.utilities.getAuthorizationHeader();

  //     this.http.get(url, headers).subscribe(data => {
  //       resolve(data);
  //     }, err => {
  //       resolve(err);
  //     });
  //   });
  // }

  async getDistributors(tableControl: TableControl) {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Distributor/GetPaginated`;
      url = this.utilities.applyTableControls(url, tableControl);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getDistributorCount(filter: FilterModel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/Distributor/GetCount`;
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getDistributorById(distributorId: number){
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Distributor/Get?id=" + distributorId;

      var headers = this.utilities.getAuthorizationHeader();

      this.http.get(url, headers).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getDistributorByUserId(userId: number){
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Distributor/GetDistributorBy?userId=" + userId;

      var headers = this.utilities.getAuthorizationHeader();

      this.http.get(url, headers).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async registerDistributor(userId: number, distributor: Distributor): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Distributor/Register?userId=" + userId;
      var body = {
        DistributorName: distributor.DistributorName,
        Company: distributor.Company,
        DirectorName: distributor.DirectorName,
        TelephoneNo: distributor.TelephoneNo,
        Email: distributor.Email,
        Address: distributor.Address,
        Website: distributor.Website,
        BankAccountNumber: distributor.BankAccountNumber,
        BankAccountName: distributor.BankAccountName,
        PayPalEmail: distributor.PayPalEmail,
        ABN: distributor.ABN,
        EIN: distributor.EIN,
        AccountsContact: distributor.AccountsContact,
        EverydayContactPerson: distributor.EverydayContactPerson,
        HexColor: distributor.HexColor,
        IsRegistered: true
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async approveDistributor(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
			const url 	= this.constants.SERVICE_URL + `api/Distributor/Approve?id=${id}`;
			const headers = this.utilities.getFormHeadersJson();

			this.http.post(url, null, headers).subscribe(data => {
				resolve(data);
			}, err => {
				reject(err);
			});
		});
	}

  async updateDistributor(distributor: Distributor): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Distributor/Update";
      var body = {
        Id: distributor.Id,
        DistributorName: distributor.DistributorName,
        Company: distributor.Company,
        DirectorName: distributor.DirectorName,
        TelephoneNo: distributor.TelephoneNo,
        Email: distributor.Email,
        Address: distributor.Address,
        Website: distributor.Website,
        BankAccountNumber: distributor.BankAccountNumber,
        BankAccountName: distributor.BankAccountName,
        PayPalEmail: distributor.PayPalEmail,
        ABN: distributor.ABN,
        EIN: distributor.EIN,
        AccountsContact: distributor.AccountsContact,
        EverydayContactPerson: distributor.EverydayContactPerson,
        HexColor: distributor.HexColor,
        Logo: distributor.Logo,
        IsRegistered: distributor.IsRegistered,
        Active: distributor.Active
      }

      var xb = this.utilities.getEncodedBody(body);
      var headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async deleteDistributor(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
			const url 	= this.constants.SERVICE_URL + `api/Distributor/Delete?id=${id}`;
			const headers = this.utilities.getFormHeadersJson();

			this.http.post(url, null, headers).subscribe(data => {
				resolve(data);
			}, err => {
				reject(err);
			});
		});
	}

  async denyDistributor(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
			const url 	= this.constants.SERVICE_URL + `api/Distributor/Deny?id=${id}`;
			const headers = this.utilities.getFormHeadersJson();

			this.http.post(url, null, headers).subscribe(data => {
				resolve(data);
			}, err => {
				reject(err);
			});
		});
	}
}
