import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../common/constants/constants.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private http: HttpClient,
    private constants: ConstantsService) { }

  async getCountries() {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/Countries/GetCountries";

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }
}
