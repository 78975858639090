import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManufacturerService } from 'src/app/services/http/manufacturer/manufacturer.service';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { Manufacturer } from 'src/app/models/Manufacturer';

@Component({
  selector: 'app-edit-manufacturer',
  templateUrl: './edit-manufacturer.component.html',
  styleUrls: ['./edit-manufacturer.component.scss']
})
export class EditManufacturerComponent implements OnInit {
  @Output() manufacturerEdited = new EventEmitter();
  @Output() manufacturerAdded = new EventEmitter();
	@ViewChild('EditManufacturer', {static: false}) editManufacturer: NgxSmartModalComponent;

  manufacturer: Manufacturer = new Manufacturer();

  form: FormGroup;

  isSubmitted: boolean;
  modalTitle: string;
  fullScreenSpinner = 'fullScreenSpinner';

  constructor(private spinnerService: NgxSpinnerService,
    private manufacturerService: ManufacturerService) { 
    }
  
  get id() { return this.form.controls['id'] };
  get name() { return this.form.controls['name'] };

  ngOnInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required)
    });
  }

  onDataAdded() {
    this.isSubmitted = false;

    let data = this.editManufacturer.getData() as Manufacturer;

    if (!data) return;

    if (data.Id == null) {
      this.form.reset();
      this.refreshTitle();
      return;
    }

    this.form.setValue({
      id: data.Id,
      name: data.Name
    })

    this.refreshTitle();
  }

  refreshTitle(name: string = '') {
    this.modalTitle = name
      ? 'Editing: ' + name
      : 'Adding Manufacturer';
  }
  
  async onSubmit() {
    this.isSubmitted = true;

    if (!this.form.valid) return;

    this.spinnerService.show(this.fullScreenSpinner);

    let isNew = !this.id.value;
    let isSuccessful = await this.save();

    this.spinnerService.hide(this.fullScreenSpinner);

    if (!isSuccessful) return;

    this.isSubmitted = false;
    this.form.reset();
    this.editManufacturer.close();

    if (isNew)
      this.manufacturerAdded.emit();
    else
      this.manufacturerEdited.emit();
  }

  async save() {
    let manufacturer = new Manufacturer();

    manufacturer.Construct(this.id.value, this.name.value);

    let isSuccessful: boolean;

    await this.manufacturerService.editManufacturer(manufacturer)
      .then(_ => isSuccessful = true)
      .catch(err => {
        if (err.status === 409) {
          this.name.setErrors({ duplicate: true });
        }
      });

    return isSuccessful;
  }
}
