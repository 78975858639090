import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detailed-bordereau',
  templateUrl: './detailed-bordereau.component.html',
  styleUrls: ['./detailed-bordereau.component.scss']
})
export class DetailedBordereauComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
