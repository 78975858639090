export class ProductConfiguration {
    public Id: number;
    public ProductTypeId: number;
    public ProductTypeName: string;
    public ManufacturerId: number;
    public ManufacturerName: string;
    public MakeOrModelId: number;
    public MakeOrModelNameId: number;
    public MakeOrModelName: string;
    public ConfigurationId: number;
    public ConfigurationName: string;
    public CBM: string;
    public Height: number;
    public Weight: number;
    public Length: number;
    public Width: number;
    public Title: string;
    public MetaTitle: string;
    public MetaDescription: string;
    public MetaKeywords: string;
    public MetaURL: string;
    public FeaturedImage: string;
    public Source: string;
    public YearModel: number;
    
    public Clear(){
        this.Id = null;
        this.ProductTypeId = null;
        this.ManufacturerId = null;
        this.MakeOrModelId = null;
        this.MakeOrModelNameId = null;
        this.ProductTypeName = "";
        this.ManufacturerName = "";
        this.MakeOrModelName = "";
        this.ConfigurationName = "";
        this.ConfigurationId = null;
        this.CBM = "";
        this.Height = null;
        this.Weight = null;
        this.Length = null;
        this.Width = null;
        this.Title = "";
        this.MetaTitle = "";
        this.MetaDescription = "";
        this.MetaKeywords = "";
        this.MetaURL = "";
        this.FeaturedImage = "";
        this.Source = "";
        this.YearModel = null;
    }

    public Construct(object: any){
        this.Id = object.Id;
        this.ProductTypeId = object.ProductTypeId;
        this.ProductTypeName = object.ProductTypeName;
        this.ManufacturerId = object.ManufacturerId;
        this.ManufacturerName = object.ManufacturerName;
        this.MakeOrModelId = object.MakeOrModelId;
        this.MakeOrModelNameId = object.MakeOrModelNameId;
        this.MakeOrModelName = object.MakeOrModelName;
        this.ConfigurationId = object.ConfigurationId;
        this.ConfigurationName = object.ConfigurationName;
        this.CBM = object.CBM;
        this.Height = object.Height;
        this.Weight = object.Weight;
        this.Length = object.Length;
        this.Width = object.Width;
        this.Title = object.Title;
        this.MetaTitle = object.MetaTitle;
        this.MetaDescription = object.MetaDescription;
        this.MetaKeywords = object.MetaKeywords;
        this.MetaURL = object.MetaURL;
        this.FeaturedImage = object.FeaturedImage;
        this.Source = object.Source;
        this.YearModel = object.YearModel;
    }
}