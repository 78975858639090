import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ProductConfigurationService } from 'src/app/services/http/product-configuration/product-configuration.service';

@Component({
  selector: 'app-delete-product-configuration',
  templateUrl: './delete-product-configuration.component.html',
  styleUrls: ['./delete-product-configuration.component.scss']
})
export class DeleteProductConfigurationComponent implements OnInit {
  @Output() productConfigurationDeleted = new EventEmitter();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private productConfigurationService: ProductConfigurationService) { }

  ngOnInit() {
  }

  async deleteProductConfigurations(productConfigurationId: number) {
    this.spinner.show("fullScreenSpinner");
    await this.productConfigurationService.deleteProductConfiguration(productConfigurationId);
    this.spinner.hide("fullScreenSpinner");

    this.ngxSmartModalService.getModal('deleteProductConfiguration').close()
    this.productConfigurationDeleted.emit();
  }
}
