export class DistributorFee {
    public Id: number;
    public DistributorId: number;
    public FeeDescription: string;
    public FeeRates: number;
    public FeeTypeId: number;
    public CurrencyId: number;
    public OperatorId: number;
    public DistributorName: string;
    public FeeTypeName: string;
    public CurrencyISO: string;

    public Clear(){
        this.DistributorId = null;
        this.FeeDescription = "";
        this.FeeRates = null
        this.FeeTypeId = null
        this.CurrencyId = null
        this.OperatorId = null
        this.DistributorName = "";
        this.FeeTypeName = "";
        this.CurrencyISO = "";
    }

    public Construct(object: any){
        this.Id = object.Id;
        this.DistributorId = object.DistributorId;
        this.FeeDescription = object.FeeDescription;
        this.FeeRates = object.FeeRates;
        this.FeeTypeId = object.FeeTypeId;
        this.CurrencyId = object.CurrencyId;
        this.OperatorId = object.OperatorId;
        this.DistributorName = object.DistributorName;
        this.FeeTypeName = object.FeeTypeName;
        this.CurrencyISO = object.CurrencyISO;
    }
}