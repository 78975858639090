import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { isArray } from 'util';

import { ProductTypeService } from 'src/app/services/http/product-type/product-type.service';

@Component({
  selector: 'app-import-product-type',
  templateUrl: './import-product-type.component.html',
  styleUrls: ['./import-product-type.component.scss']
})
export class ImportProductTypeComponent implements OnInit {
  @Output() productImported = new EventEmitter();
  @ViewChild('importProductType', { static: false }) modal: NgxSmartModalComponent;
  @ViewChild('importExcelControl', { static: false }) importExcelControl: FormControl;

  importProductForm: FormGroup;

  importFailed = false;
  errorMessages: string[] = [];
  importExcel = null;
  isSubmitted = false;

  constructor(
    private productTypeService: ProductTypeService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.importProductForm = new FormGroup({
      importExcel: new FormControl(null),
      file: new FormControl(null, [Validators.required, this.fileTypeExcel.bind(this)])
    });
  }

  onFileChange(files: File[]) {
    this.isSubmitted = false;
    this.importFailed = false;
    this.errorMessages = [];
    
    if (files.length) {
      this.importProductForm.patchValue({ file: files[0] });
      this.importProductForm.get('file').markAsDirty();
      this.importProductForm.get('file').updateValueAndValidity();
    }
  }

  fileTypeExcel(control: FormControl): {[s: string]: boolean} {
    if (control.value && !this.isValidExcelFile(control.value))
      return { 'invalidExcelFile': true };
  }

  isValidExcelFile(file: File): boolean {
    const allowedFileTypes = [
      "application/vnd.ms-excel", 
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

    return !this.isFileTypeCsv(file) && !!allowedFileTypes.some(t => t === file.type);
  }

  isFileTypeCsv(file: File) {
    return file.name.split('.').pop().toLowerCase() === 'csv';
  }

  removeFile() {
    this.importProductForm.reset();
  }

  async onSubmit() {
    this.isSubmitted = true;
    this.importFailed = false;
    this.errorMessages = [];
    
    if (!this.importProductForm.valid)
      return;

    this.spinner.show("fullScreenSpinner");
    
    const file = <File>this.importProductForm.get('file').value;

    await this.productTypeService.importProductTypes(file)
      .then(data => {
        const result = data;

        this.isSubmitted = false;
        if (result && isArray(result)) {
          const errors = this.getErrors(result);
    
          if (errors.length) {
            this.importFailed = true;
            this.errorMessages = errors;
            this.importProductForm.reset();
          }
    
        } else {
          this.close();
          this.productImported.emit();
        }
      })
      .catch(err => {
        this.isSubmitted = false;
        this.importFailed = true;

        if (err.status === 409)
          this.errorMessages = this.getErrors(err.error);
        else if (err.status === 422)
          this.errorMessages = [err.error];
        else
          this.errorMessages = ["An unexpected error occurred. Try uploading again."];
        
        this.importProductForm.reset();
      });

    this.spinner.hide("fullScreenSpinner");
  }

  getErrors(data: any[]): string[] {
    const errors: string[] = [];

    for (let item of data)
      if (item.HasError)
        errors.push(`${item.Row ? `[Row ${item.Row}] ` : ''}${item.Word} : ${item.ErrorMessage}`);

    return errors;
  }
  
  close() {
    this.reset();
    this.modal.close();
  }
  
  reset() {
    this.isSubmitted = false;
    this.importFailed = false;
    this.errorMessages = [];
    this.importProductForm.reset();
  }

}
