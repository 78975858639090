import { Injectable } from '@angular/core';
import { FilterModel } from 'src/app/models/FilterModel';
import { ConstantsService } from '../../common/constants/constants.service';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { HttpClient } from '@angular/common/http';
import { TableControl } from 'src/app/models/TableControl';
import { Carrier } from 'src/app/models/Carrier';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {
  private distributorId: number;

  constructor(private constants: ConstantsService,
    private utilities: UtilitiesService,
    private http: HttpClient) {
      this.distributorId = this.constants.DISTRIBUTOR_ID;
    }
  
  async getCarrierCount(filter: FilterModel): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + `api/Carrier/GetCountByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getCarriers(tableControl: TableControl) {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + `api/Carrier/GetPaginatedByDistributorId?distributorId=${this.distributorId}`;
      url = this.utilities.applyTableControls(url, tableControl);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async saveCarrier(carrier: Carrier): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + "api/Carrier/Save";
      let body = {
        Name: carrier.Name,
        DistributorId: this.distributorId,
      }

      let xb = this.utilities.getEncodedBody(body);
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async editCarrier(carrier: Carrier): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + "api/Carrier/Save";
      let body = {
        Id: carrier.Id,
        Name: carrier.Name,
        DistributorId: this.distributorId
      }

      let xb = this.utilities.getEncodedBody(body);
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, xb, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  async deleteCarrier(carrierId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + `api/Carrier/Disable`;
      
      let body = '=' + carrierId;
      let headers = this.utilities.getFormHeaders();

      this.http.post(url, body, headers).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }
  
  async getMoreCarriers(term: string, bufferSize: number, pageIndex: number): Promise<{}> {
    return new Promise((resolve, reject) => {
      let url = this.constants.SERVICE_URL + `api/Carrier/GetPaginatedByDistributorId?distributorId=${this.distributorId}`;

      let tableCntrl = new TableControl(bufferSize);
      tableCntrl.Filter = new FilterModel("Word", term);
      tableCntrl.Sorting = "WordASC";
      tableCntrl.Page = pageIndex;

      url = this.utilities.applyTableControls(url, tableCntrl);

      this.http.get<any[]>(url).pipe(
        map((items: {Id:number, Name:string}[]) => {
          let obj = {};
          items.forEach(item => {
            obj[item.Id] = item.Name;
          });
          return obj;
          })
        )
        .subscribe(data => resolve(data),
                  err => reject(err));
    });
  }
}
