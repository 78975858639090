import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { UtilitiesService } from 'src/app/services/common/utilities/utilities.service';
import { ConstantsService } from 'src/app/services/common/constants/constants.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UserService } from 'src/app/services/http/user/user.service';
import { ChildUser } from 'src/app/models/ChildUser';

@Component({
  selector: 'app-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.scss']
})
export class EditUserProfileComponent implements OnInit {
  @Output() userProfileEdited = new EventEmitter();
  @Output() userProfileAdded = new EventEmitter();
  @ViewChild('EditUserProfile', {static: false}) editUserProfile: NgxSmartModalComponent;

  form: FormGroup;

  fullScreenSpinner = 'fullScreenSpinner';
  isSubmitted: any;
  modalTitle: string;

  constructor(private spinnerService: NgxSpinnerService,
    private userProfile: UserService) { 
    }

  ngOnInit() {
    this.form = new FormGroup({
			parentId: new FormControl(null, Validators.required),
			childId: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required)
		});
  }

  get parentId() { return this.form.controls['parentId'] };
  get childId() { return this.form.controls['childId'] };
  get email() { return this.form.controls['email'] };

  async onDataAdded() {
		this.isSubmitted = false;

		let data = this.editUserProfile.getData() as ChildUser;

		if  (!data) return;

    if (data.Id == null) {
      this.form.reset();
      this.refreshTitle();
      return;
    }

		this.form.setValue({
			parentId: data.ParentUserId,
			childId: data.ChildUserId,
			email: data.ChildUserEmail
		});
	}

	async onClose(){
		this.form.reset();
	}

	async onSubmit() {
		this.isSubmitted = true;

		if (this.form.invalid) return;
		
		this.spinnerService.show(this.fullScreenSpinner);
	
		let isNew = !this.parentId.value;
		let isSuccessful = await this.save();
	
		this.spinnerService.hide(this.fullScreenSpinner);

		if (!isSuccessful) return;

		this.isSubmitted = false;
		this.form.reset();
		this.editUserProfile.close();
	
		if (isNew)
		  this.userProfileEdited.emit();
		else
		  this.userProfileAdded.emit();
	}

	async save() {

    let childUser = new ChildUser();
  
    childUser.Construct({
      ParentUserId: this.parentId.value,
      ChildUserId: this.childId.value,
      Email: this.email.value
    });

    let isSuccessful: boolean;

    await this.userProfile.saveChildUser(childUser)
    .then(_ => isSuccessful = true)
    .catch(err => { 
      if (err.status === 409)
        this.parentId.setErrors({ duplicate: true })
        this.childId.setErrors({ duplicate: true })
        this.email.setErrors({ duplicate: true });
    });

    return isSuccessful;
	}

  refreshTitle(name: string = '') {
    this.modalTitle = name
      ? 'Editing ' + name
      : 'Adding User Profile';
  }
}
