import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SailingScheduleService } from 'src/app/services/http/sailing-schedule/sailing-schedule.service';

@Component({
  selector: 'app-disable-sailing-schedule',
  templateUrl: './disable-sailing-schedule.component.html',
  styleUrls: ['./disable-sailing-schedule.component.scss']
})
export class DisableSailingScheduleComponent implements OnInit {
  @Output() sailingScheduleDisabled = new EventEmitter();

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private sailingScheduleService: SailingScheduleService) { }

  ngOnInit() {
  }

  async disableSailingSchedules(id) {
    this.spinner.show("fullScreenSpinner");
    await this.sailingScheduleService.disableSailingSchedule(id);
    this.spinner.hide("fullScreenSpinner");

    this.ngxSmartModalService.getModal('disableSailingSchedule').close()
    this.sailingScheduleDisabled.emit();
  }
}
