export class SailingSchedule {
	public Id: number;
	public VesselId: number;
	public VoyageId: number;
	public OriginPortId: number;
	public DestinationPortId: number;
	public OriginCountryId: number;
	public DestinationCountryId: number;
	public ETA: Date;
	public ETD: Date;
	public DistributorId: number;

	public Clear() {
		this.VesselId = null;
		this.VoyageId = null;
		this.OriginPortId = null;
		this.DestinationPortId = null;
		this.OriginCountryId = null;
		this.DestinationCountryId = null;
		this.ETA = null;
		this.ETD = null;
	}

	public Construct(
		id: number,
		voyageId: number,
		originPortId: number,
		destinationPortId: number,
		originCountryId: number,
		destinationCountryId: number,
		eta: Date,
		etd: Date,
		distributorId: number
	) {
		this.Id = id;
		this.VoyageId = voyageId;
		this.OriginPortId = originPortId;
		this.DestinationPortId = destinationPortId;
		this.OriginCountryId = originCountryId;
		this.DestinationCountryId = destinationCountryId;
		this.ETA = eta;
		this.ETD = etd;
		this.DistributorId = distributorId;
	}
}