import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DistributorService } from 'src/app/services/http/distributor/distributor.service';

@Component({
  selector: 'app-delete-user-profile',
  templateUrl: './delete-user-profile.component.html',
  styleUrls: ['./delete-user-profile.component.scss']
})
export class DeleteUserProfileComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
  }

}
