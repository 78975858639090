import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilitiesService } from 'src/app/services/common/utilities/utilities.service';
import { ConstantsService } from 'src/app/services/common/constants/constants.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit, OnDestroy {
  username: string;
  title: string;
  isDropdownOpen: boolean = false;
  subscription: any;

  constructor(
    private utilities: UtilitiesService,
    private constants: ConstantsService,
    router: Router
  ) {
    // this.subscription = router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     const items = constants.routes.map(v => v.children).reduce((v, i) => [...v, ...i]);
    //     const current = items.find(v => v.path === `${event.url}/`);
    //     this.title = current.text || '';
    //   }
    // });
  }

  ngOnInit() {
    // Note! should be refactored, should only store token and just call endpoint for getting this details
    let currentUser: any = JSON.parse(this.utilities.getStorage(this.constants.CURRENT_USER));
    if (currentUser){
      this.username = currentUser.profile.preferred_username;
    }
  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }

}
