import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TableControl } from 'src/app/models/TableControl';
import { FilterModel } from 'src/app/models/FilterModel';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { ConstantsService } from '../../common/constants/constants.service';


export class KeyVal {
  public Id:number;
  public Name:string;
}

@Injectable({
  providedIn: 'root'
})
export class InsuranceCertificateService {

	public distributorId: number;

  constructor(private http: HttpClient,
    private utilities: UtilitiesService,
    private constants: ConstantsService) {
      this.distributorId = this.constants.DISTRIBUTOR_ID;
    }
  
  async getInsuranceCertificates(tableControl: TableControl) {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/InsuranceCertificates/GetPaginatedByDistributors?distributorIds=${this.distributorId}`;
      url = this.utilities.applyTableControls(url, tableControl);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async getInsuranceCertificateCount(filter: FilterModel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + `api/InsuranceCertificates/GetCountByDistributors?distributorIds=${this.distributorId}`;
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }
}
