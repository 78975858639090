import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TableControl } from 'src/app/models/TableControl';
import { FilterModel } from 'src/app/models/FilterModel';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { ConstantsService } from '../../common/constants/constants.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private http: HttpClient,
    private utilities: UtilitiesService,
    private constants: ConstantsService) { }

  //INSURANCE QUOTES SERVICES
  async GetInsuranceQuotesByDistributors(quoteType: string, distributorIds: string, tableControl: TableControl) {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/InsuranceQuotes/GetPaginatedByDistributors?quoteType=" + quoteType + "&distributorIds=" + distributorIds;
      url = this.utilities.applyTableControls(url, tableControl);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async GetCountInsuranceQuotesByDistributors(quoteType: string, distributorIds: string, filter: FilterModel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/InsuranceQuotes/GetCountByDistributors?quoteType=" + quoteType + "&distributorIds=" + distributorIds;
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  //INSURANCE CERTIFICATES SERVICES
  async GetInsuranceCertificatesByDistributors(distributorIds: string, tableControl: TableControl) {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/InsuranceCertificates/GetPaginatedByDistributors?distributorIds=" + distributorIds;
      url = this.utilities.applyTableControls(url, tableControl);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async GetCountInsuranceCertificatesByDistributors(distributorIds: string, filter: FilterModel): Promise<any> {
    return new Promise((resolve, reject) => {
      var url = this.constants.SERVICE_URL + "api/InsuranceCertificates/GetCountByDistributors?distributorIds=" + distributorIds;
      url = this.utilities.applyTableFilters(url, filter);

      this.http.get(url).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }
}